const emailRegex = /^[a-zA-Z0-9._-]+@cloudframe.com$/;

export const getInitials = (name: string): string => {
  const names = name.split(" ");
  const initials = names.map((n) => n.charAt(0).toUpperCase()).join("");
  return initials;
};

export const isEmailValid = (email: string): boolean => {
  return emailRegex.test(email);
};

export const parseJson = (json: string): any => {
  try {
    return JSON.parse(json);
  } catch (e) {
    return {};
  }
};

export const isBlank = (value: any): boolean => {
  return (
    value === undefined || value === null || value === "" || Number.isNaN(value)
  );
};

export const formatSentence = (sentence: string): string => {
  // Capitalize the first letter
  let formattedSentence = sentence.charAt(0).toUpperCase();

  // Add the rest of the sentence starting from the second character
  formattedSentence += sentence.slice(1);

  // Insert space after every capital letter
  for (let i = 1; i < formattedSentence.length; i++) {
    if (formattedSentence[i]!== formattedSentence[i].toLowerCase()) {
      formattedSentence = formattedSentence.slice(0, i) + ' ' + formattedSentence.slice(i);
    }
  }

  // Remove the trailing space if present
  if (formattedSentence.endsWith(' ')) {
    formattedSentence = formattedSentence.slice(0, -1);
  }

  return formattedSentence;
};
