import { gql } from "@apollo/client";

export const MUTATION_CREATE_PARTNER = gql`
  mutation CreatePartner($data: CreatePartnerDetailsInputType!) {
    createPartner(data: $data) {
      id
      partnerName
      primaryContactName
      primaryContactEmail
      primaryContactPhone
      usingCfSandbox
      cfSbDetails
      additionalInfo
      teamMembers {
        id
        name
        role
        organization
        phone
        email
      }
    }
  }
`;

export const MUTATION_EDIT_PARTNER = gql`
  mutation UpdatePartnerByID(
    $data: UpdatePartnerDetailsInputType!
    $updatePartnerByIdId: Float!
  ) {
    updatePartnerByID(data: $data, id: $updatePartnerByIdId) {
      id
      partnerName
      primaryContactName
      primaryContactEmail
      primaryContactPhone
      usingCfSandbox
      cfSbDetails
      additionalInfo
      teamMembers {
        id
        name
        role
        organization
        phone
        email
      }
    }
  }
`;

export const MUTATION_DELETE_PARTNER = gql`
  mutation DeletePartner($deletePartnerId: Float!) {
    deletePartner(id: $deletePartnerId)
  }
`;

export const MUTATION_DELETE_PARTNER_FROM_PROJECT = gql`
  mutation DeleteProjectPartner($deleteProjectPartnerId: Int!) {
    deleteProjectPartner(id: $deleteProjectPartnerId)
  }
`;
