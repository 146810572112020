import { useMutation } from "@apollo/client";
import {
  CloudFrameDefaultButton,
  CloudFramePrimaryButton,
} from "@cloudframe/button";
import { CloudFrameDialog } from "@cloudframe/dialog";
import { CloudFrameDropdown } from "@cloudframe/dropdown";
import { CloudFrameTextBox } from "@cloudframe/textbox";
import { DialogType, IModalProps } from "@fluentui/react";
import { FormEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../../@styles/css/form-element.css";
import { IForm } from "../../@types/form.types";
import { ORGANIZATION_TYPE } from "../../constants/project.enum";
import {
  MUTATION_CREATE_PARTNER,
  MUTATION_EDIT_PARTNER,
} from "../../graphql-mutations/partner.mutation";
import { getKeysAsObjects } from "../../utils/object.util";
import { isBlank } from "../../utils/string.utils";
import { useStyles } from "./partner-form.styles";

export interface TeamMember {
  id?: number;
  name: string;
  role: string;
  organization: string;
  phone: string;
  email: string;
}

const PartnerForm = (props: IForm) => {
  const classes = useStyles();
  const { data } = props;
  const [formValues, setFormValues] = useState<any>({});
  const [errors, setErrors] = useState<any>({});
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [createPartner] = useMutation(MUTATION_CREATE_PARTNER);
  const [updatePartnerByID] = useMutation(MUTATION_EDIT_PARTNER);
  const [formSubmitAttempted, setFormSubmitAttempted] = useState(false);
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);
  const [showErrorDialog, setShowErrorDialog] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const emailPattern = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
  const phonePattern = /^\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}$/;
  const [isFormValid, setIsFormValid] = useState(false);

  const [members, setMembers] = useState<TeamMember[]>([]);
  const navigate = useNavigate();

  const onAddTeamMember = () => {
    console.log(members);
    setMembers([
      ...members,
      {
        id: undefined,
        name: "",
        role: "",
        organization: "",
        phone: "",
        email: "",
      },
    ]);
  };
  const onRemoveTeamMember = (index) => {
    const list = [...members];
    list.splice(index, 1);
    setMembers(list);
  };
  const handleMemberChange = (
    index: number,
    field: keyof TeamMember,
    value: any
  ) => {
    const newMembers = members.map((member, i) => {
      if (i === index) {
        return { ...member, [field]: value };
      }
      return member;
    });
    setMembers(newMembers);
    if (field === "phone" && !phonePattern.test(value)) {
      setErrors((prevErrors: any) => ({
        ...prevErrors,
        [`phone_${index}`]: "Please enter a valid Phone number",
      }));
    } else if (field === "phone") {
      setErrors((prevErrors: any) => {
        const { [`phone_${index}`]: removedError, ...rest } = prevErrors;
        return rest;
      });
    }
    if (field === "email" && !emailPattern.test(value)) {
      setErrors((prevErrors: any) => ({
        ...prevErrors,
        [`email_${index}`]: "Please enter a valid Email address",
      }));
    } else if (field === "email") {
      setErrors((prevErrors: any) => {
        const { [`email_${index}`]: removedError, ...rest } = prevErrors;
        return rest;
      });
    }
  };

  useEffect(() => {
    const savedDataString = sessionStorage.getItem("partnerData");
    if (savedDataString) {
      const savedData = JSON.parse(savedDataString);
      delete savedData.__typename;
      for (let i = 0; i < savedData.teamMembers.length; i++) {
        delete savedData.teamMembers[i].__typename;
      }
      setFormValues(savedData);
      setMembers(savedData.teamMembers);
      setFormSubmitAttempted(true);
    } else {
      setMembers([
        {
          id: undefined,
          name: "",
          role: "",
          organization: ORGANIZATION_TYPE.CF_Partner,
          phone: "",
          email: "",
        },
      ]);
      setFormValues({
        partnerName: "",
        primaryContactName: "",
        primaryContactEmail: "",
        primaryContactPhone: "",
        usingCfSandbox: false,
        cfSbDetails: "",
        additionalInfo: "",
      });
    }

    // setTimeout(() => {
    //   sessionStorage.removeItem("partnerData");
    // }, 2000);
  }, [data]);

  const validateForm = () => {
    const newErrors: any = {};
    if (isBlank(formValues.partnerName)) {
      newErrors.partnerName = "Please enter the Partner Name";
    }
    if (isBlank(formValues.primaryContactName)) {
      newErrors.primaryContactName = "Please enter the Primary Contact Name";
    }
    if (isBlank(formValues.primaryContactEmail)) {
      newErrors.primaryContactEmail = "Please enter the Email ID";
    } else if (!emailPattern.test(formValues.primaryContactEmail)) {
      newErrors.primaryContactEmail = "Please enter a valid Email ID";
    }

    if (isBlank(formValues.primaryContactPhone)) {
      newErrors.primaryContactPhone = "Please enter the Contact Number";
    } else if (!phonePattern.test(formValues.primaryContactPhone)) {
      newErrors.primaryContactPhone = "Please enter a valid Contact Number";
    }
    if (isBlank(formValues.usingCfSandbox)) {
      newErrors.cfSandbox = "Please select an option";
    }

    members.forEach((member, index) => {
      if (isBlank(member.name)) {
        newErrors[`name_${index}`] = "Please enter the Name";
      }
      if (isBlank(member.role)) {
        newErrors[`role_${index}`] = "Please enter the Role";
      }
      if (isBlank(member.organization)) {
        newErrors[`organization_${index}`] = "Please enter the Organization";
      }
      if (isBlank(member.phone)) {
        newErrors[`phone_${index}`] = "Please enter the Phone";
      } else if (!phonePattern.test(member.phone)) {
        newErrors[`phone_${index}`] = "Please enter a valid Phone number";
      }
      if (isBlank(member.email)) {
        newErrors[`email_${index}`] = "Please enter the Email";
      } else if (!emailPattern.test(member.email)) {
        newErrors[`email_${index}`] = "Please enter a valid Email address";
      }
    });

    setErrors(newErrors);

    const isValid = Object.keys(newErrors).length === 0;
    setIsFormValid(isValid);
    return isValid;
  };

  const updatePartnerDetails = async () => {
    const {
      id,
      partnerName,
      primaryContactName,
      primaryContactEmail,
      primaryContactPhone,
      usingCfSandbox,
      cfSbDetails,
      additionalInfo,
      teamMembers = [],
    } = formValues;

    const result = await updatePartnerByID({
      variables: {
        updatePartnerByIdId: formValues.id,
        data: {
          partnerName,
          primaryContactName,
          primaryContactEmail,
          primaryContactPhone,
          usingCfSandbox,
          cfSbDetails,
          additionalInfo,
          teamMembers: members,
        },
      },
    });
    if (result.data.updatePartnerByID) {
      sessionStorage.removeItem("partnerData");
      setShowSuccessDialog(true);
      navigate("/list-partner");
    } else {
      console.error("Error updating partner:", result.errors);
      setErrorMessage("Error updating partner details.");
      setShowErrorDialog(true);
    }
  };
  const resetForm = () => {
    setFormValues({
      partnerName: "",
      primaryContactName: "",
      primaryContactEmail: "",
      primaryContactPhone: "",
      usingCfSandbox: false,
      cfSbDetails: "",
      additionalInfo: "",
    });
    setMembers([
      {
        id: undefined,
        name: "",
        role: "",
        organization: ORGANIZATION_TYPE.Customer,
        phone: "",
        email: "",
      },
    ]);
    setErrors({});
    setFormSubmitAttempted(false);
  };

  const onFormSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setFormSubmitting(true);
    setFormSubmitAttempted(true);
    const isFormValid = validateForm();
    try {
      console.log(isFormValid);
      if (isFormValid) {
        if (formValues.id) {
          await updatePartnerDetails();
        } else {
          const formData = {
            ...formValues,
          };
          const result = await createPartner({
            variables: { data: { ...formData, teamMembers: members } },
          });
          if (result?.data?.createPartner) {
            sessionStorage.removeItem("partnerData");
            setShowSuccessDialog(true);
            navigate("/list-partner");
          } else {
            setErrorMessage("Error Occurred while submitting partner details.");
            setShowErrorDialog(true);
          }
        }
      }
    } catch (error) {
      console.log(error);
      setErrorMessage("Error Occurred while submitting partner details.");
      setShowErrorDialog(true);
    }
    setFormSubmitting(false);
  };
  const hideSuccessDialog = () => {
    setShowSuccessDialog(false);
    navigate("/list-partner");
  };
  const hideErrorDialog = () => {
    setShowErrorDialog(false);
  };

  const modalProps: IModalProps = {
    isBlocking: false,
  };

  return (
    <div className={classes.root}>
      <div
        id="form"
        className={classes.formBody}
        style={{ borderRadius: "25px" }}
      >
        <div className={classes.subContainer}>
          <h2>{formValues.id ? "Update Partner" : "Add new Partner"}</h2>
          <form onSubmit={onFormSubmit}>
            <CloudFrameTextBox
              onChange={(_, value) => {
                setFormValues({ ...formValues, partnerName: value });
              }}
              required
              label="Partner Name"
              className={classes.mb8}
              value={formValues.partnerName}
              disabled={!!data}
            />
            <CloudFrameTextBox
              onChange={(_, value) => {
                setFormValues({ ...formValues, primaryContactName: value });
              }}
              required
              label="Primary Contact Name"
              className={classes.mb8}
              value={formValues.primaryContactName}
              disabled={!!data}
            />
            <CloudFrameTextBox
              label="Primary Contact Email"
              className={classes.mb8}
              value={formValues.primaryContactEmail}
              required
              disabled={!!data}
              errorMessage={errors.primaryContactEmail}
              onChange={(_, value) => {
                if (value !== undefined) {
                  setFormValues({ ...formValues, primaryContactEmail: value });
                  if (!emailPattern.test(value)) {
                    setErrors({
                      ...errors,
                      primaryContactEmail: "Please enter a valid email address",
                    });
                  } else {
                    setErrors({
                      ...errors,
                      primaryContactEmail: undefined,
                    });
                  }
                }
              }}
            />

            <CloudFrameTextBox
              label="Primary Contact Phone"
              required
              className={classes.mb8}
              value={formValues.primaryContactPhone}
              disabled={!!data}
              errorMessage={errors.primaryContactPhone}
              onChange={(_, value) => {
                if (value !== undefined) {
                  setFormValues({ ...formValues, primaryContactPhone: value });
                  if (!phonePattern.test(value)) {
                    setErrors({
                      ...errors,
                      primaryContactPhone: "Please enter a valid Phone Number",
                    });
                  } else {
                    setErrors({
                      ...errors,
                      primaryContactPhone: undefined,
                    });
                  }
                }
              }}
            />

            <h3>
              <b>Team Member details</b>
            </h3>
            <div>
              {members.map((member, index) => (
                <div key={index}>
                  <b>Member {index + 1}</b>
                  <div style={{ marginBottom: "30px" }}>
                    <div style={{ display: "flex", marginBottom: "1rem" }}>
                      <span style={{ minWidth: "225px", marginRight: "1rem" }}>
                        <CloudFrameTextBox
                          onChange={(_, value) =>
                            handleMemberChange(index, "name", value)
                          }
                          required
                          label="Name"
                          value={member.name}
                          errorMessage={errors[`name_${index}`]}
                        />
                      </span>
                      <span style={{ minWidth: "225px", marginRight: "2px" }}>
                        <CloudFrameTextBox
                          onChange={(_, value) =>
                            handleMemberChange(index, "role", value)
                          }
                          required
                          label="Role"
                          value={member.role}
                          errorMessage={errors[`role_${index}`]}
                        />
                      </span>
                    </div>
                    <div style={{ display: "flex", marginBottom: "1rem" }}>
                      <span style={{ minWidth: "225px", marginRight: "1rem" }}>
                        <CloudFrameDropdown
                          label="organization"
                          selectedKey={member.organization}
                          onChange={(e, option) =>
                            handleMemberChange(
                              index,
                              "organization",
                              option?.key
                            )
                          }
                          options={getKeysAsObjects(ORGANIZATION_TYPE)}
                        />
                      </span>
                      <span style={{ minWidth: "225px", marginRight: "2px" }}>
                        <CloudFrameTextBox
                          onChange={(_, value) =>
                            handleMemberChange(index, "phone", value)
                          }
                          required
                          label="Phone"
                          value={member.phone}
                          errorMessage={errors[`phone_${index}`]}
                        />
                      </span>
                    </div>
                    <div style={{ display: "flex", marginBottom: "1rem" }}>
                      <span style={{ minWidth: "225px", marginRight: "1rem" }}>
                        <CloudFrameTextBox
                          onChange={(_, value) =>
                            handleMemberChange(index, "email", value)
                          }
                          required
                          label="Email"
                          value={member.email}
                          errorMessage={errors[`email_${index}`]}
                        />
                      </span>
                    </div>
                    <CloudFrameDefaultButton
                      text={"Remove"}
                      onClick={() => onRemoveTeamMember(index)}
                      disabled={formSubmitting}
                      className={classes.mt16}
                      style={{ backgroundColor: "red", color: "white" }}
                    />
                  </div>
                </div>
              ))}
            </div>
            <div>
              <CloudFramePrimaryButton
                style={{
                  padding: "1em",
                  marginBottom: "20px",
                  // borderRadius: "50px",
                  marginLeft: "370px",
                }}
                title="Add"
                text={"Add"}
                iconProps={{ iconName: "AddTo" }}
                onClick={() => {
                  onAddTeamMember();
                }}
              />
            </div>

            <CloudFrameDropdown
              placeholder="Select an option"
              label="Is CF Sandbox used by Partner?"
              required
              className={classes.mb8}
              options={[
                { key: "yes", text: "Yes" },
                { key: "no", text: "No" },
              ]}
              onChange={(_, option) => {
                const updatedFormValues = {
                  ...formValues,
                  usingCfSandbox: option?.key === "yes",
                };
                if (option?.key === "no") {
                  updatedFormValues.cfSbDetails = "";
                }
                setFormValues(updatedFormValues);
                setErrors({ ...errors, usingCfSandbox: undefined });
              }}
              errorMessage={errors.usingCfSandbox}
              selectedKey={formValues.usingCfSandbox ? "yes" : "no"}
            />
            {formValues.usingCfSandbox && (
              <CloudFrameTextBox
                onChange={(_, value) => {
                  setFormValues({ ...formValues, cfSbDetails: value });
                }}
                required
                label="CloudFrame Sandbox Details"
                className={classes.mb8}
                value={formValues.cfSbDetails}
                disabled={!!data}
                errorMessage={errors.cfSbDetails}
              />
            )}

            <CloudFrameTextBox
              onChange={(_, value) => {
                setFormValues({ ...formValues, additionalInfo: value });
              }}
              label="Additional Information"
              className={classes.mb8}
              value={formValues.additionalInfo}
              disabled={!!data}
            />
            <div className={classes.btnContainer}>
              <CloudFramePrimaryButton
                text={formValues.id ? "Update" : "Submit"}
                type="submit"
                disabled={formSubmitting}
                className={`${classes.mt16} ${classes.ml2}`}
              />
              <CloudFrameDefaultButton
                text={"Reset"}
                type="reset"
                onClick={resetForm}
                disabled={formSubmitting}
                className={`${classes.mt16} ${classes.ml2}`}
              />
            </div>
          </form>
        </div>
      </div>

      <CloudFrameDialog
        hidden={!showSuccessDialog}
        onDismiss={hideSuccessDialog}
        dialogContentProps={{
          type: DialogType.normal,
          title: formValues.id ? "Updated" : "Success!",
          closeButtonAriaLabel: "Close",
          subText: formValues.id
            ? "Your form has been successfully updated."
            : "Your form has been successfully submitted.",
        }}
        modalProps={modalProps}
        primaryButton={{ text: "Close", onClick: hideSuccessDialog }}
      />

      <CloudFrameDialog
        hidden={!showErrorDialog}
        onDismiss={hideErrorDialog}
        dialogContentProps={{
          type: DialogType.normal,
          title: "Error",
          closeButtonAriaLabel: "Close",
          subText: errorMessage,
        }}
        modalProps={modalProps}
        primaryButton={{ text: "Close", onClick: hideErrorDialog }}
      />
    </div>
  );
};

export default PartnerForm;
