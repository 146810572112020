import { useMutation, useQuery } from "@apollo/client";
import { CloudFramePrimaryButton } from "@cloudframe/button";
import { CloudFrameCheckbox } from "@cloudframe/checkbox";
import { CloudFrameDatepicker } from "@cloudframe/datepicker";
import { CloudFrameDialog } from "@cloudframe/dialog";
import { CloudFrameDropdown } from "@cloudframe/dropdown";
import { CloudFrameTextBox } from "@cloudframe/textbox";
import {
  DayOfWeek,
  defaultDatePickerStrings,
  DialogType,
  IDropdownOption,
  IModalProps,
} from "@fluentui/react";
import { FormEvent, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  MUTATION_CREATE_TRAINING,
  UPDATE_TRAINING_DETAILS,
} from "../../graphql-mutations/training.mutation";
import {
  QUERY_GET_MEMBERS,
  QUERY_GET_TRAINING_BY_ID,
} from "../../graphql-query/training.query";
import { isBlank } from "../../utils/string.utils";
import { useStyles } from "./training-form.style";

interface ILocationState {
  id?: string;
}

const TrainingForm = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state as ILocationState;
  const floatId = state?.id ? parseFloat(state.id) : undefined;

  const [formValues, setFormValues] = useState<any>({
    course: "",
    startDate: null,
    endDate: null,
    status: "",
    sandboxProvided: false,
    additionalInfo: "",
    members: [],
  });
  const [errors, setErrors] = useState<any>({});
  const [errorMessage, setErrorMessage] = useState("");
  const [showErrorDialog, setShowErrorDialog] = useState(false);
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [createTraining] = useMutation(MUTATION_CREATE_TRAINING);
  const [updateTraining] = useMutation(UPDATE_TRAINING_DETAILS);
  const { data: membersData } = useQuery(QUERY_GET_MEMBERS);
  const { data: trainingData } = useQuery(QUERY_GET_TRAINING_BY_ID, {
    variables: { id: floatId },
    skip: !floatId,
  });
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);

  useEffect(() => {
    if (trainingData && trainingData.getTrainingDetailsById) {
      const { getTrainingDetailsById } = trainingData;
      setFormValues({
        course: getTrainingDetailsById.course ?? "",
        startDate: getTrainingDetailsById.startDate
          ? new Date(getTrainingDetailsById.startDate)
          : null,
        endDate: getTrainingDetailsById.endDate
          ? new Date(getTrainingDetailsById.endDate)
          : null,
        status: getTrainingDetailsById.status ?? "",
        sandboxProvided: getTrainingDetailsById.sandboxProvided ?? false,
        additionalInfo: getTrainingDetailsById.additionalInfo ?? "",
        members: getTrainingDetailsById.members
          ? getTrainingDetailsById.members.map((member: any) => member.id)
          : [],
      });
    }
  }, [trainingData]);

  const handleInputChange = (
    event: FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string | undefined
  ) => {
    const { name, value } = event.currentTarget;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleDateChange = (
    date: Date | null | undefined,
    fieldName: string
  ) => {
    if (date === undefined) {
      return;
    }
    setFormValues({
      ...formValues,
      [fieldName]: date,
    });
  };

  const handleCheckboxChange = (isChecked: boolean) => {
    setFormValues({
      ...formValues,
      sandboxProvided: isChecked,
    });
  };

  const handleDropdownChange = (
    event: FormEvent<HTMLDivElement>, // Adjust event type if necessary
    option?: IDropdownOption<any>, // Adjust option type if necessary
    index?: number | undefined
  ) => {
    if (option) {
      const selectedMemberId = option.key as number;
      const updatedMembers = [...formValues.members]; // Clone the array to avoid mutating state directly
      const indexOfSelected = updatedMembers.indexOf(selectedMemberId);

      if (indexOfSelected === -1) {
        updatedMembers.push(selectedMemberId); // Add the selected member ID to the array
      } else {
        updatedMembers.splice(indexOfSelected, 1); // Remove the selected member ID if already present
      }

      setFormValues({ ...formValues, members: updatedMembers });
    }
  };

  const validateForm = (formValues: any) => {
    const newErrors: any = {};

    if (isBlank(formValues.course)) {
      newErrors.course = "Please fill the course field";
    }

    if (isBlank(formValues.startDate)) {
      newErrors.startDate = "Start Date is required.";
    }

    if (isBlank(formValues.endDate)) {
      newErrors.endDate = "End Date is required.";
    }

    if (isBlank(formValues.status)) {
      newErrors.status = "Please select the status.";
    }

    return newErrors;
  };
  const resetForm = () => {
    setFormValues({
      course: "",
      startDate: null,
      endDate: null,
      status: "",
      sandboxProvided: false,
      additionalInfo: "",
      members: [],
    });
    setErrors({});
  };

  const submitForm = async (e: FormEvent) => {
    e.preventDefault();
    const validationErrors = validateForm(formValues);
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      console.log("Please fill all the required fileds");
      return;
    }

    setFormSubmitting(true);
    console.log(formValues);

    try {
      if (floatId !== undefined) {
        const { data } = await updateTraining({
          variables: { id: floatId, data: formValues },
        });
        console.log("Response from backend:", data);
      } else {
        const { data } = await createTraining({
          variables: { data: formValues },
        });
        console.log("Response from backend:", data);
      }

      resetForm();
      setFormSubmitting(false);
      setShowSuccessDialog(true);
    } catch (error) {
      console.error("Error submitting form:", error);
      setFormSubmitting(false);
      setErrorMessage("Error in submitting the training");
      setShowErrorDialog(true);
    }
  };

  const hideSuccessDialog = () => {
    setShowSuccessDialog(false);
    navigate("/training-details");
  };

  const modalProps: IModalProps = {
    isBlocking: false,
  };

  const closeErrorDialog = () => {
    setShowErrorDialog(false);
    setErrorMessage("");
  };

  return (
    <div className={classes.root} style={{ marginTop: "15px" }}>
      <div
        id="form"
        className={classes.formBody}
        style={{ borderRadius: "25px" }}
      >
        <div className={classes.subContainer}>
          <h3>{floatId ? "Edit Training Form" : "New Training Form"}</h3>
          <form onSubmit={submitForm}>
            <br />
            <CloudFrameTextBox
              type="text"
              name="course"
              label="Course"
              placeholder="Course"
              value={formValues.course}
              required={true}
              onChange={handleInputChange}
              errorMessage={errors.course}
            />
            <CloudFrameDatepicker
              firstDayOfWeek={DayOfWeek.Monday}
              label="Start Date"
              value={formValues.startDate}
              onSelectDate={(date) => handleDateChange(date, "startDate")}
              placeholder="Select a date..."
              ariaLabel="Select a date"
              strings={defaultDatePickerStrings}
              isRequired={true}
            />
            <CloudFrameDatepicker
              firstDayOfWeek={DayOfWeek.Monday}
              label="End Date"
              value={formValues.endDate}
              onSelectDate={(date) => handleDateChange(date, "endDate")}
              placeholder="Select a date..."
              ariaLabel="Select a date"
              strings={defaultDatePickerStrings}
              isRequired={true}
            />
            <CloudFrameDropdown
              placeholder="Select status"
              required={true}
              label="Status"
              options={[
                { key: "In Progress", text: "In Progress" },
                { key: "Completed", text: "Completed" },
                { key: "Yet to Start", text: "Yet to Start" },
              ]}
              selectedKey={formValues.status}
              onChange={(e: any, option?: IDropdownOption) => {
                if (option) {
                  setFormValues({ ...formValues, status: option.key });
                }
              }}
              errorMessage={errors.status}
            />
            <br />
            <CloudFrameCheckbox
              label="Sandbox provided"
              checked={formValues.sandboxProvided}
              onChange={(e: any, isChecked?: boolean) =>
                handleCheckboxChange(!!isChecked)
              }
            />
            <br />
            <CloudFrameTextBox
              type="text"
              name="additionalInfo"
              label="Additional Info"
              placeholder="Additional Info"
              value={formValues.additionalInfo}
              onChange={handleInputChange}
            />
            <CloudFrameDropdown
              placeholder="Select developer(s)"
              label="Member"
              options={
                membersData?.getTeamMember?.map((member: any) => ({
                  key: member.id,
                  text: member.name,
                })) || []
              }
              multiSelect={true}
              onChange={handleDropdownChange}
              selectedKeys={formValues.members}
            />

            <br />
            <div style={{ display: "flex", gap: "10px" }}>
              <CloudFramePrimaryButton text="Reset" onClick={resetForm} />
              <CloudFramePrimaryButton
                text={floatId ? "Update" : "Submit"}
                type="submit"
                disabled={formSubmitting}
              />
            </div>
          </form>
        </div>
      </div>
      <CloudFrameDialog
        hidden={!showSuccessDialog}
        onDismiss={hideSuccessDialog}
        dialogContentProps={{
          type: DialogType.normal,
          title: "Success!",
          closeButtonAriaLabel: "Close",
          subText: floatId
            ? "Training Details Updated Successfully."
            : "Training Details Submitted Successfully.",
        }}
        modalProps={modalProps}
        primaryButton={{ text: "Close", onClick: hideSuccessDialog }}
      />
      <CloudFrameDialog
        hidden={!showErrorDialog}
        onDismiss={closeErrorDialog}
        dialogContentProps={{
          type: DialogType.normal,
          title: "Error",
          subText: errorMessage,
        }}
        modalProps={{
          isBlocking: false,
        }}
        primaryButton={{ text: "Close", onClick: closeErrorDialog }}
      ></CloudFrameDialog>
    </div>
  );
};

export default TrainingForm;
