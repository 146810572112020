import { gql } from "@apollo/client";

export const QUERY_GET_CUSTOMERS = gql`
  query getCustomerDetails {
    getCustomerDetails {
      id
      customerName
      primaryContactName
      primaryContactEmail
      primaryContactPhone
      industry
      primaryLocation
      timezone
      keyNotes
      additionalInfo
      teamMembers {
        id
        name
        role
        organization
        phone
        email
      }
    }
  }
`;
