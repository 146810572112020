import { gql } from "@apollo/client";

export const MUTATION_CREATE_TRAINING = gql`
  mutation CreateTrainingDetails($data: TrainingDetailsInputType!) {
    createTrainingDetailsInfo(data: $data) {
      id
      course
      startDate
      endDate
      status
      sandboxProvided
      additionalInfo
      members {
        id
      }
    }
  }
`;

export const MUTATION_DELETE_TRAINING = gql`
  mutation DeleteTrainingDetails($id: Int!) {
    deleteTrainingDetails(id: $id)
  }
`;

// Define the updateTraining mutation
export const UPDATE_TRAINING_DETAILS = gql`
  mutation UpdateTrainingDetails($id: Int!, $data: TrainingDetailsInputType!) {
    updateTrainingDetails(id: $id, data: $data) {
      id
      course
      startDate
      endDate
      status
      sandboxProvided
      additionalInfo
      members {
        id
      }
    }
  }
`;
