import { gql } from "@apollo/client";

export const MUTATION_CREATE_CUSTOMER = gql`
  mutation createCustomer($data: CreateCustomerDetailsInputType!) {
    createCustomer(data: $data) {
      id
      customerName
      primaryContactName
      primaryContactEmail
      primaryContactPhone
      industry
      primaryLocation
      timezone
      keyNotes
      additionalInfo
      teamMembers {
        id
        name
        role
        organization
        phone
        email
      }
    }
  }
`;

export const MUTATION_EDIT_CUSTOMER = gql`
  mutation updateCustomerByID(
    $data: UpdateCustomerDetailsInputType!
    $updateCustomerByID: Float!
  ) {
    updateCustomerByID(data: $data, id: $updateCustomerByID) {
      id
      customerName
      primaryContactName
      primaryContactEmail
      primaryContactPhone
      industry
      primaryLocation
      timezone
      keyNotes
      additionalInfo
      teamMembers {
        id
        name
        role
        organization
        phone
        email
      }
    }
  }
`;

export const MUTATION_DELETE_CUSTOMER = gql`
  mutation deleteCustomer($deleteCustomerId: Float!) {
    deleteCustomer(id: $deleteCustomerId)
  }
`;
