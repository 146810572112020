import { gql } from '@apollo/client';

// Query to fetch all email service configurations
export const QUERY_GET_ALL_EMAIL_CONFIGS = gql`
  query GetAllEmailConfigs {
    findAllEmailServiceConfig {
      id
      configurationType
      thresholdPeriod
      emailReceivers
      emailBody
      emailBodyVariables
    }
  }
`;

// Query to fetch a specific email service configuration by its ID
export const QUERY_GET_EMAIL_CONFIG_BY_ID = gql`
  query GetEmailConfigById($id: Float!) {
    findByIdEmailServiceConfig(id: $id) {
      id
      configurationType
      thresholdPeriod
      emailReceivers
      emailBody
      emailBodyVariables
    }
  }
`;
