import { useMutation, useQuery } from "@apollo/client";
import {
  CloudFrameDefaultButton,
  CloudFramePrimaryButton,
} from "@cloudframe/button";
import { CloudFrameDropdown } from "@cloudframe/dropdown";
import { CloudFrameTextBox } from "@cloudframe/textbox";
import { FormEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../../@styles/css/form-element.css";
import { IForm } from "../../@types/form.types";
import { isBlank } from "../../utils/string.utils";
import { useStyles } from "./member-form.styles";
import {
  MUTATION_CREATE_MEMBER,
  MUTATION_EDIT_MEMBER,
} from "../../graphql-mutations/member.mutation";
import {
  GET_CUSTOMER_OPTIONS,
  GET_PARTNER_OPTIONS,
  GET_TRAINING_OPTIONS,
} from "../../graphql-query/member.query";
import { CloudFrameDialog } from "@cloudframe/dialog";
import { DialogType, IModalProps } from "@fluentui/react";
import { ORGANIZATION_TYPE } from "../../constants/project.enum";
import { getKeysAsObjects } from "../../utils/object.util";

export interface Customer {
  id: number;
  customerName: string;
}

export interface Partner {
  id: number;
  partnerName: string;
}

export interface Training {
  id: number;
  course: string;
}

const MemberForm = (props: IForm) => {
  const { data } = props;
  const classes = useStyles();
  const [formValues, setFormValues] = useState<any>({});
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [formSubmitAttempted, setFormSubmitAttempted] = useState(false);
  const [errors, setErrors] = useState<any>({});
  const emailPattern = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
  const phonePattern = /^\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}$/;
  const { data: partnerData } = useQuery(GET_PARTNER_OPTIONS);
  const { data: customerData } = useQuery(GET_CUSTOMER_OPTIONS);
  const { data: trainingData } = useQuery(GET_TRAINING_OPTIONS);
  const [createTeamMemberInfo] = useMutation(MUTATION_CREATE_MEMBER);
  const [updateTeamMember] = useMutation(MUTATION_EDIT_MEMBER);
  const navigate = useNavigate();

  const [showSuccessDialog, setShowSuccessDialog] = useState(false);

  useEffect(() => {
    const savedDataString = sessionStorage.getItem("memberData");
    if (savedDataString) {
      const savedData = JSON.parse(savedDataString);
      delete savedData.__typename;
      setFormValues({
        ...savedData,
        trainings: savedData.trainings.map((training: any) => training.id),
        customer:
          savedData.organization === "Customer" ? savedData.customer.id : "",
        partner:
          savedData.organization === "CF-Partner" ? savedData.partner.id : "",
        cfSbDetails: savedData.cfSbDetails === "true" ? "yes" : "no",
      });
    } else {
      setFormValues({
        name: "",
        role: "",
        organization: ORGANIZATION_TYPE,
        phone: "",
        email: "",
        cfSbDetails: "no",
        customer: "",
        partner: "",
        trainings: [],
      });
    }
  }, [data]);

  const validateForm = () => {
    const newErrors: any = {};
    if (isBlank(formValues.name)) {
      newErrors.name = "Please enter the Name";
    }
    if (isBlank(formValues.role)) {
      newErrors.role = "Please enter the Role";
    }
    if (isBlank(formValues.organization)) {
      newErrors.organization = "Please enter the organization";
    }
    if (isBlank(formValues.phone)) {
      newErrors.phone = "Please enter the Contact Number";
    } else if (
      !/^\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}$/.test(formValues.phone)
    ) {
      newErrors.phone = "Please enter a valid Contact Number";
    }

    if (isBlank(formValues.email)) {
      newErrors.email = "Please enter the Email ID";
    } else if (!emailPattern.test(formValues.email)) {
      newErrors.email = "Please enter a valid Email ID";
    }
    if (formValues.cfSbDetails === undefined || formValues.cfSbDetails === "") {
      newErrors.cfSbDetails = "Please select an option";
    }
    if (
      formValues.organization === "Customer" &&
      isBlank(formValues.customer)
    ) {
      newErrors.customer = "Please enter Customer";
    }
    if (
      formValues.organization === "CF-Partner" &&
      isBlank(formValues.partner)
    ) {
      newErrors.partner = "Please enter the partner";
    }
    /*if (!formValues.trainings || !formValues.trainings.length) {
      newErrors.trainings = "Please enter the training details";
    }*/

    setErrors({ ...newErrors });
    return !Object.keys(newErrors).length;
  };

  const updateTeamMemberDetails = async () => {
    const {
      id,
      name,
      role,
      organization,
      phone,
      email,
      cfSbDetails,
      customer,
      partner,
      trainings = [],
    } = formValues;

    const result = await updateTeamMember({
      variables: {
        id: formValues.id,
        data: {
          name,
          role,
          organization,
          phone,
          email,
          cfSbDetails: cfSbDetails === "yes" ? "true" : "false",
          customer: customer ? parseInt(customer) : null,
          partner: partner ? parseInt(partner) : null,
          trainings: trainings.map((training: any) => parseInt(training)),
        },
      },
    });
    if (result.data.updateTeamMember) {
      sessionStorage.removeItem("memberData");
      setShowSuccessDialog(true);
    } else {
      console.error("Error updating member:", result.errors);
    }
  };

  const onFormSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setFormSubmitting(true);
    setFormSubmitAttempted(true);
    const isFormValid = validateForm();
    try {
      if (isFormValid) {
        if (formValues.id) {
          await updateTeamMemberDetails();
        } else {
          const formData = {
            ...formValues,
            cfSbDetails: formValues.cfSbDetails === "yes" ? "true" : "false",
            customer: formValues.customer
              ? parseInt(formValues.customer)
              : null,
            partner: formValues.partner ? parseInt(formValues.partner) : null,
            trainings: formValues.trainings
              ? formValues.trainings.map((id: any) => parseInt(id))
              : [],
          };
          const result = await createTeamMemberInfo({
            variables: { data: formData },
          });
          if (result?.data?.createTeamMemberInfo) {
            sessionStorage.removeItem("memberData");
            setShowSuccessDialog(true);
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
    setFormSubmitting(false);
  };

  const handleReset = () => {
    setFormValues({
      ...formValues,
      name: "",
      role: "",
      organization: ORGANIZATION_TYPE,
      phone: "",
      email: "",
      cfSbDetails: false,
      customer: "",
      partner: "",
      trainings: [],
    });
    setErrors({});
  };

  const hideSuccessDialog = () => {
    setShowSuccessDialog(false);
    navigate("/member-list");
  };

  const modalProps: IModalProps = {
    isBlocking: false,
  };

  return (
    <div className={classes.root}>
      <div
        id="form"
        className={classes.formBody}
        style={{ borderRadius: "25px", minHeight: "700px" }}
      >
        <div className={classes.subContainer}>
          <h2>{formValues.id ? "Edit Member" : "Create New Member"}</h2>
          <form onSubmit={onFormSubmit}>
            <CloudFrameTextBox
              onChange={(_, value) => {
                setFormValues({ ...formValues, name: value });
              }}
              required
              label="Name"
              className={classes.mb8}
              value={formValues.name}
              errorMessage={errors.name}
            />
            <CloudFrameTextBox
              onChange={(_, value) => {
                setFormValues({ ...formValues, role: value });
              }}
              required
              label="Role"
              className={classes.mb8}
              value={formValues.role}
              errorMessage={errors.role}
            />
            <CloudFrameDropdown
              label="Organization"
              required
              className={classes.mb8}
              options={getKeysAsObjects(ORGANIZATION_TYPE)}
              onChange={(_, option) => {
                const updatedFormValues = {
                  ...formValues,
                  organization: option?.key,
                };
                if (option?.key !== "Customer") {
                  updatedFormValues.customer = "";
                }
                if (option?.key !== "CF_Partner") {
                  updatedFormValues.partner = "";
                }
                setFormValues(updatedFormValues);
              }}
              errorMessage={errors.organization}
              selectedKey={formValues.organization}
            />

            {formValues.organization === "Customer" && (
              <CloudFrameDropdown
                placeholder="Select a customer"
                label="Customer"
                required
                className={classes.mb8}
                options={
                  customerData?.getCustomerDetails.map((customer: any) => ({
                    key: customer.id,
                    text: customer.customerName,
                  })) || []
                }
                onChange={(_, option) =>
                  setFormValues({ ...formValues, customer: option?.key })
                }
                errorMessage={errors.customer}
                selectedKey={formValues.customer}
              />
            )}
            {formValues.organization === "CF_Partner" && (
              <CloudFrameDropdown
                placeholder="Select a partner"
                label="Partner"
                required
                className={classes.mb8}
                options={
                  partnerData?.getPartnerDetails.map((partner: any) => ({
                    key: partner.id,
                    text: partner.partnerName,
                  })) || []
                }
                onChange={(_, option) =>
                  setFormValues({ ...formValues, partner: option?.key })
                }
                errorMessage={errors.partner}
                selectedKey={formValues.partner}
              />
            )}
            <CloudFrameTextBox
              onChange={(_, value) => {
                setFormValues({ ...formValues, phone: value });
              }}
              label="Contact Number"
              required
              className={classes.mb8}
              value={formValues.phone}
              errorMessage={errors.phone}
              onBlur={() => {
                if (!phonePattern.test(formValues.phone)) {
                  setErrors({
                    ...errors,
                    phone: "Please enter a valid contact number",
                  });
                } else {
                  setErrors({ ...errors, phone: undefined });
                }
              }}
            />
            <CloudFrameTextBox
              onChange={(_, value) => {
                setFormValues({ ...formValues, email: value });
              }}
              required
              label="Email Id"
              className={classes.mb8}
              value={formValues.email}
              errorMessage={errors.email}
              onBlur={() => {
                if (!emailPattern.test(formValues.email)) {
                  setErrors({
                    ...errors,
                    email: "Please enter a valid email address",
                  });
                } else {
                  setErrors({ ...errors, email: undefined });
                }
              }}
            />

            <CloudFrameDropdown
              placeholder="Select an option"
              label="Is CF Sandbox used by Member?"
              required
              selectedKey={formValues.cfSbDetails}
              errorMessage={errors.cfSbDetails}
              onChange={(e, item: any) => {
                setFormValues({
                  ...formValues,
                  cfSbDetails: item.key,
                });
                setErrors({ ...errors, cfSbDetails: undefined });
              }}
              className={classes.mb8}
              options={[
                { key: "yes", text: "Yes" },
                { key: "no", text: "No" },
              ]}
            />

            <CloudFrameDropdown
              placeholder="Select trainings"
              label="Trainings"
              className={classes.mb8}
              multiSelect
              options={
                trainingData?.getTrainingDetails.map((training: any) => ({
                  key: training.id,
                  text: training.course,
                })) || []
              }
              onChange={(_, option) => {
                const newTrainings = option?.selected
                  ? [...(formValues.trainings || []), option.key]
                  : formValues.trainings.filter(
                      (id: any) => id !== option?.key
                    );
                setFormValues({ ...formValues, trainings: newTrainings });
              }}
              errorMessage={errors.trainings}
              selectedKeys={formValues.trainings}
            />

            <div className={classes.btnContainer}>
              <CloudFramePrimaryButton
                text={formValues.id ? "Update" : "Submit"}
                type="submit"
                className={`${classes.mt16} ${classes.ml2}`}
                disabled={formSubmitting}
              />
              <CloudFrameDefaultButton
                text={"Reset"}
                type="button"
                className={`${classes.mt16} ${classes.ml2}`}
                onClick={handleReset}
                disabled={formSubmitting}
              />
              <CloudFrameDialog
                hidden={!showSuccessDialog}
                onDismiss={hideSuccessDialog}
                dialogContentProps={{
                  type: DialogType.normal,
                  title: "Success!",
                  closeButtonAriaLabel: "Close",
                  subText: formValues.id
                    ? "Your form has been successfully updated."
                    : "Your form has been successfully submitted.",
                }}
                modalProps={modalProps}
                primaryButton={{ text: "Close", onClick: hideSuccessDialog }}
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default MemberForm;
