import React, { useState, useEffect, FormEvent, ReactElement } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { CloudFramePrimaryButton } from "@cloudframe/button";
import { CloudFrameDropdown } from "@cloudframe/dropdown";
import { CloudFrameTextBox } from "@cloudframe/textbox";
import { CloudFrameDialog } from "@cloudframe/dialog";
import { useStyles } from "./create-email-service-config.style";
import { useMutation, useQuery } from '@apollo/client';
import { MUTATION_PERSIST_EMAIL_CONFIG, MUTATION_DELETE_EMAIL_CONFIG } from '../../graphql-mutations/email-service-config.mutation';
import { QUERY_GET_EMAIL_CONFIG_BY_ID } from '../../graphql-query/email-service-config.query';
import { isBlank } from '../../utils/string.utils';
import { DialogType, IDropdownOption, IDropdownProps, IModalProps, ITextFieldProps } from '@fluentui/react';
import CustomLabel from '../custom-label/custom-label';

interface ILocationState {
  id?: string;
}

const ConfigurationForm = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state as ILocationState;
  const floatId = state?.id ? parseInt(state.id, 10) : undefined;

  const [formValues, setFormValues] = useState<any>({
    configurationType: "",
    thresholdPeriod: 0,
    emailReceivers: "",
    emailBody: "",
    emailBodyVariables: "",
  });
  const [errors, setErrors] = useState<any>({});
  const [errorMessage, setErrorMessage] = useState("");
  const [showErrorDialog, setShowErrorDialog] = useState(false);
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [persistEmailConfig] = useMutation(MUTATION_PERSIST_EMAIL_CONFIG);
  const [deleteEmailConfig] = useMutation(MUTATION_DELETE_EMAIL_CONFIG);
  const { data: configurationData } = useQuery(QUERY_GET_EMAIL_CONFIG_BY_ID, {
    variables: { id: floatId },
    skip: !floatId,
  });
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);

  useEffect(() => {
    if (configurationData && configurationData.findByIdEmailServiceConfig) {
      const { findByIdEmailServiceConfig } = configurationData;
      setFormValues({
        configurationType: findByIdEmailServiceConfig.configurationType ?? "",
        thresholdPeriod: findByIdEmailServiceConfig.thresholdPeriod ?? 0,
        emailReceivers: findByIdEmailServiceConfig.emailReceivers ?? "",
        emailBody: findByIdEmailServiceConfig.emailBody ?? "",
        emailBodyVariables: findByIdEmailServiceConfig.emailBodyVariables ?? "",
      });
    }
  }, [configurationData]);

  const handleInputChange = (event: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined) => {
    const { name, value } = event.currentTarget;
    setFormValues({
      ...formValues,
      [name]: name === "thresholdPeriod" ? parseFloat(value) : value
    });
  };

  const handleDropdownChange = (event: FormEvent<HTMLDivElement>, option?: IDropdownOption<any>, index?: number | undefined) => {
    if (option) {
      setFormValues({ ...formValues, configurationType: option.key });
    }
  };

  const validateForm = (formValues: any) => {
    const newErrors: any = {};
  
    if (isBlank(formValues.configurationType)) {
      newErrors.configurationType = "Please select the configuration type.";
    }
  
    if (isBlank(formValues.thresholdPeriod)) {
      newErrors.thresholdPeriod = "Threshold period is required.";
    }
  
    if (isBlank(formValues.emailReceivers)) {
      newErrors.emailReceivers = "Email receivers are required.";
    }
  
    if (isBlank(formValues.emailBody)) {
      newErrors.emailBody = "Email body is required.";
    }
  
    return newErrors;
  };

  const resetForm = () => {
    setFormValues({
      configurationType: "",
      thresholdPeriod: 0,
      emailReceivers: "",
      emailBody: "",
      emailBodyVariables: "",
    });
    setErrors({});
  };

  const submitForm = async (e: FormEvent) => {
    e.preventDefault();
    const validationErrors = validateForm(formValues);
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      console.log("Please fill all the required fields");
      return;
    }
   
    setFormSubmitting(true);
    console.log(formValues);

    try {
      const { data } = await persistEmailConfig({ variables: { ...formValues, id: floatId } });
      console.log('Response from backend:', data);

      resetForm();
      setFormSubmitting(false);
      setShowSuccessDialog(true);
      navigate('/email-service-config');

    } catch (error) {
      console.error('Error submitting form:', error);
      setFormSubmitting(false);
      setErrorMessage("Error in submitting the configuration");
      setShowErrorDialog(true);
    }
  };

  const hideSuccessDialog = () => {
    setShowSuccessDialog(false);
    navigate('/configuration-details');
  };

  const modalProps: IModalProps = {
    isBlocking: false,
  };
  
  const closeErrorDialog = () => {
    setShowErrorDialog(false);
    setErrorMessage('');
  };

  const renderLabel = (
    props: ITextFieldProps | IDropdownProps | undefined
  ): ReactElement =>
    props ? (
      <CustomLabel id={props.label?.replaceAll(" ", "")} {...props} />
    ) : (
      <div />
    );
    
  return (
    <div className={classes.root} style={{ marginTop: "15px" }}>
      <div id="form" className={classes.formBody} style={{ borderRadius: "25px" }}>
        <div className={classes.subContainer}>
          <h3>{floatId ? "Edit Configuration Form" : "New Configuration Form"}</h3>
          <form onSubmit={submitForm}>
            <br />
            <CloudFrameDropdown
              placeholder="Select Configuration Type"
              required={true}
              label="Configuration Type"
              options={[
                { key: "LICENSE_EXPIRY_NOTIFICATION", text: "LICENSE_EXPIRY_NOTIFICATION" },
              ]}
              selectedKey={formValues.configurationType}
              onChange={handleDropdownChange}
              errorMessage={errors.configurationType}
            />
            <br />
            <CloudFrameTextBox
              type="number"
              name="thresholdPeriod"
              label="Threshold Period"
              placeholder="Threshold Period"
              value={formValues.thresholdPeriod}
              required={true}
              onChange={handleInputChange}
              errorMessage={errors.thresholdPeriod}
            />
            <br />
            <CloudFrameTextBox
              type="text"
              name="emailReceivers"
              label="Email Receivers"
              placeholder="Email Receivers"
              value={formValues.emailReceivers}
              required={true}
              onChange={handleInputChange}
              errorMessage={errors.emailReceivers}
            />
            <br />
            <CloudFrameTextBox
              type="textarea"
              name="emailBody"
              label="Email Body"
              ariaLabel={`Please enter the Email Body as a template with the help of placeholder variables.\nEx: Hello [username], your license expires on [date]. Please contact [admin].`}
              placeholder="Email Body"
              value={formValues.emailBody}
              required={true}
              multiline={true}
              rows={4}
              onChange={handleInputChange}
              errorMessage={errors.emailBody}
              onRenderLabel={renderLabel}
            />
            <br />
            <CloudFrameTextBox
              type="text"
              name="emailBodyVariables"
              label="Email Body Variables"
              placeholder="Email Body Variables"
              value={formValues.emailBodyVariables}
              onChange={handleInputChange}
              ariaLabel='Please enter the actual values of the placeholders.(Ex: username=ABC; date=dd/mm/yyyy; admin=XYZ)'
              onRenderLabel={renderLabel}
            />
            <br />
            <div style={{ display: "flex", gap: "10px" }}>
              <CloudFramePrimaryButton text="Reset" onClick={resetForm} />
              <CloudFramePrimaryButton text={floatId ? "Update" : "Submit"} type="submit" disabled={formSubmitting} />
            </div>
          </form>
        </div>
      </div>
      <CloudFrameDialog
        hidden={!showSuccessDialog}
        onDismiss={hideSuccessDialog}
        dialogContentProps={{
          type: DialogType.normal,
          title: "Success!",
          closeButtonAriaLabel: "Close",
          subText: floatId ? "Configuration Details Updated Successfully." : "Configuration Details Submitted Successfully."
        }}
        modalProps={modalProps}
        primaryButton={{ text: "Close", onClick: hideSuccessDialog }}
      />
      <CloudFrameDialog
        hidden={!showErrorDialog}
        onDismiss={closeErrorDialog}
        dialogContentProps={{
          type: DialogType.normal,
          title: 'Error',
          subText: errorMessage
        }}
        modalProps={{
          isBlocking: false,
        }}
        primaryButton={{ text: "Close", onClick: closeErrorDialog }}
      ></CloudFrameDialog>
    </div>
  );
};

export default ConfigurationForm;
