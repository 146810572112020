import { useMutation, useQuery } from "@apollo/client";
import { CloudFramePrimaryButton } from "@cloudframe/button";
import { CloudFrameDataTable } from "@cloudframe/data-table";
import { CloudFrameTextBox } from "@cloudframe/textbox";
import {
  DialogType,
  IColumn,
  IIconProps,
  Modal,
  SelectionMode,
} from "@fluentui/react";
import { useEffect, useState } from "react";
import { useId } from "@fluentui/react-hooks";
import { useContainerStyles } from "../../@styles/container.styles";
import "../../@styles/css/form-element.css";
import { useListStyles } from "../../@styles/list.styles";
import { UserPermission } from "../../constants/permissions.constants";
import Authorization from "../auth/authorization";
import { QUERY_GET_CUSTOMERS } from "../../graphql-query/customer.query";
import {
  MUTATION_EDIT_CUSTOMER,
  MUTATION_DELETE_CUSTOMER,
} from "../../graphql-mutations/customer.mutation";
import { useNavigate, useSearchParams } from "react-router-dom";
import ActionButtons from "../action-buttons/action-buttons";
import { saveAs } from "file-saver";
import { IActionButtonTitle } from "../action-buttons/action-button.intefaces";
import { IDialogProps as IMessageDialogProps } from "../../@types/form.types";
import { CloudFrameDialog } from "@cloudframe/dialog";

const CustomerList = () => {
  const customerListQuery = useQuery(QUERY_GET_CUSTOMERS);
  const [customerList, setCustomerList] = useState<Array<any>>([]);
  const [activeItem, setActiveItem] = useState<any>({});
  const [selectedCustomer, setSelectedCustomer] = useState<any | null>(null);
  const [showSelectedCustomer, setShowSelectedCustomer] = useState(false);
  const [hideDialog, setHideDialog] = useState(true);
  const [messgeInfo, setMessageInfo] = useState<IMessageDialogProps>();
  const [showErrorDialog, setShowErrorDialog] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [messageHidden, setMessageHidden] = useState(true);
  const labelId = useId("dialogLabel");
  const subTextId = useId("subTextLabel");
  const [deleteCustomer] = useMutation(MUTATION_DELETE_CUSTOMER);
  const [editCustomer] = useMutation(MUTATION_EDIT_CUSTOMER);

  const classes = useListStyles();
  const contClasses = useContainerStyles();
  const listClasses = useListStyles();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const searchIcon: IIconProps = { iconName: "Search" };

  const onViewCustomer = (item: any) => {
    setSelectedCustomer(item);
    setShowSelectedCustomer(true);
  };

  const hideConfirmationDialog = () => {
    setHideDialog(true);
  };

  const onRevokeCustomer = async () => {
    if (!activeItem.item?.id) {
      return;
    }
    hideConfirmationDialog();
    const result = await deleteCustomer({
      variables: {
        deleteCustomerId: activeItem.item.id,
      },
    });
    const updatedCustomerList = customerList.filter(
      (customer) => customer !== activeItem
    );

    setCustomerList(updatedCustomerList);

    if (result.data.deleteCustomer) {
      await customerListQuery.refetch();
    }

    //console.error("Error deleting customer:", error);
    // setErrorMessage(errorMessage);
    // setShowErrorDialog(true);
  };
  const hideErrorDialog = () => {
    setShowErrorDialog(false);
  };

  const [customerData, setCustomerData] = useState({
    id: null,
    customerName: "",
    primaryContactName: "",
    primaryContactEmail: "",
    primaryContactPhone: "",
    industry: "",
    primaryLocation: "",
    timezone: "",
    keyNotes: "",
    additionalInfo: "",
    teamMembers: [],
  });

  useEffect(() => {
    loadCustomerList().then(() => {
      console.log("List loaded");
    });
  }, [customerListQuery.data]);

  const handleEdit = (index) => {
    sessionStorage.setItem("customerData", JSON.stringify(customerList[index]));
    navigate("/create-customer");
  };

  const loadCustomerProps = (customer: any[]): any[] => {
    return customer.map((customer) => {
      return {
        ...customer,
      };
    });
  };

  const loadCustomerList = async () => {
    let data: Array<any> = customerListQuery.data?.getCustomerDetails;
    if (data) {
      const formattedData = loadCustomerProps(data);
      setCustomerList([...formattedData]);
    }
  };

  const getActions = (props: any): IActionButtonTitle => {
    const actionButtonTitles: IActionButtonTitle = {
      viewTitle: "View Customer",
      deleteTitle: "Delete Customer",
      editTitle: "Edit Customer",
    };
    return actionButtonTitles;
  };

  const columns: IColumn[] = [
    {
      key: "customerName",
      name: "CustomerName",
      fieldName: "customerName",
      minWidth: 90,
      maxWidth: 100,
      isRowHeader: true,
      isResizable: true,
      isFiltered: false,
      data: "string",
      isPadded: true,
    },
    {
      key: "primaryContactEmail",
      name: "Email",
      fieldName: "primaryContactEmail",
      minWidth: 90,
      maxWidth: 100,
      isRowHeader: true,
      isResizable: true,
      isFiltered: false,
      data: "string",
      isPadded: true,
    },
    {
      key: "industry",
      name: "Industry",
      fieldName: "industry",
      minWidth: 90,
      maxWidth: 100,
      isRowHeader: true,
      isResizable: true,
      isFiltered: false,
      data: "string",
      isPadded: true,
    },
    {
      key: "primaryLocation",
      name: "PrimaryLocation",
      fieldName: "primaryLocation",
      minWidth: 90,
      maxWidth: 100,
      isRowHeader: true,
      isResizable: true,
      isFiltered: false,
      data: "string",
      isPadded: true,
    },
    {
      key: "action",
      name: "Action",
      minWidth: 100,
      maxWidth: 100,
      isRowHeader: true,
      isResizable: true,
      isFiltered: true,
      isPadded: true,
      data: "string",
      onRender: (props, index) => {
        return (
          <ActionButtons
            onEdit={() => handleEdit(index)}
            onDelete={() => setHideDialog(false)}
            onView={onViewCustomer}
            row={props}
            buttonTitles={getActions(props)}
          />
        );
      },
    },
  ];

  const dialogStyles = { main: { maxWidth: 150 } };

  const modalProps = {
    titleAriaId: labelId,
    subtitleAriaId: subTextId,
    isBlocking: false,
    styles: dialogStyles,
    dragOptions: undefined,
  };

  const filterList = (event: any) => {
    const customers = loadCustomerProps(
      customerListQuery.data?.getCustomerDetails || []
    );
    let textFilter = event.currentTarget.value.toLowerCase();
    if (!textFilter) {
      setTimeout(() => {
        setCustomerList(customers);
      });
    } else {
      const data = customers.filter(
        (l: any) =>
          `${l.customerName}`.toLowerCase().includes(textFilter) ||
          `${l.industry}`.toLowerCase().includes(textFilter) ||
          `${l.primaryLocation}`.toLowerCase().includes(textFilter)
      );
      setCustomerList(data);
    }
  };

  const exportToCSV = () => {
    const csvContent = prepareCSVData();
    if (csvContent) {
      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8" });
      saveAs(blob, "customer_list.csv");
    } else {
      console.error("CSV content is undefined");
    }
  };
  const prepareCSVData = () => {
    if (customerListQuery.data && customerListQuery.data.getCustomerDetails) {
      const headers = [
        "Customer Name",
        "primaryContactName",
        "primaryContactEmail",
        "primaryContactPhone",
        "industry",
        "primaryLocation",
        "timezone",
        "keyNotes",
        "additionalInfo",
        "teamMembers",
      ].join(",");

      // Extract data for each customer and format it as CSV rows
      const rows = customerListQuery.data.getCustomerDetails.map((customer) => {
        const teamMembers = customer.teamMembers
          ? customer.teamMembers
              .map((teamMembers: any) => teamMembers.name)
              .join(", ")
          : "";

        const primaryContactPhone = `\t${customer.primaryContactPhone}`;

        return [
          customer.customerName,
          customer.primaryContactName,
          customer.primaryContactEmail,
          primaryContactPhone,
          customer.industry,
          customer.primaryLocation,
          customer.timezone,
          customer.keyNotes,
          customer.additionalInfo,
          teamMembers,
        ].join(",");
      });
      const csvContent = [headers, ...rows].join("\n");

      return csvContent;
    } else {
      console.error(
        "Customer data is not available or has unexpected structure"
      );
      return "";
    }
  };

  const modalStyles = {
    root: {
      height: "600px",
      overflow: "auto" as "auto",
    },
  };

  return (
    <Authorization>
      <section key={UserPermission.P_MEMBER_LIST}>
        <div style={{ marginTop: "1rem", maxWidth: "50%" }}>
          <h3>Customer List</h3>
          <hr />
          <div className={contClasses.flexStartContainer}>
            <div style={{ width: "150rem", marginTop: "-2rem" }}>
              <CloudFrameTextBox
                placeholder="Search"
                iconProps={searchIcon}
                onChange={(e) => filterList(e)}
                className={listClasses.searchBar}
              />
            </div>
            <div
              style={{
                width: "auto",
                marginBottom: "1rem",
                marginLeft: "35rem",
                marginTop: "-2.8rem",
              }}
            >
              <CloudFramePrimaryButton onClick={exportToCSV}>
                Export to CSV
              </CloudFramePrimaryButton>
            </div>
          </div>
          <div style={{ width: "auto", marginTop: "-2rem" }}>
            <CloudFrameDataTable
              items={customerList || []}
              selectionMode={SelectionMode.none}
              columns={columns}
              className={`${classes.tableBody}`}
              onActiveItemChanged={(item, index) =>
                setActiveItem({ item, index })
              }
              compact
            />
          </div>
        </div>
        <CloudFrameDialog
          hidden={hideDialog}
          onDismiss={hideConfirmationDialog}
          dialogContentProps={{
            type: DialogType.normal,
            title: "WARNING",
            closeButtonAriaLabel: "Close",
            subText:
              "Deleting this customer will delete the Team members associated with this Customer and Remove the Customer from project ,Are you sure you want to delete this customer?",
          }}
          modalProps={modalProps}
          primaryButton={{ text: "Delete", onClick: onRevokeCustomer }}
          secondaryButton={{ text: "Cancel", onClick: hideConfirmationDialog }}
        />
        <CloudFrameDialog
          hidden={messageHidden}
          onDismiss={() => setMessageHidden(true)}
          dialogContentProps={{
            type: DialogType.normal,
            title: messgeInfo?.title,
            closeButtonAriaLabel: "Close",
            subText: messgeInfo?.message,
          }}
          modalProps={modalProps}
          primaryButton={{
            text: "Close",
            onClick: () => setMessageHidden(true),
          }}
        />
        <Modal
          isOpen={selectedCustomer !== null}
          onDismiss={() => setSelectedCustomer(null)}
          styles={modalStyles}
        >
          <div
            style={{
              padding: "40px",
              borderRadius: "30px",
              backgroundColor: "#ffffff",
            }}
          >
            <h2 style={{ textAlign: "center" }}>Customer Details</h2>
            <div>
              <table style={{ width: "100%", borderCollapse: "collapse" }}>
                <tr>
                  <td style={{ border: "1px solid", padding: "5px" }}>
                    <b>Customer Name</b>
                  </td>
                  <td style={{ border: "1px solid", padding: "5px" }}>
                    {selectedCustomer?.customerName}
                  </td>
                </tr>
                <tr>
                  <td style={{ border: "1px solid", padding: "5px" }}>
                    <b>Primary Contact Name</b>
                  </td>
                  <td style={{ border: "1px solid", padding: "5px" }}>
                    {selectedCustomer?.primaryContactName}
                  </td>
                </tr>
                <tr>
                  <td style={{ border: "1px solid", padding: "5px" }}>
                    <b>Primary Contact Email</b>
                  </td>
                  <td style={{ border: "1px solid", padding: "5px" }}>
                    {selectedCustomer?.primaryContactEmail}
                  </td>
                </tr>
                <tr>
                  <td style={{ border: "1px solid", padding: "5px" }}>
                    <b>Primary Contact Phone</b>
                  </td>
                  <td style={{ border: "1px solid", padding: "5px" }}>
                    {selectedCustomer?.primaryContactPhone}
                  </td>
                </tr>
                <tr>
                  <td style={{ border: "1px solid", padding: "5px" }}>
                    <b>Industry</b>
                  </td>
                  <td style={{ border: "1px solid", padding: "5px" }}>
                    {selectedCustomer?.industry}
                  </td>
                </tr>
                <tr>
                  <td style={{ border: "1px solid", padding: "5px" }}>
                    <b>Primary Location</b>
                  </td>
                  <td style={{ border: "1px solid", padding: "5px" }}>
                    {selectedCustomer?.primaryLocation}
                  </td>
                </tr>
                <tr>
                  <td style={{ border: "1px solid", padding: "5px" }}>
                    <b>Timezone</b>
                  </td>
                  <td style={{ border: "1px solid", padding: "5px" }}>
                    {selectedCustomer?.timezone}
                  </td>
                </tr>
                <tr>
                  <td style={{ border: "1px solid", padding: "5px" }}>
                    <b>Key Notes</b>
                  </td>
                  <td style={{ border: "1px solid", padding: "5px" }}>
                    {selectedCustomer?.keyNotes}
                  </td>
                </tr>
                <tr>
                  <td style={{ border: "1px solid", padding: "5px" }}>
                    <b>Additional Information</b>
                  </td>
                  <td style={{ border: "1px solid", padding: "5px" }}>
                    {selectedCustomer?.additionalInfo}
                  </td>
                </tr>
                <tr>
                  <td style={{ border: "1px solid", padding: "5px" }}>
                    <b>Team Members</b>
                  </td>
                  <td style={{ border: "1px solid", padding: "5px" }}>
                    {selectedCustomer?.teamMembers
                      ?.map((teamMembers: any) => teamMembers.name)
                      .join(", ") || "N/A"}
                  </td>
                </tr>
              </table>
            </div>
            <div style={{ marginBottom: "1rem" }} />
            <div style={{ textAlign: "center" }}>
              <CloudFramePrimaryButton
                onClick={() => setSelectedCustomer(null)}
                text="Close"
              />
            </div>
          </div>
        </Modal>
      </section>
    </Authorization>
  );
};

export default CustomerList;
