import { useMutation, useQuery } from "@apollo/client";
import { CloudFramePrimaryButton } from "@cloudframe/button";
import { CloudFrameDataTable } from "@cloudframe/data-table";
import { CloudFrameTextBox } from "@cloudframe/textbox";
import {
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  IColumn,
  IIconProps,
  Modal,
  PrimaryButton,
  SelectionMode,
} from "@fluentui/react";
import { saveAs } from "file-saver";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useContainerStyles } from "../../@styles/container.styles";
import "../../@styles/css/form-element.css";
import { useListStyles } from "../../@styles/list.styles";
import {
  MUTATION_DELETE_PROJECT,
  MUTATION_UPDATE_PROJECT,
} from "../../graphql-mutations/projectManagement.mutation";

import { QUERY_GET_PROJECTS } from "../../graphql-query/project.query"; // Assuming QUERY_GET_PROJECT_LIST is defined
import { toISOShortDateString } from "../../utils/date.utils";
import ActionButtons from "../action-buttons/action-buttons";
import { UserPermission } from "../../constants/permissions.constants";
import Authorization from "../auth/authorization";
const PAGE_SIZE = 10;
const ProjectList = () => {
  const projectListQuery = useQuery(QUERY_GET_PROJECTS);
  console.log(projectListQuery);
  const totalPages1 = Math.ceil(
    projectListQuery.data?.getProjects?.length / PAGE_SIZE
  );
  const [activeItem, setActiveItem] = useState<any>({});

  const [projectList, setProjectList] = useState<Array<any>>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const [selectedProject, setSelectedProject] = useState<any>(null);
  const [hideDialog, setHideDialog] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const classes = useListStyles();
  const contClasses = useContainerStyles();
  const listClasses = useListStyles();
  const navigate = useNavigate();
  const [showDialog, setShowDialog] = useState(false);
  const [selectedProjectIndex, setSelectedProjectIndex] = useState(-1);
  const { data: projectData, refetch } = useQuery(QUERY_GET_PROJECTS);

  const [deleteProject] = useMutation(MUTATION_DELETE_PROJECT, {
    onCompleted: () => {
      refetch();
      setShowDialog(false);
    },
    onError: (error) => {
      console.error("Error deleting project:", error);
      setError("Error deleting project. Please try again later.");
    },
  });
  useEffect(() => {
    if (projectData) {
      setProjectList(formatProjectData(projectData.getProjects));
    }
  }, [projectData]);

  const [updateProject] = useMutation(MUTATION_UPDATE_PROJECT);
  const [projectId, setProjectId] = useState("");
  const [updatedData, setUpdatedData] = useState({});

  const confirmDeleteProject = async () => {
    try {
      if (!activeItem.item?.id) {
        console.error("No project selected for deletion.");
        return;
      }
      hideConfirmationDialog();

      const result = await deleteProject({
        variables: {
          id: activeItem.item.id,
        },
      });
      if (result.data.deleteProjectDetails) {
        await projectListQuery.refetch();
      }
      setSelectedProject(null);
      setShowDialog(false);
    } catch (error) {
      console.error("Error deleting project:", error);
      setError("Error deleting project. Please try again later.");
    }
  };

  const cancelDeleteProject = () => {
    setSelectedProjectIndex(-1);
    setShowDialog(false);
  };
  const handleDeleteProject = (index: number) => {
    setSelectedProjectIndex(index);
    setShowDialog(true);
  };
  const searchIcon: IIconProps = { iconName: "Search" };
  const loadProjectList1 = () => {
    const startIndex = (currentPage - 1) * PAGE_SIZE;
    const endIndex = startIndex + PAGE_SIZE;
    const currentPageData = projectList?.slice(startIndex, endIndex) || [];
    return currentPageData;
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const handleShowDetails = (item: any) => {
    setActiveItem(item);
    setSelectedProject(item);
  };
  const hideConfirmationDialog = () => {
    setHideDialog(true);
  };
  const handleEdit = (projectData) => {
    const project = { ...projectData };
    project.customer = project.customer?.id;
    project.partner = project.partner?.id;
    sessionStorage.setItem("projectData", JSON.stringify(project));
    navigate(`/project-form`, {
      state: {
        id: activeItem.item.id,
      },
    });
  };

  useEffect(() => {
    if (projectListQuery.data) {
      setProjectList(projectListQuery.data.getProjects);
    }
  }, [projectListQuery.data]);
  useEffect(() => {
    if (projectListQuery.data) {
      const sortedProjects = projectListQuery.data.getProjects.sort((a, b) =>
        a.name.toLowerCase().localeCompare(b.name.toLowerCase())
      );
      setProjectList(sortedProjects);
    }
  }, [projectListQuery.data]);

  const loadProjectList = () => {
    let data: Array<any> = projectListQuery.data?.getProjects || [];

    if (data) {
      const formattedData = formatProjectData(data);
      setProjectList([...formattedData]);
    }
  };

  const formatProjectData = (projects: any[]): any[] => {
    return projects.map((project: any) => {
      const startDate = project.startDate ? new Date(project.startDate) : null;
      const endDate = project.endDate ? new Date(project.endDate) : null;
      const formattedStartDate = startDate
        ? toISOShortDateString(startDate.getTime())
        : "";
      const formattedEndDate = endDate
        ? toISOShortDateString(endDate.getTime())
        : "";

      return {
        ...project,
        startDate: formattedStartDate,
        endDate: formattedEndDate,
      };
    });
  };

  const columns: IColumn[] = [
    {
      key: "plist_name",
      name: "Project Name",
      fieldName: "name",
      minWidth: 100,
      maxWidth: 180,
      isRowHeader: true,
      isResizable: true,
      isFiltered: true,
      data: "string",
      isPadded: true,
    },
    {
      key: "plist_startDate",
      name: "Start Date",
      fieldName: "startDate",
      minWidth: 100,
      maxWidth: 150,
      isRowHeader: true,
      isResizable: true,
      isFiltered: true,
      data: "string",
      isPadded: true,
    },
    {
      key: "plist_endDate",
      name: "End Date",
      fieldName: "endDate",
      minWidth: 100,
      maxWidth: 150,
      isRowHeader: true,
      isResizable: true,
      isFiltered: true,
      data: "string",
      isPadded: true,
    },
    {
      key: "plist_engagementType",
      name: "Engagement Type",
      fieldName: "engagementType",
      minWidth: 100,
      maxWidth: 150,
      isRowHeader: true,
      isResizable: true,
      isFiltered: true,
      data: "string",
      isPadded: true,
    },
    {
      key: "action",
      name: "Action",
      minWidth: 100,
      maxWidth: 100,
      isRowHeader: true,
      isResizable: true,
      isFiltered: true,
      isPadded: true,
      onRender: (item) => {
        return (
          <ActionButtons
            onEdit={() => handleEdit(item)}
            onView={() => handleShowDetails(item)}
            onDelete={() => handleDeleteProject(item.id)}
            row={item}
            buttonTitles={{
              editTitle: "edit",
              viewTitle: "View",
              deleteTitle: "Delete",
            }}
          />
        );
      },
    },
  ];

  const filterList = (event: any) => {
    const projects = [...(projectListQuery.data?.getProjects || [])];
    let textFilter = event.currentTarget.value.toLowerCase();
    if (!textFilter) {
      setProjectList(formatProjectData(projects));
    } else {
      const data = projects.filter((project: any) => {
        const nameMatches = project.name?.toLowerCase().includes(textFilter);
        const startDateMatches = project.startDate?.includes(textFilter);
        const endDateMatches = project.endDate?.includes(textFilter);
        const engagementTypeMatches = project.engagementType
          ?.toLowerCase()
          .includes(textFilter);

        return (
          nameMatches ||
          startDateMatches ||
          endDateMatches ||
          engagementTypeMatches
        );
      });
      setProjectList(data);
    }
  };
  const exportToCSV = () => {
    const headers = [
      "Project Name",
      "Start Date",
      "End Date",
      "Milestones",
      "Engagement Type",
      "project Engagement type",
      "customer Name",
      "Partner Name",
      "Issued License Link",
      "Renewal Reminder Date",
      "Tech Stack",
      "Key Features",
      "Challenges",
      "Additional Info",
      "Java Tech Stack",
      "Java Key Features",
      "Java Performance Metrics",
      "Java Integration Details",
    ].join(",");
    const rows = projectListQuery.data.getProjects.map((project) =>
      [
        project.name,
        project.startDate,
        project.endDate,
        project.milestones,
        project.engagementType,
        project.EngagementType,
        project.customer?.customerName || "",
        project.partner?.partnerName || "",
        project.issuedLicenseLink,
        project.renewalReminderDate,
        project.mfTechStack,
        project.mfKeyFeatures,
        project.mfChallenges,
        project.mfAdditionalInfo,
        project.javaTechStack,
        project.javaKeyFeatures,
        project.javaPerformanceMetrics,
        project.javaIntegrationDetails,
      ].join(",")
    );
    const csvContent = [headers, ...rows].join("\n");
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8" });
    saveAs(blob, "project_list.csv");
  };
  const prepareCSVData = () => {
    const headers = [
      "Project Name",
      "Start Date",
      "End Date",
      "Milestones",
      "Engagement Type",
      "project Engagement Type",
      "Issued License Link",
      "Renewal Reminder Date",
      "Tech Stack",
      "Key Features",
      "Challenges",
      "Additional Info",
      "Java Tech Stack",
      "Java Key Features",
      "Java Performance Metrics",
      "Java Integration Details",
    ].join(",");
    const rows = projectListQuery.data.getProjects.map((project) => {
      const startDate = project.startDate ? project.startDate : "";
      const endDate = project.endDate ? project.endDate : "";
      return [
        project.name,
        startDate,
        endDate,
        project.milestones,
        project.engagementType,
        project.customer?.customerName || "",
        project.partner?.partnerName || "",
        project.issuedLicenseLink,
        project.renewalReminderDate,
        project.mfTechStack,
        project.mfKeyFeatures,
        project.mfChallenges,
        project.mfAdditionalInfo,
        project.javaTechStack,
        project.javaKeyFeatures,
        project.javaPerformanceMetrics,
        project.javaIntegrationDetails,
      ].join(",");
    });
    const csvContent = [headers, ...rows].join("\n");
    return csvContent;
  };
  const handleSearch = (event) => {
    const textFilter = event.currentTarget.value.toLowerCase();
    const projects = [...projectListQuery.data?.getProjects];
    if (!textFilter) {
      setProjectList(formatProjectData(projects));
    } else {
      const data = projects.filter(
        (project) =>
          project.name.toLowerCase().includes(textFilter) ||
          project.startDate.includes(textFilter) ||
          project.endDate.includes(textFilter) ||
          project.engagementType.toLowerCase().includes(textFilter)
      );
      setProjectList(data);
    }
  };
  const handlePrev = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages1) {
      setCurrentPage(currentPage + 1);
    }
  };
  const modalStyles = {
    root: {
      height: "550px",
      overflow: "auto" as "auto",
    },
  };

  console.log("Fetched project data:", projectListQuery.data?.getProjects);

  return (
    <Authorization>
      <section
        key={UserPermission.P_PROJECT_LIST}
        className={contClasses.sectionContainer}
      >
        <div className={"dashboard-tile " + contClasses.boxContainer}>
          <div className={contClasses.root}>
            <div style={{ marginTop: "3rem" }}>
              <h3>Project List</h3>
              <hr />
              <div className={contClasses.flexStartContainer}>
                <CloudFrameTextBox
                  placeholder="Search"
                  iconProps={searchIcon}
                  onChange={(e) => filterList(e)}
                  className={listClasses.searchBar}
                />
              </div>

              <div style={{ width: "auto", marginTop: "1rem" }}>
                <CloudFrameDataTable
                  items={loadProjectList1()}
                  selectionMode={SelectionMode.none}
                  columns={columns}
                  className={`${classes.tableBody}`}
                  compact
                  onActiveItemChanged={(item, index) =>
                    setActiveItem({ item, index })
                  }
                />
              </div>

              {/* Pagination */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "right",
                  marginTop: "10px",
                }}
              >
                <button onClick={handlePrev} disabled={currentPage === 1}>
                  Prev
                </button>
                {Array.from({ length: totalPages1 }).map((_, index) => (
                  <button
                    key={index}
                    onClick={() => handlePageChange(index + 1)}
                    style={{
                      margin: "0 5px",
                      padding: "5px 10px",
                      border: "1px solid #ccc",
                      backgroundColor:
                        currentPage === index + 1 ? "#0078d4" : "#f2f2f2",
                      color: currentPage === index + 1 ? "white" : "black",
                      cursor: "pointer",
                    }}
                  >
                    {index + 1}
                  </button>
                ))}
                <button
                  onClick={handleNext}
                  disabled={currentPage === totalPages1}
                >
                  Next
                </button>
              </div>
              {/* End of Pagination */}

              <div style={{ width: "auto", marginBottom: "1rem" }}>
                <CloudFramePrimaryButton onClick={exportToCSV}>
                  Export to CSV
                </CloudFramePrimaryButton>
              </div>
            </div>
          </div>
        </div>
        {/* Modal to display project details */}
        <Modal
          isOpen={selectedProject !== null}
          onDismiss={() => setSelectedProject(null)}
          styles={modalStyles}
        >
          <div
            style={{
              padding: "45px",
              borderRadius: "30px",
              backgroundColor: "#ffffff",
            }}
          >
            <h3
              style={{
                textDecoration: "underline",
                marginTop: "5px",
                marginBottom: "30px",
              }}
            >
              Project Details
            </h3>
            <table
              style={{
                borderCollapse: "collapse",
                border: "1px solid #000000",
              }}
            >
              <tbody>
                <tr>
                  <td style={{ border: "1px solid #000000", padding: "8px" }}>
                    <strong>Name</strong>
                  </td>
                  <td style={{ border: "1px solid #000000", padding: "8px" }}>
                    {selectedProject?.name}
                  </td>
                </tr>
                <tr>
                  <td style={{ border: "1px solid #000000", padding: "8px" }}>
                    <strong>Start Date</strong>
                  </td>
                  <td style={{ border: "1px solid #000000", padding: "8px" }}>
                    {selectedProject?.startDate}
                  </td>
                </tr>
                <tr>
                  <td style={{ border: "1px solid #000000", padding: "8px" }}>
                    <strong>End Date</strong>
                  </td>
                  <td style={{ border: "1px solid #000000", padding: "8px" }}>
                    {selectedProject?.endDate}
                  </td>
                </tr>
                <tr>
                  <td style={{ border: "1px solid #000000", padding: "8px" }}>
                    <strong>Milestones</strong>
                  </td>
                  <td style={{ border: "1px solid #000000", padding: "8px" }}>
                    {selectedProject?.milestones}
                  </td>
                </tr>
                <tr>
                  <td style={{ border: "1px solid #000000", padding: "8px" }}>
                    <strong>Engagement Type</strong>
                  </td>
                  <td style={{ border: "1px solid #000000", padding: "8px" }}>
                    {selectedProject?.engagementType}
                  </td>
                </tr>
                <tr>
                  <td style={{ border: "1px solid #000000", padding: "8px" }}>
                    <strong>Project Engagement Type</strong>
                  </td>
                  <td style={{ border: "1px solid #000000", padding: "8px" }}>
                    {selectedProject?.projectEngagementType}
                  </td>
                </tr>
                <tr>
                  <td style={{ border: "1px solid #000000", padding: "8px" }}>
                    <strong>Customer</strong>
                  </td>
                  <td style={{ border: "1px solid #000000", padding: "8px" }}>
                    {selectedProject?.customer?.customerName || "No customer"}
                  </td>
                </tr>
                <tr>
                  <td style={{ border: "1px solid #000000", padding: "8px" }}>
                    <strong>Partner</strong>
                  </td>
                  <td style={{ border: "1px solid #000000", padding: "8px" }}>
                    {selectedProject?.partner?.partnerName || "No partner"}
                  </td>
                </tr>
                <tr>
                  <td style={{ border: "1px solid #000000", padding: "8px" }}>
                    <strong>Product Infos</strong>
                  </td>
                  <td style={{ border: "1px solid #000000", padding: "8px" }}>
                    {selectedProject?.productInfos?.map((info, index) => (
                      <span key={index}>
                        <div>Product Name : {info.productName} </div>{" "}
                        <div>Version : {info.version}</div>
                        <div>Tickets Fixed :{info.ticketsFixed}</div>
                        <div>Release Type :{info.releaseType}</div>
                        <div>Repository : {info.repository}</div>
                        {index !== selectedProject.productInfos.length - 1 && (
                          <hr />
                        )}
                      </span>
                    ))}
                  </td>
                </tr>
                <tr>
                  <td style={{ border: "1px solid #000000", padding: "8px" }}>
                    <strong>Issued License Link</strong>
                  </td>
                  <td style={{ border: "1px solid #000000", padding: "8px" }}>
                    {selectedProject?.issuedLicenseLink}
                  </td>
                </tr>
                <tr>
                  <td style={{ border: "1px solid #000000", padding: "8px" }}>
                    <strong>Renewal Reminder Date</strong>
                  </td>
                  <td style={{ border: "1px solid #000000", padding: "8px" }}>
                    {selectedProject?.renewalReminderDate}
                  </td>
                </tr>
                <tr>
                  <td style={{ border: "1px solid #000000", padding: "8px" }}>
                    <strong>Team Members</strong>
                  </td>
                  <td style={{ border: "1px solid #000000", padding: "8px" }}>
                    {selectedProject?.teamMembers?.map((member, index) => (
                      <span key={index}>
                        <div>
                          {member.name} ({member.email})
                        </div>
                        {index !== selectedProject.teamMembers.length - 1
                          ? " "
                          : ""}
                      </span>
                    ))}
                  </td>
                </tr>

                <tr>
                  <td style={{ border: "1px solid #000000", padding: "8px" }}>
                    <strong>Training</strong>
                  </td>
                  <td style={{ border: "1px solid #000000", padding: "8px" }}>
                    {selectedProject?.trainingDetails?.course || "No training"}
                  </td>
                </tr>

                <tr>
                  <td style={{ border: "1px solid #000000", padding: "8px" }}>
                    <strong>MainFrame Tech Stack</strong>
                  </td>
                  <td style={{ border: "1px solid #000000", padding: "8px" }}>
                    {selectedProject?.mfTechStack}
                  </td>
                </tr>
                <tr>
                  <td style={{ border: "1px solid #000000", padding: "8px" }}>
                    <strong>MainFrame Key Features</strong>
                  </td>
                  <td style={{ border: "1px solid #000000", padding: "8px" }}>
                    {selectedProject?.mfKeyFeatures}
                  </td>
                </tr>
                <tr>
                  <td style={{ border: "1px solid #000000", padding: "8px" }}>
                    <strong>MainFrame Challenges</strong>
                  </td>
                  <td style={{ border: "1px solid #000000", padding: "8px" }}>
                    {selectedProject?.mfChallenges}
                  </td>
                </tr>
                <tr>
                  <td style={{ border: "1px solid #000000", padding: "8px" }}>
                    <strong>MainFrame Additional Info</strong>
                  </td>
                  <td style={{ border: "1px solid #000000", padding: "8px" }}>
                    {selectedProject?.mfAdditionalInfo}
                  </td>
                </tr>

                <tr>
                  <td style={{ border: "1px solid #000000", padding: "8px" }}>
                    <strong>Java Tech Stack</strong>
                  </td>
                  <td style={{ border: "1px solid #000000", padding: "8px" }}>
                    {selectedProject?.javaTechStack}
                  </td>
                </tr>
                <tr>
                  <td style={{ border: "1px solid #000000", padding: "8px" }}>
                    <strong>Java Key Features</strong>
                  </td>
                  <td style={{ border: "1px solid #000000", padding: "8px" }}>
                    {selectedProject?.javaKeyFeatures}
                  </td>
                </tr>
                <tr>
                  <td style={{ border: "1px solid #000000", padding: "8px" }}>
                    <strong>Java Performance Metrics</strong>
                  </td>
                  <td style={{ border: "1px solid #000000", padding: "8px" }}>
                    {selectedProject?.javaPerformanceMetrics}
                  </td>
                </tr>
                <tr>
                  <td style={{ border: "1px solid #000000", padding: "8px" }}>
                    <strong>Java Integration Details</strong>
                  </td>
                  <td style={{ border: "1px solid #000000", padding: "8px" }}>
                    {selectedProject?.javaIntegrationDetails}
                  </td>
                </tr>

                <tr>
                  <td style={{ border: "1px solid #000000", padding: "8px" }}>
                    <strong>Java Integration API</strong>
                  </td>
                  <td style={{ border: "1px solid #000000", padding: "8px" }}>
                    {selectedProject?.javaIntegrationApi}
                  </td>
                </tr>
                <tr>
                  <td style={{ border: "1px solid #000000", padding: "8px" }}>
                    <strong>Deployment Infrastructure</strong>
                  </td>
                  <td style={{ border: "1px solid #000000", padding: "8px" }}>
                    {selectedProject?.depInfra}
                  </td>
                </tr>
                <tr>
                  <td style={{ border: "1px solid #000000", padding: "8px" }}>
                    <strong>Server Infrastructure Details</strong>
                  </td>
                  <td style={{ border: "1px solid #000000", padding: "8px" }}>
                    {selectedProject?.depOs}
                  </td>
                </tr>
                <tr>
                  <td style={{ border: "1px solid #000000", padding: "8px" }}>
                    <strong>Deployment Database</strong>
                  </td>
                  <td style={{ border: "1px solid #000000", padding: "8px" }}>
                    {selectedProject?.depDatabase}
                  </td>
                </tr>
                <tr>
                  <td style={{ border: "1px solid #000000", padding: "8px" }}>
                    <strong>Document Note</strong>
                  </td>
                  <td style={{ border: "1px solid #000000", padding: "8px" }}>
                    {selectedProject?.docNote}
                  </td>
                </tr>
                <tr>
                  <td style={{ border: "1px solid #000000", padding: "8px" }}>
                    <strong>Document Files</strong>
                  </td>
                  <td style={{ border: "1px solid #000000", padding: "8px" }}>
                    {selectedProject?.attachments
                      ?.map((attachments: any) => attachments.fileName)
                      .join(", ") || "N/A"}
                  </td>
                </tr>
                <tr>
                  <td style={{ border: "1px solid #000000", padding: "8px" }}>
                    <strong>Time Trackings</strong>
                  </td>
                  <td style={{ border: "1px solid #000000", padding: "8px" }}>
                    {selectedProject?.timeTrackings?.map((track, index) => (
                      <div key={index}>
                        <div>Resource Name : {track.resourceName}</div>
                        <div>Billable : {track.billable ? "Yes" : "No"}</div>
                        <div> Date : {track.startDate}</div>
                        <div> Hours : {track.hours}</div>
                        {index !== selectedProject.timeTrackings.length - 1 && (
                          <hr />
                        )}
                      </div>
                    ))}
                  </td>
                </tr>
                <tr>
                  <td style={{ border: "1px solid #000000", padding: "8px" }}>
                    <strong>Feedback And Issues</strong>
                  </td>
                  <td style={{ border: "1px solid #000000", padding: "8px" }}>
                    {selectedProject?.feedbackAndIssues?.map(
                      (feedback, index) => (
                        <div key={index}>
                          <div>Feedback : {feedback.feedback}</div>
                          <div> Issues : {feedback.issues}</div>
                          {index !==
                            selectedProject.feedbackAndIssues.length - 1 && (
                            <hr />
                          )}
                        </div>
                      )
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <CloudFramePrimaryButton
            onClick={() => setSelectedProject(null)}
            style={{ marginBottom: "2rem", marginLeft: "2rem", width: "10%" }}
          >
            Close
          </CloudFramePrimaryButton>
        </Modal>
        <Dialog
          hidden={!showDialog}
          onDismiss={cancelDeleteProject}
          dialogContentProps={{
            type: DialogType.normal,
            title: "Confirm Deletion",
            closeButtonAriaLabel: "Close",
          }}
          modalProps={{
            isBlocking: true,
            styles: { main: { maxWidth: 450 } },
          }}
        >
          <div>Are you sure you want to delete this Project Details?</div>
          <DialogFooter>
            <PrimaryButton onClick={confirmDeleteProject} text="Yes" />
            <DefaultButton onClick={cancelDeleteProject} text="No" />
          </DialogFooter>
        </Dialog>
      </section>
    </Authorization>
  );
};

export default ProjectList;
