import { useMutation, useQuery } from "@apollo/client";
import { CloudFramePrimaryButton } from "@cloudframe/button";
import { CloudFrameDataTable } from "@cloudframe/data-table";
import { CloudFrameDialog } from "@cloudframe/dialog";
import { CloudFrameTextBox } from "@cloudframe/textbox";
import {
  DialogType,
  IColumn,
  IIconProps,
  Modal,
  SelectionMode,
} from "@fluentui/react";
import { useId } from "@fluentui/react-hooks";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useContainerStyles } from "../../@styles/container.styles";
import "../../@styles/css/form-element.css";
import { useListStyles } from "../../@styles/list.styles";
import { AzureProfile } from "../../@types/auth.types";
import { UserPermission } from "../../constants/permissions.constants";
import { MUTATION_DELETE_PARTNER } from "../../graphql-mutations/partner.mutation";
import { QUERY_GET_PARTNERS } from "../../graphql-query/partner.query";
import { getActiveUsers } from "../../utils/ms.graph.utils";
import ActionButtons from "../action-buttons/action-buttons";
import Authorization from "../auth/authorization";

const PartnerList = () => {
  const partnerListQuery = useQuery(QUERY_GET_PARTNERS);
  const [partnerList, setPartnerList] = useState<Array<any>>([]);
  const classes = useListStyles();
  const contClasses = useContainerStyles();
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const listClasses = useListStyles();
  const navigate = useNavigate();
  const [activeItem, setActiveItem] = useState<any>({});
  const [showEditView, setEditView] = useState(false);
  const [selectedRole, setSelectedRole] = useState<any>({});
  const [showRoleDetails, setShowRoleDetail] = useState(false);
  const [showPartner, setPartner] = useState<any | null>(null);
  const [formValues, setFormValues] = useState<any>({
    isOfflineLicense: false,
  });
  const labelId = useId("dialogLabel");
  const subTextId = useId("subTextLabel");
  const [hideDialog, setHideDialog] = useState(true);
  const searchIcon: IIconProps = { iconName: "Search" };

  const loadPartnerStatus = async (formattedData: any[]): Promise<void> => {
    const activeProfiles: AzureProfile[] = await getActiveUsers();
    const profileMap = {};
    activeProfiles.forEach((profile) => {
      profileMap[profile.userPrincipalName] = true;
    });
    formattedData.forEach((partner) => {});
  };

  const [deletePartner] = useMutation(MUTATION_DELETE_PARTNER);

  const hideConfirmationDialog = () => {
    setHideDialog(true);
  };

  const onDeletePartner = async () => {
    if (!activeItem.item?.id) {
      return;
    }

    hideConfirmationDialog();
    const result = await deletePartner({
      variables: {
        deletePartnerId: activeItem.item.id,
      },
    });
    const updatedPartnerList = partnerList.filter(
      (partner) => partner !== activeItem
    );
    setPartnerList(updatedPartnerList);
    if (result.data.deletePartner) {
      await partnerListQuery.refetch();
    }
  };

  const handleShowDetails = (partner: any) => {
    setPartner(partner);
  };

  const [partnerData, setPartnerData] = useState({
    id: null,
    partnerName: "",
    primaryContactName: "",
    primaryContactEmail: "",
    primaryContactPhone: "",
    usingCfSandbox: false,
    cfSbDetails: "",
    additionalInfo: "",
    teamMembers: [],
  });

  useEffect(() => {
    loadPartnerList().then(() => {
      console.log("List loaded");
    });
  }, [partnerListQuery.data]);

  const handleEdit = (index) => {
    sessionStorage.setItem("partnerData", JSON.stringify(partnerList[index]));
    navigate(`/create-Partner`);
  };
  const loadPartnerProps = (partner: any[]): any[] => {
    return partner.map((partner) => {
      return {
        ...partner,
      };
    });
  };

  const loadPartnerList = async () => {
    let data: Array<any> = partnerListQuery.data?.getPartnerDetails;
    if (data) {
      const formattedData = loadPartnerProps(data);
      loadPartnerStatus(formattedData).then(() => {
        setPartnerList([...formattedData]);
      });
    }
  };

  const columns: IColumn[] = [
    {
      key: "id",
      name: "SL",
      fieldName: "id",
      minWidth: 20,
      maxWidth: 30,
      isRowHeader: true,
      isResizable: true,
      isSorted: false,
      isSortedDescending: false,
      sortAscendingAriaLabel: "Sorted A to Z",
      sortDescendingAriaLabel: "Sorted Z to A",
      isFiltered: false,
      data: "number",
      isPadded: true,
      onRender: (partner, id) => <p>{id ? id + 1 : 1}</p>,
    },
    {
      key: "partnerName",
      name: "Partner Name",
      fieldName: "partnerName",
      minWidth: 70,
      maxWidth: 130,
      isRowHeader: true,
      isResizable: true,
      // isSorted: false,
      // isSortedDescending: false,
      // sortAscendingAriaLabel: "Sorted A to Z",
      // sortDescendingAriaLabel: "Sorted Z to A",
      isFiltered: true,
      data: "string",
      isPadded: true,
    },
    {
      key: "primaryContactName",
      name: "Primary Partner Name",
      fieldName: "primaryContactName",
      minWidth: 140,
      maxWidth: 180,
      isRowHeader: true,
      isResizable: true,
      // isSorted: false,
      // isSortedDescending: false,
      // sortAscendingAriaLabel: "Sorted A to Z",
      // sortDescendingAriaLabel: "Sorted Z to A",
      isFiltered: true,
      data: "string",
      isPadded: true,
    },
    {
      key: "primaryContactEmail",
      name: "Email ID",
      fieldName: "primaryContactEmail",
      minWidth: 130,
      maxWidth: 150,
      isRowHeader: true,
      isResizable: true,
      // isSorted: false,
      // isSortedDescending: false,
      // sortAscendingAriaLabel: "Sorted A to Z",
      // sortDescendingAriaLabel: "Sorted Z to A",
      isFiltered: true,
      data: "string",
      isPadded: true,
    },
    {
      key: "primaryContactPhone",
      name: "Phone number",
      fieldName: "primaryContactPhone",
      minWidth: 60,
      maxWidth: 150,
      isRowHeader: true,
      isResizable: true,
      // isSorted: false,
      // isSortedDescending: false,
      // sortAscendingAriaLabel: "Sorted A to Z",
      // sortDescendingAriaLabel: "Sorted Z to A",
      isFiltered: true,
      data: "string",
      isPadded: true,
    },
    {
      key: "action",
      name: "Action",
      minWidth: 50,
      maxWidth: 150,
      isRowHeader: true,
      isResizable: true,
      isFiltered: true,
      isPadded: true,
      onRender: (partner, index) => {
        return (
          <ActionButtons
            onEdit={() => handleEdit(index)}
            onView={() => handleShowDetails(partner)}
            onDelete={() => setHideDialog(false)}
            row={partner}
            buttonTitles={{
              editTitle: "edit",
              viewTitle: "View",
              deleteTitle: "Delete",
            }}
          />
        );
      },
    },
  ];
  const totalPages = Math.ceil(partnerList.length / itemsPerPage);
  const getPaginatedData = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return partnerList.slice(startIndex, endIndex);
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const dialogStyles = { main: { maxWidth: 450 } };

  const modalProps = {
    titleAriaId: labelId,
    subtitleAriaId: subTextId,
    isBlocking: false,
    styles: dialogStyles,
    dragOptions: undefined,
  };

  const filterList = (event: any) => {
    const partners = loadPartnerProps(
      partnerListQuery.data?.getPartnerDetails || []
    );
    let textFilter = event.currentTarget.value;
    if (!textFilter) {
      setTimeout(() => {
        setPartnerList(partners);
      });
    } else {
      const data = partners.filter(
        (l: any) =>
          `${l.partnerName}`.toLowerCase().includes(textFilter) ||
          `${l.primaryContactName}`.toLowerCase().includes(textFilter) ||
          `${l.primaryContactEmail}`.toLowerCase().includes(textFilter) ||
          `${l.primaryContactPhone}`.includes(textFilter) ||
          `${l.usingCfSandbox}`.includes(textFilter) ||
          `${l.cfSbDetails}`.toLowerCase().includes(textFilter) ||
          `${l.additionalInfo}`.toLowerCase().includes(textFilter)
      );
      setPartnerList(data);
    }
  };

  return (
    <Authorization>
      <section
        key={UserPermission.P_PARTNER_LIST}
        className={contClasses.sectionContainer}
      >
        <div className={"dashboard-tile " + contClasses.boxContainer}>
          <div className={contClasses.root}>
            <div style={{ marginTop: "3rem", maxWidth: "90%" }}>
              <h3>Partner List</h3>
              <hr />
              <div className={contClasses.flexStartContainer}>
                <CloudFrameTextBox
                  placeholder="Search"
                  iconProps={searchIcon}
                  onChange={(e) => filterList(e)}
                  className={listClasses.searchBar}
                />
              </div>

              {totalPages > 1 && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    padding: "2px",
                    marginRight: "100px",
                  }}
                >
                  <button
                    onClick={() => handlePageChange(currentPage - 1)}
                    style={{
                      margin: "5px",
                      cursor: "pointer",
                      visibility: currentPage === 1 ? "hidden" : "visible",
                    }}
                  >
                    Prev
                  </button>
                  {[currentPage - 1, currentPage, currentPage + 1].map(
                    (page) =>
                      page > 0 &&
                      page <= totalPages && (
                        <button
                          key={page}
                          onClick={() => handlePageChange(page)}
                          style={{
                            margin: "5px",
                            cursor: "pointer",
                            display: "inline-block",
                            width: "20px",
                            height: "20px",
                            border: "1px solid",
                            textAlign: "center",
                            background:
                              page === currentPage ? "#4C8DE4" : "#E8E8E8",
                            color:
                              page === currentPage
                                ? "#fff"
                                : page <= totalPages
                                ? "#000"
                                : "#ccc",
                            //fontWeight: page === currentPage ? " : "normal",
                          }}
                        >
                          {page}
                        </button>
                      )
                  )}
                  <button
                    onClick={() => handlePageChange(currentPage + 1)}
                    style={{
                      margin: "5px",
                      cursor: "pointer",
                      visibility:
                        currentPage === totalPages ? "hidden" : "visible",
                    }}
                  >
                    Next
                  </button>
                </div>
              )}
              <CloudFrameDialog
                hidden={hideDialog}
                onDismiss={hideConfirmationDialog}
                dialogContentProps={{
                  type: DialogType.normal,
                  title: "WARNING!!!",
                  closeButtonAriaLabel: "Close",
                  subText:
                    "Deleting this partner will remove it from all associated Projects.",
                }}
                modalProps={modalProps}
                primaryButton={{ text: "Delete", onClick: onDeletePartner }}
                secondaryButton={{
                  text: "Cancel",
                  onClick: hideConfirmationDialog,
                }}
              />
              <div style={{ width: "auto", marginTop: "1rem" }}>
                <CloudFrameDataTable
                  items={partnerList || []}
                  selectionMode={SelectionMode.none}
                  columns={columns}
                  className={`${classes.tableBody}`}
                  onActiveItemChanged={(item, index) =>
                    setActiveItem({ item, index })
                  }
                  compact
                />
                <Modal
                  isOpen={showPartner !== null}
                  onDismiss={() => setPartner(null)}
                >
                  <div
                    style={{
                      padding: "45px",
                      borderRadius: "30px",
                      backgroundColor: "#ffffff",
                    }}
                  >
                    <h3
                      style={{
                        textDecoration: "underline",
                        marginTop: "5px",
                        marginBottom: "30px",
                      }}
                    >
                      Partner Details
                    </h3>
                    <table
                      style={{
                        borderCollapse: "collapse",
                        border: "1px solid #000000",
                      }}
                    >
                      <tbody>
                        <tr>
                          <td
                            style={{
                              border: "1px solid #000000",
                              padding: "8px",
                            }}
                          >
                            <strong>Name:</strong>
                          </td>
                          <td
                            style={{
                              border: "1px solid #000000",
                              padding: "8px",
                            }}
                          >
                            {showPartner?.partnerName}
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              border: "1px solid #000000",
                              padding: "8px",
                            }}
                          >
                            <strong>Primary Contact Name:</strong>
                          </td>
                          <td
                            style={{
                              border: "1px solid #000000",
                              padding: "8px",
                            }}
                          >
                            {showPartner?.primaryContactName}
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              border: "1px solid #000000",
                              padding: "8px",
                            }}
                          >
                            <strong>Email ID:</strong>
                          </td>
                          <td
                            style={{
                              border: "1px solid #000000",
                              padding: "8px",
                            }}
                          >
                            {showPartner?.primaryContactEmail}
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              border: "1px solid #000000",
                              padding: "8px",
                            }}
                          >
                            <strong>Phone Number:</strong>
                          </td>
                          <td
                            style={{
                              border: "1px solid #000000",
                              padding: "8px",
                            }}
                          >
                            {showPartner?.primaryContactPhone}
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              border: "1px solid #000000",
                              padding: "8px",
                            }}
                          >
                            <strong>Using CloudFrame Sandbox</strong>
                          </td>
                          <td
                            style={{
                              border: "1px solid #000000",
                              padding: "8px",
                            }}
                          >
                            {showPartner?.usingCfSandbox ? "Yes" : "No"}
                          </td>
                        </tr>

                        <tr>
                          <td
                            style={{
                              border: "1px solid #000000",
                              padding: "8px",
                            }}
                          >
                            <strong>CloudFrame Sandbox Details</strong>
                          </td>
                          <td
                            style={{
                              border: "1px solid #000000",
                              padding: "8px",
                            }}
                          >
                            {showPartner?.cfSbDetails}
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              border: "1px solid #000000",
                              padding: "8px",
                            }}
                          >
                            <strong>Additional Info</strong>
                          </td>
                          <td
                            style={{
                              border: "1px solid #000000",
                              padding: "8px",
                            }}
                          >
                            {showPartner?.additionalInfo}
                          </td>
                        </tr>
                        <tr>
                          <td style={{ border: "1px solid", padding: "5px" }}>
                            <b>Team Members</b>
                          </td>
                          <td style={{ border: "1px solid", padding: "5px" }}>
                            {showPartner?.teamMembers
                              ?.map((teamMembers: any) => teamMembers.name)
                              .join(", ") || "N/A"}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <CloudFramePrimaryButton
                    onClick={() => setPartner(null)}
                    style={{
                      marginBottom: "1rem",
                      marginLeft: "3rem",
                      // paddingLeft:"80px",
                      width: "10%",
                    }}
                  >
                    Close
                  </CloudFramePrimaryButton>
                </Modal>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Authorization>
  );
};

export default PartnerList;
