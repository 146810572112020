import { CloudFrameStack } from "@cloudframe/stack";
import Authorization from "../components/auth/authorization";
import { UserPermission } from "../constants/permissions.constants";
import TrainingForm from "../components/create-new-training/training-form";

const CreateNewtraining = () => {
  return (
    <Authorization>
      <section key={UserPermission.P_CREATE_TRAINING}>
        <div style={{ margin: 16 }}>
          <CloudFrameStack>
            <TrainingForm />
          </CloudFrameStack>
        </div>
      </section>
    </Authorization>
  );
};

export default CreateNewtraining;
