export const deepClean = (obj: any, propToRemove: string): void => {
  if (Array.isArray(obj)) {
    obj.forEach((item) => deepClean(item, propToRemove));
  } else if (obj && typeof obj === "object") {
    if (obj.hasOwnProperty(propToRemove)) {
      delete obj[propToRemove];
    }
    Object.keys(obj).forEach((key) => deepClean(obj[key], propToRemove));
  }
};

export const getKeysAsObjects = (value: any = {}): any[] => {
    return Object.keys(value).map((key) => ({
        key: key,
        text: key.replaceAll(/[_-]/g, " "),
      }))
}