import { useQuery } from "@apollo/client";
import { CloudFrameDatepicker } from "@cloudframe/datepicker";
import { CloudFrameDropdown } from "@cloudframe/dropdown";
import { CloudFrameTextBox } from "@cloudframe/textbox";
import {
  IDropdownProps,
  ITextFieldProps,
  defaultDatePickerStrings,
} from "@fluentui/react";
import React, { ReactElement, useEffect, useState } from "react";
import "../../@styles/css/form-element.css";
import {
  ENGAGEMENT_TYPE,
  PROJECT_ENGAGEMENT_TYPE,
} from "../../constants/project.enum";
import {
  GET_CUSTOMER_OPTIONS,
  GET_PARTNER_OPTIONS,
} from "../../graphql-query/member.query";
import { useDebounce } from "../../hooks/cf-hooks";
import { mergeArraysByKey } from "../../utils/array.utils";
import { getKeysAsObjects } from "../../utils/object.util";
import { isBlank } from "../../utils/string.utils";
import CustomLabel from "../custom-label/custom-label";

interface ProjectDetailsFormProps {
  updateFormData: (data: any) => void;
  setErrors: (errors: any) => void;
}

const ProjectDetailsForm: React.FC<ProjectDetailsFormProps> = ({
  updateFormData,
  setErrors,
}) => {
  const [formValues, setFormValues] = useState<any>({});

  const [error, setError] = useState<any>({});
  const { data: partnerData } = useQuery(GET_PARTNER_OPTIONS);
  const { data: customerData } = useQuery(GET_CUSTOMER_OPTIONS);
  const [selectedCustomer, setSelectedCustomer] = useState<number | null>(null);
  const [selectedPartner, setSelectedPartner] = useState<number | null>(null);
  const validateForm = () => {
    const errors: any = {};
    if (isBlank(formValues.name)) {
      errors.name = "Project name is required";
    }
    if (isBlank(formValues.startDate)) {
      errors.startDate = "Start date is required";
    }
    if (isBlank(formValues.endDate)) {
      errors.endDate = "End date is required";
    }
    if (
      formValues.engagementType === ENGAGEMENT_TYPE.Through_Partner &&
      isBlank(formValues.partner)
    ) {
      errors.partner = "Partner is required";
    }
    if (isBlank(formValues.customer)) {
      errors.customer = "Customer is required";
    }
    if (isBlank(formValues.milestones)) {
      errors.milestones = "Milestones is required";
    }

    if (
      formValues.endDate &&
      formValues.startDate &&
      formValues.startDate > formValues.endDate
    ) {
      errors.endDate = "End date must be after project start date";
    }

    if (
      formValues.startDate &&
      formValues.endDate &&
      formValues.endDate < formValues.startDate
    ) {
      errors.startDate = "Start date must be before project end date";
    }

    setError(errors);

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  useEffect(() => {
    const formDataStr = sessionStorage.getItem("projectData");
    const formData = formDataStr ? JSON.parse(formDataStr) : {};

    const initialFormValues = {
      ...formData,
      name: formData.name || "",
      startDate: formData.startDate ? new Date(formData.startDate) : new Date(),
      endDate: formData.endDate ? new Date(formData.endDate) : new Date(),
      milestones: formData.milestones || "",
      partner: formData.partner || null,
      customer: formData.customer || null,
      engagementType:
        formData.engagementType || ENGAGEMENT_TYPE.Direct_Engagement,
      projectEngagementType:
        formData.projectEngagementTyp || PROJECT_ENGAGEMENT_TYPE.POC,
      projectStatus: formData.projectStatus || "Yet to Start",
    };
    setFormValues(initialFormValues);
  }, []);

  useDebounce(
    () => {
      const isValid = validateForm();
      if (isValid) {
        updateFormData(formValues);
      }
    },
    1000,
    formValues
  );

  const renderLabel = (
    props: ITextFieldProps | IDropdownProps | undefined
  ): ReactElement =>
    props ? (
      <CustomLabel id={props.label?.replaceAll(" ", "")} {...props} />
    ) : (
      <div />
    );

  const handleCustomerChange = (_, option) => {
    const customer = customerData?.getCustomerDetails.find(
      (customer: any) => customer.id === Number(option?.key)
    );
    const team = mergeArraysByKey(
      formValues?.teamMembers || [],
      customer?.teamMembers || [],
      "id"
    );
    setFormValues({
      ...formValues,
      customer: Number(option?.key),
      teamMembers: team,
    });
  };

  const handlePartnerChange = (_, option) => {
    const partner = partnerData?.getPartnerDetails.find(
      (partner: any) => partner.id === Number(option?.key)
    );
    const team = mergeArraysByKey(
      formValues?.teamMembers || [],
      partner?.teamMembers || [],
      "id"
    );
    setFormValues({
      ...formValues,
      partner: Number(option?.key),
      teamMembers: team,
    });
  };

  return (
    <div style={{ marginLeft: "35px" }}>
      <h3>Create New Project</h3>
      <div
        style={{ width: "40%", marginBottom: "0.5rem", paddingTop: "0.5rem" }}
      >
        <CloudFrameTextBox
          label="Project Name"
          value={formValues.name}
          onChange={(_, value) => {
            setFormValues({ ...formValues, name: value });
          }}
          errorMessage={error.name}
          required
        />
      </div>
      <CloudFrameDatepicker
        label="Project Start Date"
        value={formValues.startDate}
        onSelectDate={(date) => {
          setFormValues({ ...formValues, startDate: date });
        }}
        placeholder="Select a date..."
        ariaLabel="Select a date"
        strings={defaultDatePickerStrings}
        style={{ width: "40%", marginBottom: "0.5rem" }}
      />
      <CloudFrameDatepicker
        label="Project End Date (if applicable)"
        value={formValues.endDate}
        onSelectDate={(date) => {
          setFormValues({ ...formValues, endDate: date });
        }}
        placeholder="Select a date..."
        ariaLabel="Select a date"
        strings={defaultDatePickerStrings}
        style={{ width: "40%", marginBottom: "0.5rem" }}
      />
      <div style={{ width: "40%", marginBottom: "0.5rem" }}>
        <CloudFrameTextBox
          label="Project Milestones"
          value={formValues.milestones}
          onChange={(_, value) => {
            setFormValues({ ...formValues, milestones: value });
          }}
          errorMessage={error.milestones}
          multiline
          rows={3}
          required
        />
      </div>
      <CloudFrameDropdown
        label="Project Engagement Type"
        ariaLabel="The reason why we are creating this project"
        defaultSelectedKey={[formValues.projectEngagementType]}
        options={getKeysAsObjects(PROJECT_ENGAGEMENT_TYPE)}
        onChange={(_, option) => {
          setFormValues({ ...formValues, projectEngagementType: option?.key });
        }}
        errorMessage={error.projectEngagementType}
        onRenderLabel={renderLabel}
        required
        style={{
          width: "40%",
          marginBottom: "0.5rem",
          fontFamily: "inherit",
        }}
      />
      <CloudFrameDropdown
        label="Project Status"
        defaultSelectedKey={[formValues.projectStatus]}
        options={[
          { key: "Yet to Start", text: "Yet to Start" },
          { key: "Ongoing", text: "Ongoing" },
          { key: "Completed", text: "Completed" },
          { key: "On Hold", text: "On Hold" },
        ]}
        onChange={(_, option) => {
          setFormValues({ ...formValues, projectStatus: option?.key });
        }}
        errorMessage={error.projectStatus}
        required
        style={{
          width: "40%",
          marginBottom: "0.5rem",
          fontFamily: "inherit",
        }}
      />
      <CloudFrameDropdown
        label="Engagement Type"
        defaultSelectedKey={formValues.engagementType}
        options={getKeysAsObjects(ENGAGEMENT_TYPE)}
        onChange={(_, option) => {
          const partnerId =
            option?.key === ENGAGEMENT_TYPE.Through_Partner
              ? formValues.partner
              : null;
          setFormValues({
            ...formValues,
            engagementType: option?.key,
            partner: partnerId,
          });
        }}
        style={{
          width: "40%",
          marginBottom: "0.5rem",
          fontFamily: "inherit",
        }}
      />
      {formValues.engagementType === ENGAGEMENT_TYPE.Through_Partner && (
        <CloudFrameDropdown
          label="Partner Name"
          options={
            partnerData?.getPartnerDetails.map((partner: any) => ({
              key: partner.id,
              text: partner.partnerName,
            })) || []
          }
          defaultSelectedKey={formValues.partner}
          onChange={handlePartnerChange}
          errorMessage={error.partnerName}
          style={{
            width: "40%",
            marginBottom: "0.5rem",
            fontFamily: "inherit",
          }}
          required
        />
      )}
      <CloudFrameDropdown
        label="Customer Name"
        ariaLabel="Please choose the customer associated with the project. If you don't see your customer, please create a new customer from Project Management -> Customer -> Create Customer menu."
        options={
          customerData?.getCustomerDetails.map((customer: any) => ({
            key: customer.id,
            text: customer.customerName,
          })) || []
        }
        defaultSelectedKey={formValues.customer}
        onChange={handleCustomerChange}
        onRenderLabel={renderLabel}
        errorMessage={error.customerName}
        required
        style={{
          width: "40%",
          marginBottom: "0.5rem",
          fontFamily: "inherit",
        }}
      />
    </div>
  );
};

export default ProjectDetailsForm;
