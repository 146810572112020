import { useEffect, useRef } from "react";

export function useDebounce(
  effect: () => void,
  delay: number,
  deps: any
) {
  const firstDebounce = useRef(true);

  useEffect(() => {
    if (deps && firstDebounce.current) {
      effect();
      firstDebounce.current = false;
      return;
    }

    const handler = setTimeout(() => {
      effect();
    }, delay);

    return () => clearTimeout(handler);
  }, [deps]);
}
