import { useMutation } from "@apollo/client";
import {
  CloudFrameDefaultButton,
  CloudFramePrimaryButton,
} from "@cloudframe/button";
import { CloudFrameDropdown } from "@cloudframe/dropdown";
import { CloudFrameTextBox } from "@cloudframe/textbox";
import { FormEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../../@styles/css/form-element.css";
import { IForm } from "../../@types/form.types";
import { isBlank } from "../../utils/string.utils";
import { useStyles } from "./customer-form.styles";
import {
  MUTATION_CREATE_CUSTOMER,
  MUTATION_EDIT_CUSTOMER,
} from "../../graphql-mutations/customer.mutation";
import { CloudFrameDialog } from "@cloudframe/dialog";
import { DialogType, IModalProps } from "@fluentui/react";
import { getKeysAsObjects } from "../../utils/object.util";
import { ORGANIZATION_TYPE } from "../../constants/project.enum";

export interface TeamMember {
  id?: number;
  name: string;
  role: string;
  organization: string;
  phone: string;
  email: string;
}

const CustomerForm = (props: IForm) => {
  const classes = useStyles();
  const { data } = props;
  const [formValues, setFormValues] = useState<any>({});
  const [errors, setErrors] = useState<any>({});
  const [showErrorDialog, setShowErrorDialog] = useState(false);
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [createCustomer] = useMutation(MUTATION_CREATE_CUSTOMER);
  const [updateCustomerByID] = useMutation(MUTATION_EDIT_CUSTOMER);
  const [formSubmitAttempted, setFormSubmitAttempted] = useState(false);
  const emailPattern = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
  const phonePattern = /^\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}$/;
  const [members, setMembers] = useState<TeamMember[]>([]);
  const [isFormValid, setIsFormValid] = useState(false);
  const navigate = useNavigate();

  const onAddTeamMember = () => {
    setMembers((members) => [
      ...members,
      {
        id: undefined,
        name: "",
        role: "",
        organization: "",
        phone: "",
        email: "",
      },
    ]);
  };

  const onRemoveTeamMember = (index: number) => {
    const newMembers = [...members];
    newMembers.splice(index, 1);
    setMembers(newMembers);
  };

  const handleMemberChange = (
    index: number,
    field: keyof TeamMember,
    value: any
  ) => {
    const newMembers = members.map((member, i) => {
      if (i === index) {
        return { ...member, [field]: value };
      }
      return member;
    });
    setMembers(newMembers);

    if (field === "phone" && !phonePattern.test(value)) {
      setErrors((prevErrors: any) => ({
        ...prevErrors,
        [`phone_${index}`]: "Please enter a valid Phone number",
      }));
    } else if (field === "phone") {
      setErrors((prevErrors: any) => {
        const { [`phone_${index}`]: removedError, ...rest } = prevErrors;
        return rest;
      });
    }
    if (field === "email" && !emailPattern.test(value)) {
      setErrors((prevErrors: any) => ({
        ...prevErrors,
        [`email_${index}`]: "Please enter a valid Email address",
      }));
    } else if (field === "email") {
      setErrors((prevErrors: any) => {
        const { [`email_${index}`]: removedError, ...rest } = prevErrors;
        return rest;
      });
    }
  };

  const [showSuccessDialog, setShowSuccessDialog] = useState(false);

  useEffect(() => {
    const savedDataString = sessionStorage.getItem("customerData");
    if (savedDataString) {
      const savedData = JSON.parse(savedDataString);
      delete savedData.__typename;
      for (let i = 0; i < savedData.teamMembers.length; i++) {
        delete savedData.teamMembers[i].__typename;
      }
      setFormValues(savedData);
      setMembers(savedData.teamMembers);
      setFormSubmitAttempted(true);
    } else {
      setMembers([
        {
          id: undefined,
          name: "",
          role: "",
          organization: ORGANIZATION_TYPE.Customer,
          phone: "",
          email: "",
        },
      ]);
      setFormValues({
        customerName: "",
        primaryContactName: "",
        primaryContactEmail: "",
        primaryContactPhone: "",
        industry: "",
        primaryLocation: "",
        timezone: "",
        keyNotes: "",
        additionalInfo: "",
      });
    }
  }, [data]);

  const validateForm = () => {
    const newErrors: any = {};
    if (isBlank(formValues.customerName)) {
      newErrors.customerName = "Please enter the Customer Name";
    }
    if (isBlank(formValues.primaryContactName)) {
      newErrors.primaryContactName = "Please enter the Primary Contact Name";
    }
    if (isBlank(formValues.primaryContactEmail)) {
      newErrors.primaryContactEmail = "Please enter the Email ID";
    } else if (!emailPattern.test(formValues.primaryContactEmail)) {
      newErrors.primaryContactEmail = "Please enter a valid Email ID";
    }
    if (isBlank(formValues.primaryContactPhone)) {
      newErrors.primaryContactPhone = "Please enter the Contact Number";
    } else if (!phonePattern.test(formValues.primaryContactPhone)) {
      newErrors.primaryContactPhone = "Please enter a valid Contact Number";
    }
    if (isBlank(formValues.industry)) {
      newErrors.industry = "Please enter the Industry";
    }
    if (isBlank(formValues.primaryLocation)) {
      newErrors.primaryLocation = "Please enter the Primary Location";
    }
    if (isBlank(formValues.timezone)) {
      newErrors.timezone = "Please select an option";
    }

    members.forEach((member, index) => {
      if (isBlank(member.name)) {
        newErrors[`name_${index}`] = "Please enter the Name";
      }
      if (isBlank(member.role)) {
        newErrors[`role_${index}`] = "Please enter the Role";
      }
      if (isBlank(member.organization)) {
        newErrors[`organization_${index}`] = "Please enter the Organization";
      }
      if (isBlank(member.phone)) {
        newErrors[`phone_${index}`] = "Please enter the Phone";
      } else if (!phonePattern.test(member.phone)) {
        newErrors[`phone_${index}`] = "Please enter a valid Phone number";
      }
      if (isBlank(member.email)) {
        newErrors[`email_${index}`] = "Please enter the Email";
      } else if (!emailPattern.test(member.email)) {
        newErrors[`email_${index}`] = "Please enter a valid Email address";
      }
    });
    // setErrors({ ...newErrors });
    // return !Object.keys(newErrors).length;
    setErrors(newErrors);

    const isValid = Object.keys(newErrors).length === 0;
    setIsFormValid(isValid);
    return isValid;
  };

  const updateCustomerDetails = async () => {
    const {
      id,
      customerName,
      primaryContactName,
      primaryContactEmail,
      primaryContactPhone,
      industry,
      primaryLocation,
      timezone,
      keyNotes,
      additionalInfo,
      teamMembers = [],
    } = formValues;

    const result = await updateCustomerByID({
      variables: {
        updateCustomerByID: formValues.id,
        data: {
          customerName,
          primaryContactName,
          primaryContactEmail,
          primaryContactPhone,
          industry,
          primaryLocation,
          timezone,
          keyNotes,
          additionalInfo,
          teamMembers: members,
        },
      },
    });
    if (result.data.updateCustomerByID) {
      sessionStorage.removeItem("customerData");
      setShowSuccessDialog(true);
    } else {
      console.error("Error updating customer:", result.errors);
      setErrors("Error Occured while updating customer details");
      setShowErrorDialog(true);
    }
  };

  const resetForm = () => {
    setFormValues({
      ...formValues,
      customerName: "",
      primaryContactName: "",
      primaryContactEmail: "",
      primaryContactPhone: "",
      industry: "",
      primaryLocation: "",
      timezone: "",
      keyNotes: "",
      additionalInfo: "",
    });
    setMembers([
      {
        id: undefined,
        name: "",
        role: "",
        organization: "",
        phone: "",
        email: "",
      },
    ]);
    setErrors({});
  };

  const onFormSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setFormSubmitting(true);
    setFormSubmitAttempted(true);
    const isFormValid = validateForm();
    try {
      if (isFormValid) {
        if (formValues.id) {
          await updateCustomerDetails();
        } else {
          const formData = {
            ...formValues,
            teamMembers: members,
          };
          const result = await createCustomer({
            variables: { data: formData },
          });
          if (result?.data?.createCustomer) {
            sessionStorage.removeItem("customerData");
            setShowSuccessDialog(true);
          } else {
            setErrors("Error Occurred while submitting customerdetails.");
            setShowErrorDialog(true);
          }
        }
      }
    } catch (error) {
      console.log(error);
      setErrors("Error Occured while submitting customer details");
      setShowErrorDialog(true);
    }
    setFormSubmitting(false);
  };

  const hideSuccessDialog = () => {
    setShowSuccessDialog(false);
    navigate("/customer-list");
  };

  const modalProps: IModalProps = {
    isBlocking: false,
  };

  return (
    <div className={classes.root}>
      <div
        id="form"
        className={classes.formBody}
        style={{ borderRadius: "25px" }}
      >
        <div className={classes.subContainer}>
          <h2>{formValues.id ? "Update Customer" : "Create new Customer"}</h2>
          <form onSubmit={onFormSubmit}>
            <CloudFrameTextBox
              onChange={(_, value) => {
                setFormValues({ ...formValues, customerName: value });
              }}
              required
              label="Customer Name"
              className={classes.mb8}
              value={formValues.customerName}
              disabled={!!data}
              errorMessage={errors.customerName}
            />
            <CloudFrameTextBox
              onChange={(_, value) => {
                setFormValues({ ...formValues, primaryContactName: value });
              }}
              required
              label="Primary Contact Name"
              className={classes.mb8}
              value={formValues.primaryContactName}
              disabled={!!data}
              errorMessage={errors.primaryContactName}
            />
            <CloudFrameTextBox
              label="Primary Contact Email"
              className={classes.mb8}
              value={formValues.primaryContactEmail}
              required
              disabled={!!data}
              errorMessage={errors.primaryContactEmail}
              onChange={(_, value) => {
                if (value !== undefined) {
                  setFormValues({ ...formValues, primaryContactEmail: value });
                  if (!emailPattern.test(value)) {
                    setErrors({
                      ...errors,
                      primaryContactEmail: "Please enter a valid email address",
                    });
                  } else {
                    setErrors({
                      ...errors,
                      primaryContactEmail: undefined,
                    });
                  }
                }
              }}
            />
            <CloudFrameTextBox
              label="Primary Contact Phone"
              className={classes.mb8}
              value={formValues.primaryContactPhone}
              required
              disabled={!!data}
              errorMessage={errors.primaryContactPhone}
              onChange={(_, value) => {
                if (value !== undefined) {
                  setFormValues({ ...formValues, primaryContactPhone: value });
                  if (!phonePattern.test(value)) {
                    setErrors({
                      ...errors,
                      primaryContactPhone:
                        "Please enter a valid contact number",
                    });
                  } else {
                    setErrors({
                      ...errors,
                      primaryContactPhone: undefined,
                    });
                  }
                }
              }}
            />
            <CloudFrameTextBox
              onChange={(_, value) => {
                setFormValues({ ...formValues, industry: value });
              }}
              required
              label="Industry"
              className={classes.mb8}
              value={formValues.industry}
              disabled={!!data}
              errorMessage={errors.industry}
            />
            <CloudFrameTextBox
              onChange={(_, value) => {
                setFormValues({ ...formValues, primaryLocation: value });
              }}
              required
              label="Primary Location"
              className={classes.mb8}
              value={formValues.primaryLocation}
              disabled={!!data}
              errorMessage={errors.primaryLocation}
            />
            <CloudFrameDropdown
              onChange={(_, option) => {
                setFormValues({ ...formValues, timezone: option?.key });
              }}
              required
              label="Time Zone"
              options={[
                {
                  key: "Pacific Standard Time (PST) - UTC-8",
                  text: "Pacific Standard Time (PST) - UTC-8",
                },
                {
                  key: "Indian Standard Time (IST) - UTC+5:30 ",
                  text: "Indian Standard Time (IST) - UTC+5:30 ",
                },
                {
                  key: "Greenwich Mean Time (GMT) - UTC+0",
                  text: "Greenwich Mean Time (GMT) - UTC+0 ",
                },
                {
                  key: "Eastern Standard Time (EST) - UTC-5",
                  text: "Eastern Standard Time (EST) - UTC-5",
                },
                {
                  key: "Central Standard Time (CST) - UTC-6",
                  text: "Central Standard Time (CST) - UTC-6",
                },
                {
                  key: "Mountain Standard Time (MST) - UTC-7",
                  text: "Mountain Standard Time (MST) - UTC-7",
                },
                {
                  key: "Alaska Standard Time (AKST) - UTC-9",
                  text: "Alaska Standard Time (AKST) - UTC-9",
                },
                {
                  key: "Hawaii-Aleutian Standard Time (HST) - UTC-10",
                  text: "Hawaii-Aleutian Standard Time (HST) - UTC-10",
                },
              ]}
              selectedKey={formValues.timezone || undefined}
              errorMessage={errors.timezone}
            />
            <h3>
              <b>Team Member details</b>
            </h3>
            <div>
              {members.map((member, index) => (
                <div key={index}>
                  <b>Member {index + 1}</b>
                  <div style={{ marginBottom: "30px" }}>
                    <div style={{ display: "flex", marginBottom: "1rem" }}>
                      <span style={{ minWidth: "225px", marginRight: "1rem" }}>
                        <CloudFrameTextBox
                          onChange={(_, value) =>
                            handleMemberChange(index, "name", value)
                          }
                          required
                          label="Name"
                          value={member.name}
                          errorMessage={errors[`name_${index}`]}
                        />
                      </span>
                      <span style={{ minWidth: "225px", marginRight: "2px" }}>
                        <CloudFrameTextBox
                          onChange={(_, value) =>
                            handleMemberChange(index, "role", value)
                          }
                          required
                          label="Role"
                          value={member.role}
                          errorMessage={errors[`name_${index}`]}
                        />
                      </span>
                    </div>
                    <div style={{ display: "flex", marginBottom: "1rem" }}>
                      <span style={{ minWidth: "225px", marginRight: "1rem" }}>
                        <CloudFrameDropdown
                          label="organization"
                          selectedKey={member.organization}
                          onChange={(e, option) =>
                            handleMemberChange(
                              index,
                              "organization",
                              option?.key
                            )
                          }
                          options={getKeysAsObjects(ORGANIZATION_TYPE)}
                          errorMessage={errors[`organization_${index}`]}
                          required
                        />
                      </span>
                      <span style={{ minWidth: "225px", marginRight: "2px" }}>
                        <CloudFrameTextBox
                          onChange={(_, value) =>
                            handleMemberChange(index, "phone", value)
                          }
                          required
                          label="Phone"
                          value={member.phone}
                          errorMessage={errors[`phone_${index}`]}
                        />
                      </span>
                    </div>
                    <div style={{ display: "flex", marginBottom: "1rem" }}>
                      <span style={{ minWidth: "225px", marginRight: "1rem" }}>
                        <CloudFrameTextBox
                          onChange={(_, value) =>
                            handleMemberChange(index, "email", value)
                          }
                          required
                          label="Email"
                          value={member.email}
                          errorMessage={errors[`email_${index}`]}
                        />
                      </span>
                    </div>
                    <CloudFramePrimaryButton
                      style={{ backgroundColor: "red" }}
                      text={"Remove"}
                      onClick={() => onRemoveTeamMember(index)}
                      disabled={formSubmitting}
                      className={classes.mt16}
                    />
                  </div>
                </div>
              ))}
            </div>
            <div>
              <CloudFramePrimaryButton
                style={{
                  padding: "1em",
                  marginBottom: "20px",
                  borderRadius: "50px",
                  marginLeft: "370px",
                }}
                title="Add"
                text={"Add"}
                iconProps={{ iconName: "AddTo" }}
                onClick={onAddTeamMember}
              />
            </div>
            <CloudFrameTextBox
              onChange={(_, value) => {
                setFormValues({ ...formValues, keyNotes: value });
              }}
              label="Key Notes"
              className={classes.mb8}
              value={formValues.keyNotes}
              disabled={!!data}
              errorMessage={errors.keyNotes}
            />
            <CloudFrameTextBox
              onChange={(_, value) => {
                setFormValues({ ...formValues, additionalInfo: value });
              }}
              label="Additional Information"
              className={classes.mb8}
              value={formValues.additionalInfo}
              disabled={!!data}
              errorMessage={errors.additionalInfo}
            />
            <div className={classes.btnContainer}>
              <CloudFramePrimaryButton
                text={formValues.id ? "Update" : "Submit"}
                type="submit"
                className={`${classes.mt16} ${classes.ml2}`}
                disabled={formSubmitting}
              />
              <CloudFrameDefaultButton
                text={"Reset"}
                type="button"
                disabled={formSubmitting}
                className={`${classes.mt16} ${classes.ml2}`}
                onClick={resetForm}
              />
              <CloudFrameDialog
                hidden={!showSuccessDialog}
                onDismiss={hideSuccessDialog}
                dialogContentProps={{
                  type: DialogType.normal,
                  title: "Success!",
                  closeButtonAriaLabel: "Close",
                  subText: formValues.id
                    ? "Customer Information  updated successfully ."
                    : "Customer Information saved successfully .",
                }}
                modalProps={modalProps}
                primaryButton={{ text: "Close", onClick: hideSuccessDialog }}
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CustomerForm;
