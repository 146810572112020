import { gql } from "@apollo/client";

export const QUERY_GET_PARTNERS = gql`
  query GetPartnerDetails {
    getPartnerDetails {
      id
      partnerName
      primaryContactName
      primaryContactEmail
      primaryContactPhone
      usingCfSandbox
      cfSbDetails
      additionalInfo
      teamMembers {
        id
        name
        role
        organization
        phone
        email
      }
    }
  }
`;
