import { gql } from "@apollo/client";

export const MUTATION_PERSIST_EMAIL_CONFIG = gql`
  mutation PersistEmailConfig(
    $id: Float
    $configurationType: String!
    $thresholdPeriod: Float
    $emailReceivers: String!
    $emailBody: String
    $emailBodyVariables: String
  ) {
    persistEmailServiceConfig(
      id: $id
      configurationType: $configurationType
      thresholdPeriod: $thresholdPeriod
      emailReceivers: $emailReceivers
      emailBody: $emailBody
      emailBodyVariables: $emailBodyVariables
    ) {
      id
      configurationType
      thresholdPeriod
      emailReceivers
      emailBody
      emailBodyVariables
    }
  }
`;

// Mutation to delete an email service configuration by its ID
export const MUTATION_DELETE_EMAIL_CONFIG = gql`
  mutation DeleteEmailConfig($id: Float!) {
    deleteEmailServiceConfig(id: $id)
  }
`;