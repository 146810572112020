import { CloudFramePrimaryButton } from "@cloudframe/button";
import { message, Steps, theme } from "antd";
import React from "react";
import { useFormStyles } from "../../@styles/form.style";

interface Step {
  title: string;
  content: React.ReactNode;
  validate?: () => boolean;
}

interface Props {
  steps: Step[];
  currentStep: number;
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  handleSubmit: (e: React.MouseEvent<HTMLButtonElement>) => void;
  validateAllForms: () => boolean;
}

const StepperComponent: React.FC<Props> = ({
  steps,
  currentStep,
  setCurrentStep,
  handleSubmit,
  validateAllForms,
}) => {
  const { token } = theme.useToken();
  const formclasses = useFormStyles();

  const next = () => {
    const isValid = validateAllForms();
    if (isValid) {
      setCurrentStep((prevCurrent) => prevCurrent + 1);
    } else {
      message.error("Please fill in all required fields.");
    }
  };
  const prev = () => {
    setCurrentStep((prevCurrent) => prevCurrent - 1);
  };

  if (!steps || steps.length === 0) {
    return <div>No steps provided</div>;
  }

  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }));

  const containerStyle: React.CSSProperties = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
  };

  const contentContainerStyle: React.CSSProperties = {
    flex: 1,
    width: "100%",
    overflowY: "auto",
    marginBottom: "30px",
    marginTop: "20px",
  };

  const buttonContainerStyle: React.CSSProperties = {
    marginBottom: "5px",
    marginTop: "20px",
    textAlign: "left",
  };

  return (
    <div style={{ margin: 16 }}>
      <div className={formclasses.formBody} style={{ borderRadius: "25px" }}>
        <div style={containerStyle}>
          <Steps current={currentStep} items={items} />
          <div style={contentContainerStyle}>{steps[currentStep].content}</div>
          <div style={buttonContainerStyle}>
            {currentStep > 0 && (
              <CloudFramePrimaryButton
                style={{
                  margin: "8px",
                }}
                onClick={prev}
              >
                Previous
              </CloudFramePrimaryButton>
            )}
            {currentStep < steps.length - 1 && (
              <CloudFramePrimaryButton type="primary" onClick={next}>
                Next
              </CloudFramePrimaryButton>
            )}
            {currentStep === steps.length - 1 && (
              <CloudFramePrimaryButton type="primary" onClick={handleSubmit}>
                Submit
              </CloudFramePrimaryButton>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default StepperComponent;
