export const createObjectFromKeysArray = (keysArray: string[], defaultValue: any = true): Object => {
  const obj = {};
  for (const key of keysArray) {
    obj[key] = defaultValue;
  }
  return obj;
};

export const mergeArraysByKey = (obj1: any[], obj2: any[], key: string): any => {
  const obj = getObjectFromArray(obj1, key);
  for (const item of obj2) {
    obj[item[key]] = item;
  }
  return Object.values(obj);
};

export const getObjectFromArray = (array: any[], key: string): any => {
  const obj = {};
  for (const item of array) {
    obj[item[key]] = item;
  }
  return obj;
}