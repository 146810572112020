import { CloudFramePrimaryButton } from "@cloudframe/button";
import { CloudFrameDatepicker } from "@cloudframe/datepicker";
import { CloudFrameDropdown } from "@cloudframe/dropdown";
import { CloudFrameTextBox } from "@cloudframe/textbox";
import { defaultDatePickerStrings } from "@fluentui/react";
import React, { useEffect, useState } from "react";
import { useContainerStyles } from "../../@styles/container.styles";
import { RELEASE_TYPE } from "../../constants/project.enum";
import { useDebounce } from "../../hooks/cf-hooks";
import { getKeysAsObjects } from "../../utils/object.util";
import { isBlank } from "../../utils/string.utils";
import { validateItemsForRequiredFields } from "../../utils/validation.util";
import CustomLabel from "../custom-label/custom-label";

interface LFormProps {
  updateFormData: (data: any) => void;
  setErrors: (errors: any) => void;
}
const defaultDate = new Date();

const productErrorIdentifier = "productInfos";
const LincensemainframeForm: React.FC<LFormProps> = ({
  updateFormData,
  setErrors,
}) => {
  const [formValues, setFormValues] = useState<any>({
    renewalReminderDate: defaultDate,
    //productInfos: [defaultProdInfo],
  });
  const [error, setError] = useState<any>({});
  const defaultProdInfo = {
    productName: "Renovate",
    version: "",
    releaseType: RELEASE_TYPE.Major,
    ticketsFixed: "",
    repository: "",
  };

  useEffect(() => {
    const formDataStr = sessionStorage.getItem("projectData");
    const formData = formDataStr ? JSON.parse(formDataStr) : {};
    const productInfos =
      !formData.productInfos || formData.productInfos.length === 0
        ? [defaultProdInfo]
        : formData.productInfos;
    setFormValues({
      ...formData,
      ...formValues,
      supportType: formData.supportType || "Premium",
      productInfos,
    });
  }, []);

  // useEffect(() => {
  //   const formDataStr = sessionStorage.getItem("projectData");
  //   const formData = formDataStr ? JSON.parse(formDataStr) : {};
  //   formData.renewalReminderDate = formData.renewalReminderDate
  //     ? new Date(formData.renewalReminderDate)
  //     : new Date();
  //   formData.supportType = formData.supportType ?? SUPPORT_TYPE.Premium;
  //   if (!formData.productInfos || !formData.productInfos.length) {
  //     formData.productInfos = [defaultProdInfo];
  //   }
  //   setFormValues(formData);
  // }, []);

  const contClasses = useContainerStyles();
  const validateForm = () => {
    const errors: any = {};
    if (isBlank(formValues.issuedLicenseLink)) {
      errors.issuedLicenseLink = "Please provide issued license link";
    }
    if (isBlank(formValues.renewalReminderDate)) {
      errors.renewalReminderDate = "Please provide renewal reminder date";
    }
    if (isBlank(formValues.supportType)) {
      errors.supportType = "Please provide support type";
    }
    if (isBlank(formValues.mfTechStack)) {
      errors.mfTechStack = "Please provide mainframe tech stack";
    }
    validateItemsForRequiredFields(
      formValues.productInfos,
      ["productName", "version"],
      errors,
      productErrorIdentifier
    );

    setError(errors);

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  useDebounce(
    () => {
      const isValid = validateForm();
      if (isValid) {
        updateFormData(formValues);
      }
    },
    500,
    formValues
  );

  const handleAddCFProduct = () => {
    setFormValues((prev) => ({
      ...prev,
      productInfos: [...prev.productInfos, defaultProdInfo],
    }));
  };

  const handleRemoveCFProduct = (index: number) => {
    setFormValues((prev: any) => {
      const updated = [...prev.productInfos];
      updated.splice(index, 1);
      return { ...prev, productInfos: updated };
    });
  };

  const handleCFProductChange = (index: number, field: string, value: any) => {
    setFormValues((prev: any) => {
      const updated = [...prev.productInfos];
      updated[index] = { ...updated[index], [field]: value };
      return { ...prev, productInfos: updated };
    });
  };

  return (
    <div style={{ width: "50%", marginLeft: "35px" }}>
      <h3>License Details</h3>
      <CloudFrameTextBox
        label="Issued License Link"
        value={formValues.issuedLicenseLink}
        onChange={(_, value) => {
          setFormValues({ ...formValues, issuedLicenseLink: value });
        }}
        errorMessage={error.issuedLicenseLink}
        required
      />
      <CloudFrameDatepicker
        label="Renewal Reminder Date"
        value={formValues.renewalReminderDate}
        onSelectDate={(date) => {
          setFormValues({ ...formValues, renewalReminderDate: date });
        }}
        strings={defaultDatePickerStrings}
        isRequired={!formValues.renewalReminderDate}
      />
      <CloudFrameDropdown
        label="Support Type"
        defaultSelectedKey={formValues.supportType}
        options={[
          { key: "Premium", text: "Premium" },
          { key: "Regular", text: "Regular" },
        ]}
        onChange={(_, option) => {
          setFormValues({ ...formValues, supportType: option?.key });
        }}
        errorMessage={error.supportType}
        required
      />
      <h3>Mainframe Application Details</h3>
      <CloudFrameTextBox
        label="Technology Stack of existing application"
        value={formValues.mfTechStack}
        onChange={(_, value) => {
          setFormValues({ ...formValues, mfTechStack: value });
        }}
        errorMessage={error.mfTechStack}
        required
      />
      <CloudFrameTextBox
        label="Key Functionalities"
        value={formValues.mfKeyFeatures}
        onChange={(_, value) => {
          setFormValues({ ...formValues, mfKeyFeatures: value });
        }}
        multiline
        rows={3}
      />
      <CloudFrameTextBox
        label="Challenges or Pain Points"
        value={formValues.mfChallenges}
        onChange={(_, value) => {
          setFormValues({ ...formValues, mfChallenges: value });
        }}
        multiline
        rows={3}
        style={{
          resize: "vertical",
        }}
      />
      <CloudFrameTextBox
        label="Additional Information"
        value={formValues.mfAdditionalInfo}
        onChange={(_, value) => {
          setFormValues({ ...formValues, mfAdditionalInfo: value });
        }}
      />
      <div className={contClasses.flexStartContainer}>
        <h3>CF Product Information</h3>
        <CustomLabel
          id="addCFProduct"
          ariaLabel="You may add CloudFrame product information in the following form. You may add multiple products by clicking on the 'Add CF Product' button"
        />
      </div>
      {formValues.productInfos?.map((product: any, index: number) => (
        <div key={`product-${index}`}>
          <h5>CF Product Information {index + 1}</h5>
          <CloudFrameDropdown
            label="CloudFrame Product"
            defaultSelectedKey={product.productName}
            options={[
              { key: "Renovate", text: "Renovate" },
              { key: "Regular", text: "Regular" },
            ]}
            onChange={(_, option) => {
              handleCFProductChange(index, "productName", option?.key);
            }}
            errorMessage={
              error[`${productErrorIdentifier}_${index}_productName`]
            }
            required={!!error[`${productErrorIdentifier}_${index}_productName`]}
          />
          <CloudFrameTextBox
            label="Version"
            value={product.version}
            onChange={(_, value) => {
              handleCFProductChange(index, "version", value);
            }}
            errorMessage={error[`${productErrorIdentifier}_${index}_version`]}
            required={!!error[`${productErrorIdentifier}_${index}_version`]}
          />
          <CloudFrameDropdown
            label="Release Type"
            defaultSelectedKey={product.releaseType}
            options={getKeysAsObjects(RELEASE_TYPE)}
            onChange={(_, option) =>
              handleCFProductChange(index, "releaseType", option?.key)
            }
          />
          <CloudFrameTextBox
            label="Tickets Fixed"
            value={product.ticketsFixed}
            onChange={(_, value) =>
              handleCFProductChange(index, "ticketsFixed", value)
            }
          />
          <CloudFrameTextBox
            label="Repository Info"
            value={product.repository}
            onChange={(_, value) =>
              handleCFProductChange(index, "repository", value)
            }
          />
          {formValues.productInfos?.length > 1 && (
            <div
              className={contClasses.flexEndContainer}
              style={{ marginTop: 10, marginBottom: 0 }}
            >
              <CloudFramePrimaryButton
                text="Remove"
                onClick={() => handleRemoveCFProduct(index)}
                style={{
                  backgroundColor: "red",
                }}
              />
            </div>
          )}
        </div>
      ))}
      <CloudFramePrimaryButton
        text="Add CF Product"
        onClick={handleAddCFProduct}
        style={{ marginTop: "10px", fontSize: "12px", padding: "4px 8px" }}
      />
      {error.productInfos && (
        <div style={{ color: "red" }}>{error.productInfos}</div>
      )}
    </div>
  );
};

export default LincensemainframeForm;
