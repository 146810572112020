import { useMutation, useQuery } from "@apollo/client";
import { CloudFramePrimaryButton } from "@cloudframe/button";
import { CloudFrameDataTable } from "@cloudframe/data-table";
import { CloudFrameTextBox } from "@cloudframe/textbox";
import {
  DialogType,
  IColumn,
  IIconProps,
  Modal,
  SelectionMode,
} from "@fluentui/react";
import { useEffect, useState } from "react";
import { useContainerStyles } from "../../@styles/container.styles";
import "../../@styles/css/form-element.css";
import { useListStyles } from "../../@styles/list.styles";
import { UserPermission } from "../../constants/permissions.constants";
import Authorization from "../auth/authorization";
import { QUERY_GET_MEMBERS } from "../../graphql-query/member.query";
import { MUTATION_DELETE_MEMBER } from "../../graphql-mutations/member.mutation";
import { useNavigate, useSearchParams } from "react-router-dom";
import ActionButtons from "../action-buttons/action-buttons";
import { saveAs } from "file-saver";
import { IActionButtonTitle } from "../action-buttons/action-button.intefaces";
import { CloudFrameDialog } from "@cloudframe/dialog";
import { useId } from "@fluentui/react-components";
import { IDialogProps as IMessageDialogProps } from "../../@types/form.types";

const MemberList = () => {
  const memberListQuery = useQuery(QUERY_GET_MEMBERS);
  const [memberList, setMemberList] = useState<Array<any>>([]);
  const [activeItem, setActiveItem] = useState<any>({});
  const [selectedMember, setSelectedMember] = useState<any | null>(null);
  const [showSelectedMember, setShowSelectedMember] = useState(false);
  const [messageHidden, setMessageHidden] = useState(true);
  const [messgeInfo, setMessageInfo] = useState<IMessageDialogProps>();
  const labelId = useId("dialogLabel");
  const subTextId = useId("subTextLabel");
  const [hideDialog, setHideDialog] = useState(true);
  const [deleteTeamMember] = useMutation(MUTATION_DELETE_MEMBER);
  const [deleteConfirmationVisible, setDeleteConfirmationVisible] =
    useState(false);
  const [deleteSuccessMessageVisible, setDeleteSuccessMessageVisible] =
    useState(false);

  const classes = useListStyles();
  const contClasses = useContainerStyles();
  const listClasses = useListStyles();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const searchIcon: IIconProps = { iconName: "Search" };

  const onViewMember = (item: any) => {
    setSelectedMember(item);
    setShowSelectedMember(true);
  };

  const hideConfirmationDialog = () => {
    setHideDialog(true);
  };

  const onRevokeMember = async () => {
    if (!activeItem.item?.id) {
      return;
    }
    hideConfirmationDialog();
    const result = await deleteTeamMember({
      variables: {
        deleteMemberId: activeItem.item.id,
      },
    });
    if (result.data.deleteTeamMember) {
      await memberListQuery.refetch();
    }
  };

  const handleEdit = (index) => {
    sessionStorage.setItem("memberData", JSON.stringify(memberList[index]));
    navigate("/create-member");
  };

  useEffect(() => {
    loadMemberList().then(() => {
      console.log("List loaded");
    });
  }, [memberListQuery.data]);

  const loadMemberProps = (member: any[]): any[] => {
    return member.map((member) => {
      return {
        ...member,
      };
    });
  };

  const loadMemberList = async () => {
    let data: Array<any> = memberListQuery.data?.getTeamMember;
    if (data) {
      const formattedData = loadMemberProps(data);
      setMemberList([...formattedData]);
    }
  };

  const getActions = (props: any): IActionButtonTitle => {
    const actionButtonTitles: IActionButtonTitle = {
      viewTitle: "View Member",
      deleteTitle: "Revoke Member",
      editTitle: "Edit Member",
    };
    return actionButtonTitles;
  };

  const columns: IColumn[] = [
    {
      key: "name",
      name: "Name",
      fieldName: "name",
      minWidth: 90,
      maxWidth: 100,
      isRowHeader: true,
      isResizable: true,
      isFiltered: false,
      data: "string",
      isPadded: true,
    },
    {
      key: "role",
      name: "Role",
      fieldName: "role",
      minWidth: 90,
      maxWidth: 100,
      isRowHeader: true,
      isResizable: true,
      isFiltered: false,
      data: "string",
      isPadded: true,
    },
    {
      key: "organization",
      name: "Organization",
      fieldName: "organization",
      minWidth: 90,
      maxWidth: 100,
      isRowHeader: true,
      isResizable: true,
      isFiltered: false,
      data: "string",
      isPadded: true,
    },
    {
      key: "email",
      name: "Email",
      fieldName: "email",
      minWidth: 90,
      maxWidth: 100,
      isRowHeader: true,
      isResizable: true,
      isFiltered: false,
      data: "string",
      isPadded: true,
    },
    {
      key: "action",
      name: "Action",
      minWidth: 100,
      maxWidth: 100,
      isRowHeader: true,
      isResizable: true,
      isFiltered: true,
      isPadded: true,
      data: "string",
      onRender: (props, index) => {
        return (
          <ActionButtons
            onEdit={() => handleEdit(index)}
            onDelete={() => setHideDialog(false)}
            onView={onViewMember}
            row={props}
            buttonTitles={getActions(props)}
          />
        );
      },
    },
  ];

  const dialogStyles = { main: { maxWidth: 150 } };

  const modalProps = {
    titleAriaId: labelId,
    subtitleAriaId: subTextId,
    isBlocking: false,
    styles: dialogStyles,
    dragOptions: undefined,
  };

  const filterList = (event: any) => {
    const members = loadMemberProps(memberListQuery.data?.getTeamMember || []);
    let textFilter = event.currentTarget.value.toLowerCase();
    if (!textFilter) {
      setTimeout(() => {
        setMemberList(members);
      });
    } else {
      const data = members.filter(
        (l: any) =>
          `${l.name}`.toLowerCase().includes(textFilter) ||
          `${l.role}`.toLowerCase().includes(textFilter) ||
          `${l.organization}`.toLowerCase().includes(textFilter)
      );
      setMemberList(data);
    }
  };

  const exportToCSV = () => {
    const csvContent = prepareCSVData();
    if (csvContent) {
      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8" });
      saveAs(blob, "member_list.csv");
    } else {
      console.error("CSV content is undefined");
    }
  };

  const prepareCSVData = () => {
    if (memberListQuery.data && memberListQuery.data.getTeamMember) {
      const headers = [
        "Member Name",
        "Role",
        "Organization",
        "Customer",
        "Partner",
        "Phone no",
        "Email",
        "cfSbDetails",
        "Training Details",
      ].join(",");

      // Extract data for each member and format it as CSV rows
      const rows = memberListQuery.data.getTeamMember.map((member) => {
        const customerName = member.customer
          ? member.customer.customerName
          : "";
        const partnerName = member.partner ? member.partner.partnerName : "";
        const trainings = member.trainings
          ? member.trainings.map((training: any) => training.course).join(", ")
          : "";

        const phoneNumber = `\t${member.phone}`;

        return [
          member.name,
          member.role,
          member.organization,
          customerName,
          partnerName,
          phoneNumber,
          member.email,
          member.cfSbDetails,
          trainings,
        ].join(",");
      });
      const csvContent = [headers, ...rows].join("\n");

      return csvContent;
    } else {
      console.error("Member data is not available or has unexpected structure");
      return "";
    }
  };

  const modalStyles = {
    root: {
      height: "600px",
      overflow: "auto" as "auto",
    },
  };

  return (
    <Authorization>
      <section key={UserPermission.P_MEMBER_LIST}>
        <div style={{ marginTop: "1rem", maxWidth: "50%" }}>
          <h3>Member List</h3>
          <hr />
          <div className={contClasses.flexStartContainer}>
            <div style={{ width: "150rem", marginTop: "-2rem" }}>
              <CloudFrameTextBox
                placeholder="Search"
                iconProps={searchIcon}
                onChange={(e) => filterList(e)}
                className={listClasses.searchBar}
              />
            </div>
            <div
              style={{
                width: "auto",
                marginBottom: "1rem",
                marginLeft: "35rem",
                marginTop: "-2.8rem",
              }}
            >
              <CloudFramePrimaryButton onClick={exportToCSV}>
                Export to CSV
              </CloudFramePrimaryButton>
            </div>
          </div>
          <div style={{ width: "auto", marginTop: "-2rem" }}>
            <CloudFrameDataTable
              items={memberList || []}
              selectionMode={SelectionMode.none}
              columns={columns}
              className={`${classes.tableBody}`}
              onActiveItemChanged={(item, index) =>
                setActiveItem({ item, index })
              }
              compact
            />
          </div>
        </div>

        <CloudFrameDialog
          hidden={hideDialog}
          onDismiss={hideConfirmationDialog}
          dialogContentProps={{
            type: DialogType.normal,
            title: "Delete Confirmation",
            closeButtonAriaLabel: "Close",
            subText: "Are you sure you want to delete this member?",
          }}
          modalProps={modalProps}
          primaryButton={{ text: "Delete", onClick: onRevokeMember }}
          secondaryButton={{ text: "Cancel", onClick: hideConfirmationDialog }}
        />
        <CloudFrameDialog
          hidden={messageHidden}
          onDismiss={() => setMessageHidden(true)}
          dialogContentProps={{
            type: DialogType.normal,
            title: messgeInfo?.title,
            closeButtonAriaLabel: "Close",
            subText: messgeInfo?.message,
          }}
          modalProps={modalProps}
          primaryButton={{
            text: "Close",
            onClick: () => setMessageHidden(true),
          }}
        />
        <Modal
          isOpen={selectedMember !== null}
          onDismiss={() => setSelectedMember(null)}
          styles={modalStyles}
        >
          <div
            style={{
              padding: "40px",
              borderRadius: "30px",
              backgroundColor: "#ffffff",
            }}
          >
            <h2 style={{ textAlign: "center" }}>Member Details</h2>
            <div>
              <table style={{ width: "100%", borderCollapse: "collapse" }}>
                <tr>
                  <td style={{ border: "1px solid", padding: "5px" }}>
                    <b>Name</b>
                  </td>
                  <td style={{ border: "1px solid", padding: "5px" }}>
                    {selectedMember?.name}
                  </td>
                </tr>
                <tr>
                  <td style={{ border: "1px solid", padding: "5px" }}>
                    <b>Role</b>
                  </td>
                  <td style={{ border: "1px solid", padding: "5px" }}>
                    {selectedMember?.role}
                  </td>
                </tr>
                <tr>
                  <td style={{ border: "1px solid", padding: "5px" }}>
                    <b>Organization</b>
                  </td>
                  <td style={{ border: "1px solid", padding: "5px" }}>
                    {selectedMember?.organization}
                  </td>
                </tr>
                <tr>
                  <td style={{ border: "1px solid", padding: "5px" }}>
                    <b>Customer</b>
                  </td>
                  <td style={{ border: "1px solid", padding: "5px" }}>
                    {selectedMember?.customer?.customerName || "N/A"}
                  </td>
                </tr>
                <tr>
                  <td style={{ border: "1px solid", padding: "5px" }}>
                    <b>Partner</b>
                  </td>
                  <td style={{ border: "1px solid", padding: "5px" }}>
                    {selectedMember?.partner?.partnerName || "N/A"}
                  </td>
                </tr>
                <tr>
                  <td style={{ border: "1px solid", padding: "5px" }}>
                    <b>Phone</b>
                  </td>
                  <td style={{ border: "1px solid", padding: "5px" }}>
                    {selectedMember?.phone}
                  </td>
                </tr>
                <tr>
                  <td style={{ border: "1px solid", padding: "5px" }}>
                    <b>Email</b>
                  </td>
                  <td style={{ border: "1px solid", padding: "5px" }}>
                    {selectedMember?.email}
                  </td>
                </tr>
                <tr>
                  <td style={{ border: "1px solid", padding: "5px" }}>
                    <b>Sandbox Used</b>
                  </td>
                  <td style={{ border: "1px solid", padding: "5px" }}>
                    {selectedMember?.cfSbDetails === "true" ? "Yes" : "No"}
                  </td>
                </tr>

                <tr>
                  <td style={{ border: "1px solid", padding: "5px" }}>
                    <b>Trainings</b>
                  </td>
                  <td style={{ border: "1px solid", padding: "5px" }}>
                    {selectedMember?.trainings
                      ?.map((training: any) => training.course)
                      .join(", ") || "N/A"}
                  </td>
                </tr>
              </table>
            </div>
            <div style={{ marginBottom: "1rem" }} />
            <div style={{ textAlign: "center" }}>
              <CloudFramePrimaryButton
                onClick={() => setSelectedMember(null)}
                text="Close"
              />
            </div>
          </div>
        </Modal>
      </section>
    </Authorization>
  );
};

export default MemberList;
