import { CloudFrameStack } from "@cloudframe/stack";
import Authorization from "../components/auth/authorization";
import { UserPermission } from "../constants/permissions.constants";
import PartnerForm from "../components/partner-form/partner-form";

const CreatePartnerPage = () => {
  return (
    <Authorization>
      <section key={UserPermission.P_CREATE_NEW_PARTNER}>
        <div style={{ margin: 16 }}>
          <CloudFrameStack>
            <PartnerForm />
          </CloudFrameStack>
        </div>
      </section>
    </Authorization>
  );
};

export default CreatePartnerPage;
