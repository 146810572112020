import { CloudFrameDatepicker } from "@cloudframe/datepicker";
import { CloudFrameDropdown } from "@cloudframe/dropdown";
import { CloudFrameTextBox } from "@cloudframe/textbox";
import { defaultDatePickerStrings } from "@fluentui/react";
import React, { ReactElement, useEffect, useState } from "react";
import "../../@styles/css/form-element.css";
import { useDebounce } from "../../hooks/cf-hooks";

interface ProjectTrainingFormProps {
  updateFormData: (data: any) => void;
  setErrors: (errors: any) => void;
}

const ProjectTrainingForm: React.FC<ProjectTrainingFormProps> = ({
  updateFormData,
  setErrors,
}) => {
  const [formValues, setFormValues] = useState<any>({});
  const [error, setError] = useState<any>({});

  useEffect(() => {
    const formDataStr = sessionStorage.getItem("projectData");
    const formData = formDataStr ? JSON.parse(formDataStr) : {};
    setFormValues({
      ...formData,
      trainingDetails: {
        ...formData.trainingDetails,
        startDate: formData.trainingDetails?.startDate
          ? new Date(formData.trainingDetails.startDate)
          : null,
        endDate: formData.trainingDetails?.endDate
          ? new Date(formData.trainingDetails.endDate)
          : null,
      },
    });
  }, []);

  useDebounce(
    () => {
      console.log("formValues", formValues);
      const savedDataStr = sessionStorage.getItem("projectData");
      const savedData = savedDataStr ? JSON.parse(savedDataStr) : {};
      const updatedData = {
        ...savedData,
        trainingDetails: {
          ...savedData.trainingDetails,
          ...formValues.trainingDetails,
        },
      };

      updateFormData(updatedData);
      //   setErrors({});
      // if(formValues.teamMembers) {
      //   updateFormData(formValues);
      // }
    },
    500,
    formValues
  );

  const renderLabel = (label: string): ReactElement => (
    <div style={{ marginBottom: "0.2rem" }}>{label}</div>
  );

  return (
    <div style={{ marginLeft: "35px" }}>
      <h3>Project Training Details</h3>
      <div
        style={{ width: "40%", marginBottom: "0.5rem", paddingTop: "0.5rem" }}
      >
        <CloudFrameTextBox
          label="Course"
          value={formValues.trainingDetails?.course || ""}
          onChange={(_, value) =>
            setFormValues({
              ...formValues,
              trainingDetails: { ...formValues.trainingDetails, course: value },
            })
          }
          errorMessage={error.course}
        />
      </div>
      <CloudFrameDatepicker
        label="Start Date"
        value={formValues.trainingDetails?.startDate || null}
        onSelectDate={(date) =>
          setFormValues({
            ...formValues,
            trainingDetails: { ...formValues.trainingDetails, startDate: date },
          })
        }
        placeholder="Select a date..."
        ariaLabel="Select a date"
        strings={defaultDatePickerStrings}
        style={{ width: "40%", marginBottom: "0.5rem" }}
      />
      <CloudFrameDatepicker
        label="End Date"
        value={formValues.trainingDetails?.endDate || null}
        onSelectDate={(date) =>
          setFormValues({
            ...formValues,
            trainingDetails: { ...formValues.trainingDetails, endDate: date },
          })
        }
        placeholder="Select a date..."
        ariaLabel="Select a date"
        strings={defaultDatePickerStrings}
        style={{ width: "40%", marginBottom: "0.5rem" }}
      />
      <CloudFrameDropdown
        label="Status"
        placeholder="Select status"
        selectedKey={formValues.trainingDetails?.status || ""}
        options={[
          { key: "In Progress", text: "In Progress" },
          { key: "Completed", text: "Completed" },
          { key: "Yet to Start", text: "Yet to Start" },
        ]}
        onChange={(_, option) =>
          setFormValues({
            ...formValues,
            trainingDetails: {
              ...formValues.trainingDetails,
              status: option?.key,
            },
          })
        }
        required={!!error.status}
        errorMessage={error.status}
        style={{ width: "40%", marginBottom: "0.5rem", fontFamily: "inherit" }}
      />
      <CloudFrameDropdown
        label="Sandbox Provided"
        placeholder="Select sandbox provision"
        selectedKey={
          formValues.trainingDetails?.sandboxProvided
            ? "Yes"
            : formValues.trainingDetails?.sandboxProvided === false
            ? "No"
            : ""
        }
        options={[
          { key: "Yes", text: "Yes" },
          { key: "No", text: "No" },
        ]}
        onChange={(_, option) =>
          setFormValues({
            ...formValues,
            trainingDetails: {
              ...formValues.trainingDetails,
              sandboxProvided: option?.key === "Yes",
            },
          })
        }
        errorMessage={error.sandboxProvided}
        style={{ width: "40%", marginBottom: "0.5rem", fontFamily: "inherit" }}
      />
    </div>
  );
};

export default ProjectTrainingForm;
