import Authorization from "../components/auth/authorization";
import { initializeIcons } from "@fluentui/react";
import { UserPermission } from "../constants/permissions.constants";
import { useContainerStyles } from "../@styles/container.styles";
import CustomerList from "../components/customer-list/customer-list";

const AllCustomerPage = () => {
  const contClasses = useContainerStyles();
  initializeIcons();
  return (
    <Authorization>
      <section
        key={UserPermission.P_CUSTOMER_LIST}
        className={contClasses.sectionContainer}
      >
        <div className={"dashboard-tile " + contClasses.boxContainer}>
          <div className={contClasses.root}>
            <div style={{ marginTop: "3rem" }}>
              <div style={{ paddingLeft: "1rem", width: "90%" }}></div>
              <CustomerList />
            </div>
          </div>
        </div>
      </section>
    </Authorization>
  );
};

export default AllCustomerPage;
