import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { CloudFramePrimaryButton } from "@cloudframe/button";
import { CloudFrameDataTable } from "@cloudframe/data-table";
import { CloudFrameDatepicker } from "@cloudframe/datepicker";
import { CloudFrameDialog } from "@cloudframe/dialog";
import {
  DialogType,
  Dropdown,
  IColumn,
  Modal,
  SelectionMode,
} from "@fluentui/react";
import { saveAs } from "file-saver"; // Import saveAs function for file download
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../../@styles/css/form-element.css";
import { MUTATION_DELETE_TRAINING } from "../../graphql-mutations/training.mutation";
import {
  GET_MONTHLY_TRAINING_REPORT,
  GET_PARTNER_DETAILS,
  GET_PARTNER_TRAINING_REPORT,
  GET_TRAINEE_TRAINING_REPORT,
  GET_TRAINING_DETAILS,
  QUERY_GET_MEMBERS,
} from "../../graphql-query/training.query";
import { toISOShortDateString } from "../../utils/date.utils";
import { IActionButtonTitle } from "../action-buttons/action-button.intefaces";
import ActionButtons from "../action-buttons/action-buttons";
import { useStyles } from "./training-list.style";

// Define the type for training data
interface Training {
  id: number;
  course: string;
  startDate: string;
  endDate: string;
  status: string;
  sandboxProvided?: string;
  additionalInfo?: string;
  members?: { id: number; name: string }[];
}

const loadMemberProps = (member: any[]): any[] => {
  return member.map((member) => {
    return {
      ...member,
    };
  });
};

const TrainingDetailsPage = () => {
  const classes = useStyles();
  const [trainings, setTrainings] = useState<Training[]>([]);
  const [selectedTraining, setSelectedTraining] = useState<Training | null>(
    null
  );
  const [filteredTrainings, setFilteredTrainings] = useState<Training[]>([]);
  const [searchTerm] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [reportModalVisible, setReportModalVisible] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [getMonthlyTrainingReport] = useLazyQuery(GET_MONTHLY_TRAINING_REPORT);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedSection, setSelectedSection] = useState("");
  const [activeItem, setActiveItem] = useState<any>({});
  const itemsPerPage = 10;
  const { loading, error, data, refetch } = useQuery(GET_TRAINING_DETAILS);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false); // State for delete confirmation dialog
  const [itemToDelete, setItemToDelete] = useState<Training | null>(null); // State for item to delete
  const { data: membersData } = useQuery(QUERY_GET_MEMBERS);
  const { data: partnerData, error: partnerError } =
    useQuery(GET_PARTNER_DETAILS);
  const [getTraineeTrainingReport] = useLazyQuery(GET_TRAINEE_TRAINING_REPORT);
  const [getPartnerTrainingReport] = useLazyQuery(GET_PARTNER_TRAINING_REPORT);
  const [errorMessage, setErrorMessage] = useState("");
  const [showErrorDialog, setShowErrorDialog] = useState(false);

  useEffect(() => {
    if (!loading && !error && data) {
      setTrainings(data.getTrainingDetails);
      setFilteredTrainings(data.getTrainingDetails);
    }
  }, [loading, error, data]);

  useEffect(() => {
    const filtered = trainings.filter((training) => {
      const course = training.course || "";
      const status = training.status || "";

      return (
        course.toLowerCase().includes(searchTerm.toLowerCase()) ||
        status.toLowerCase().includes(searchTerm.toLowerCase())
      );
    });
    setFilteredTrainings(filtered);
  }, [searchTerm, trainings]);

  const filterlist = (event: any) => {
    const values = loadMemberProps(trainings || []);
    let textFilter = event.currentTarget.value.toLowerCase();
    if (!textFilter) {
      setTimeout(() => {
        setTrainings(values);
      });
    } else {
      const data = values.filter((I: any) =>
        `${I.course}`.toLowerCase().includes(textFilter)
      );
      setFilteredTrainings(data);
    }
  };

  const getActions = (props: any): IActionButtonTitle => {
    const actionButtonTitles: IActionButtonTitle = {
      viewTitle: "View Details",
      deleteTitle: "Delete Training",
      editTitle: "Edit Training",
      //downloadTitle: "",
    };
    return actionButtonTitles;
  };

  const columns: IColumn[] = [
    {
      key: "id",
      name: "ID",
      fieldName: "id",
      minWidth: 30,
      maxWidth: 40,
      isRowHeader: true,
      isResizable: true,
      isPadded: false,
      isSorted: true,
    },
    {
      key: "course",
      name: "Course",
      fieldName: "course",
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
      isRowHeader: true,
      isSorted: false,
      isSortedDescending: false,
      sortAscendingAriaLabel: "Sorted A to Z",
      sortDescendingAriaLabel: "Sorted Z to A",
    },
    {
      key: "startDate",
      name: "Start Date",
      fieldName: "startDate",
      minWidth: 100,
      maxWidth: 150,
      isResizable: true,
      isRowHeader: true,
      onRender: (item: Training) => toISOShortDateString(item.startDate),
    },
    {
      key: "endDate",
      name: "End Date",
      fieldName: "endDate",
      minWidth: 100,
      maxWidth: 150,
      isResizable: true,
      isRowHeader: true,
      onRender: (item: Training) => toISOShortDateString(item.endDate),
    },
    {
      key: "status",
      name: "Status",
      fieldName: "status",
      minWidth: 80,
      maxWidth: 100,
      isResizable: true,
      isRowHeader: true,
      onRender: (item: Training) => (item.status ? item.status : "N/A"),
    },

    {
      key: "action",
      name: "Action",
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
      data: "string",
      onRender: (props) => {
        return (
          <ActionButtons
            // onEdit={onEditLicense}
            onDelete={() => {
              setItemToDelete(props);
              setShowDeleteDialog(true);
            }}
            onView={handleViewDetails}
            onEdit={handleeditTraining}
            row={props}
            buttonTitles={getActions(props)}
          />
        );
      },
      isPadded: false,
    },
  ];

  const totalPages = Math.ceil(trainings.length / itemsPerPage);

  // Function to get paginated data
  const getPaginatedData = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return filteredTrainings.slice(startIndex, endIndex);
  };

  const handleGenerateMonthlyReport = async () => {
    if (!startDate || !endDate) {
      alert("Please select both start and end dates.");
      return;
    }

    try {
      const { data } = await getMonthlyTrainingReport({
        variables: { startDate, endDate },
      });
      if (data && data.getMonthlyTrainingReport) {
        const headers = [
          "ID",
          "Start Date",
          "End Date",
          "Members",
          "Course",
          "Additional Info",
          "Sandbox Provided",
        ];
        const headerRow = headers.join(",") + "\n";

        // Create CSV rows
        const rows = data.getMonthlyTrainingReport
          .map((training) =>
            [
              training.id,
              toISOShortDateString(training.startDate),
              toISOShortDateString(training.endDate),
              training.members?.map((member) => member.name).join(", "),
              training.course,
              training.additionalInfo,
              training.sandboxProvided ? "Yes" : "No",
            ]
              .map((value) => `"${value}"`)
              .join(",")
          )
          .join("\n");

        // Combine header and rows
        const csvContent = headerRow + rows;

        // Create Blob and save as CSV file
        const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8" });
        saveAs(blob, "MonthlyTrainingReport.csv");
      }
    } catch (error) {
      console.error("Error generating report:", error);
    }
  };

  const handleGenerateTraineeReport = async () => {
    if (!selectedTraining) {
      alert("Please select a valid trainee.");
      return;
    }

    try {
      const { data } = await getTraineeTrainingReport({
        variables: { traineeId: selectedTraining.id },
      });
      if (data && data.getTraineeTrainingReport) {
        const headers = [
          "ID",
          "Course",
          "Start Date",
          "End Date",
          "Status",
          "Sandbox Provided",
          "Additional Info",
        ];
        const headerRow = headers.join(",") + "\n";
        const rows = data.getTraineeTrainingReport
          .map((training) =>
            [
              training.id,
              training.course,
              toISOShortDateString(training.startDate),
              toISOShortDateString(training.endDate),
              training.status,
              training.sandboxProvided ? "Yes" : "No",
              training.additionalInfo,
              training.members?.map((member) => member.name).join(", "),
            ]
              .map((value) => `"${value}"`)
              .join(",")
          )
          .join("\n");
        const csvContent = headerRow + rows;
        const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8" });
        saveAs(blob, "TraineeTrainingReport.csv");
      }
    } catch (error) {
      console.error("Error generating report:", error);
    }
  };

  const handleTraineeSelection = (event, option) => {
    const selectedTrainee = membersData?.getTeamMember.find(
      (member) => member.id === option.key
    );
    setSelectedTraining(selectedTrainee);
  };

  useEffect(() => {
    if (partnerError) {
      console.error("Error fetching partner data:", partnerError);
    }
  }, [partnerError]);

  const handlePartnerSelection = (event, option) => {
    const selectedPartner = partnerData?.getPartnerDetails.find(
      (partner) => partner.id === option.key
    );
    setSelectedTraining(selectedPartner);
  };

  const handlePartnerTraineeReport = async () => {
    if (!selectedTraining) {
      alert("Please select a valid trainee.");
      return;
    }

    try {
      const { data } = await getPartnerTrainingReport({
        variables: { pId: selectedTraining.id },
      });
      if (data && data.getPartnerTrainingReport) {
        const headers = [
          "ID",
          "Course",
          "Start Date",
          "End Date",
          "Status",
          "Sandbox Provided",
          "Additional Info",
          "Members",
        ];
        const headerRow = headers.join(",") + "\n";
        const rows = data.getPartnerTrainingReport
          .map((training) =>
            [
              training.id,
              training.course,
              toISOShortDateString(training.startDate),
              toISOShortDateString(training.endDate),
              training.status,
              training.sandboxProvided ? "Yes" : "No",
              training.additionalInfo,
              training.members?.map((member) => member.name).join(", "),
            ]
              .map((value) => `"${value}"`)
              .join(",")
          )
          .join("\n");
        const csvContent = headerRow + rows;
        const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8" });
        saveAs(blob, "PartnerTrainingReport.csv");
      }
    } catch (error) {
      console.error("Error generating report:", error);
    }
  };
  // Handle page change
  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handleGenerateReport = () => {
    setReportModalVisible(true);
  };

  const handleViewDetails = (training: Training) => {
    setSelectedTraining(training);
    setShowModal(true);
  };

  const [deleteTrainingDetailsMutation] = useMutation(MUTATION_DELETE_TRAINING);
  const handleDeleteTraining = async () => {
    try {
      await deleteTrainingDetailsMutation({
        variables: { id: activeItem.item.id },
      });
      setShowDeleteDialog(false);
      await refetch();
    } catch (error) {
      console.error("Error deleting training:", error);
      setErrorMessage("Error in deleting the training");
      setShowErrorDialog(true);
    }
  };

  const navigate = useNavigate();
  const handleeditTraining = () => {
    navigate(`/invite-form`, {
      state: {
        id: activeItem.item.id,
      },
    });
  };
  const handleDownloadCSV = () => {
    const csvData = convertToCSV(trainings);
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8" });
    saveAs(blob, "trainings.csv");
  };

  const convertToCSV = (data: Training[]) => {
    if (data.length === 0) {
      return ""; // Return an empty string if data is empty
    }

    const csvRows: string[] = []; // Explicitly specify the type as string[]

    // Define headers including 'Members'
    const headers = [
      "ID",
      "Course",
      "Start Date",
      "End Date",
      "Status",
      "Sandbox Provided",
      "Additional Info",
      "Members",
    ];
    csvRows.push(headers.join(",")); // Push headers row

    data.forEach((item) => {
      // Convert members array to a string of member names
      const memberNames =
        item.members?.map((member) => member.name).join(", ") || "";

      // Extract values for each header
      const values: string[] = [
        item.id.toString(),
        item.course,
        toISOShortDateString(item.startDate),
        toISOShortDateString(item.endDate),
        item.status ? "Yes" : "No",
        item.sandboxProvided || "",
        item.additionalInfo || "",
        `"${memberNames}"`, // Enclose member names in double quotes to maintain CSV format
      ];

      // Push values row
      csvRows.push(values.join(","));
    });

    return csvRows.join("\n");
  };

  const closeErrorDialog = () => {
    setShowErrorDialog(false);
    setErrorMessage("");
  };

  return (
    <div
      style={{
        backgroundColor: "white",
        borderRadius: "15px",
        marginTop: "15px",
      }}
      className={classes.root}
    >
      <h3 className={classes.mt16}>Training Details</h3>
      <input
        placeholder="Search"
        onChange={(e) => filterlist(e)}
        style={{ width: "20rem", height: "1.5rem" }}
      />
      <CloudFrameDataTable
        items={getPaginatedData()}
        selectionMode={SelectionMode.none}
        columns={columns}
        className={classes.tableBody}
        onActiveItemChanged={(item, index) => setActiveItem({ item, index })}
      />
      {totalPages > 1 && (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            padding: "2px",
            marginRight: "100px",
          }}
        >
          <button
            onClick={() => handlePageChange(currentPage - 1)}
            style={{
              margin: "5px",
              cursor: "pointer",
              visibility: currentPage === 1 ? "hidden" : "visible",
            }}
          >
            Prev
          </button>
          {[currentPage - 1, currentPage, currentPage + 1].map(
            (page) =>
              page > 0 &&
              page <= totalPages && (
                <button
                  key={page}
                  onClick={() => handlePageChange(page)}
                  style={{
                    margin: "5px",
                    cursor: "pointer",
                    display: "inline-block",
                    width: "20px",
                    height: "20px",
                    border: "1px solid",
                    textAlign: "center",
                    background: page === currentPage ? "#4C8DE4" : "#E8E8E8",
                    color:
                      page === currentPage
                        ? "#fff"
                        : page <= totalPages
                        ? "#000"
                        : "#ccc",
                    //fontWeight: page === currentPage ? " : "normal",
                  }}
                >
                  {page}
                </button>
              )
          )}
          <button
            onClick={() => handlePageChange(currentPage + 1)}
            style={{
              margin: "5px",
              cursor: "pointer",
              visibility: currentPage === totalPages ? "hidden" : "visible",
            }}
          >
            Next
          </button>
        </div>
      )}

      <div style={{ marginTop: "50px" }}>
        <CloudFramePrimaryButton
          text="Generate Report"
          onClick={handleGenerateReport}
          className={classes.generateReportButton}
        />
        <CloudFramePrimaryButton
          text="Download CSV"
          onClick={handleDownloadCSV}
          className={classes.downloadcsvbutton}
        />
      </div>

      <Modal
        isOpen={reportModalVisible}
        onDismiss={() => setReportModalVisible(false)}
      >
        <div className={classes.reportModalContent}>
          <p
            style={{ marginTop: "10px", fontSize: "20px", fontWeight: "bold" }}
          >
            Generate Report
          </p>
          <p>Please select the report type</p>
          <br />
          <div>
            <Dropdown
              placeholder="Select Report Type"
              options={[
                { key: "partner", text: "Partner Report" },
                { key: "monthly", text: "Monthly Report" },
                { key: "trainee", text: "Per Trainee Report" },
              ]}
              onChange={(event, option) =>
                setSelectedSection(option?.key as string)
              }
            />
          </div>
          {selectedSection === "partner" && (
            <div>
              <br />
              <br />
              <Dropdown
                placeholder="Select Partner"
                options={
                  partnerData?.getPartnerDetails.map((partner: any) => ({
                    key: partner.id,
                    text: partner.partnerName,
                  })) || []
                }
                onChange={handlePartnerSelection}
              />
              <br />
              <CloudFramePrimaryButton
                text="Generate"
                onClick={handlePartnerTraineeReport}
              />
            </div>
          )}
          {selectedSection === "monthly" && (
            <div>
              <br />
              <br />
              <div style={{ display: "flex", gap: "20px" }}>
                <CloudFrameDatepicker
                  label="Start Date"
                  placeholder="Select a date &nbsp;"
                  onSelectDate={(date) =>
                    setStartDate(toISOShortDateString(date))
                  }
                />
                <CloudFrameDatepicker
                  label="End Date"
                  placeholder="Select a date &nbsp;"
                  onSelectDate={(date) =>
                    setEndDate(toISOShortDateString(date))
                  }
                />
              </div>
              <br />
              <CloudFramePrimaryButton
                text="Generate"
                onClick={handleGenerateMonthlyReport}
              />
            </div>
          )}
          {selectedSection === "trainee" && (
            <div>
              <br />
              <br />
              <Dropdown
                placeholder="Select Developer"
                options={
                  membersData?.getTeamMember.map((member: any) => ({
                    key: member.id,
                    text: member.name,
                  })) || []
                }
                onChange={handleTraineeSelection}
              />
              <br />
              <CloudFramePrimaryButton
                text="Generate"
                onClick={handleGenerateTraineeReport}
              />
            </div>
          )}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row-reverse",
            padding: "25px",
          }}
        >
          <CloudFramePrimaryButton onClick={() => setReportModalVisible(false)}>
            Close
          </CloudFramePrimaryButton>
        </div>
      </Modal>

      <CloudFrameDialog
        hidden={!showDeleteDialog}
        onDismiss={() => setShowDeleteDialog(false)}
        dialogContentProps={{
          type: DialogType.normal,
          title: "Delete Confirmation",
          closeButtonAriaLabel: "Close",
          subText: `Are you sure you want to delete the training?`,
        }}
        primaryButton={{ onClick: handleDeleteTraining, text: "Delete" }}
        secondaryButton={{
          onClick: () => {
            setShowDeleteDialog(false);
          },
          text: "Cancel",
        }}
      />

      <CloudFrameDialog
        hidden={!showErrorDialog}
        onDismiss={closeErrorDialog}
        dialogContentProps={{
          type: DialogType.normal,
          title: "Error",
          subText: errorMessage,
        }}
        modalProps={{
          isBlocking: false,
        }}
        primaryButton={{ text: "Close", onClick: closeErrorDialog }}
      ></CloudFrameDialog>

      <Modal isOpen={showModal && selectedTraining !== null}>
        {selectedTraining && (
          <div className={classes.DetailsModalContent}>
            <h2 style={{ textAlign: "center" }}>Training Details</h2>
            <div>
              <table style={{ width: "100%", borderCollapse: "collapse" }}>
                <tr>
                  <td style={{ border: "1px solid", padding: "5px" }}>
                    <b>ID</b>
                  </td>
                  <td style={{ border: "1px solid", padding: "5px" }}>
                    {selectedTraining.id}
                  </td>
                </tr>
                <tr>
                  <td style={{ border: "1px solid", padding: "5px" }}>
                    <b>Start Date</b>
                  </td>
                  <td style={{ border: "1px solid", padding: "5px" }}>
                    {toISOShortDateString(selectedTraining.startDate)}
                  </td>
                </tr>
                <tr>
                  <td style={{ border: "1px solid", padding: "5px" }}>
                    <b>End Date</b>
                  </td>
                  <td style={{ border: "1px solid", padding: "5px" }}>
                    {toISOShortDateString(selectedTraining.endDate)}
                  </td>
                </tr>
                <tr>
                  <td style={{ border: "1px solid", padding: "5px" }}>
                    <b>Status</b>
                  </td>
                  <td style={{ border: "1px solid", padding: "5px" }}>
                    {selectedTraining.status}
                  </td>
                </tr>
                <tr>
                  <td style={{ border: "1px solid", padding: "5px" }}>
                    <b>Courses</b>
                  </td>
                  <td style={{ border: "1px solid", padding: "5px" }}>
                    {selectedTraining.course}
                  </td>
                </tr>
                <tr>
                  <td style={{ border: "1px solid", padding: "5px" }}>
                    <b>Sandbox Provided</b>
                  </td>
                  <td style={{ border: "1px solid", padding: "5px" }}>
                    {selectedTraining.sandboxProvided ? "Yes" : "No"}
                  </td>
                </tr>
                <tr>
                  <td style={{ border: "1px solid", padding: "5px" }}>
                    <b>Additional Information</b>
                  </td>
                  <td style={{ border: "1px solid", padding: "5px" }}>
                    {selectedTraining.additionalInfo}
                  </td>
                </tr>
                <tr>
                  <td style={{ border: "1px solid", padding: "5px" }}>
                    <b>Members</b>
                  </td>
                  <td style={{ border: "1px solid", padding: "5px" }}>
                    {selectedTraining.members
                      ?.map((member) => member.name)
                      .join(", ")}
                  </td>
                </tr>
              </table>
            </div>
            <br />
            <div style={{ textAlign: "center" }}>
              <CloudFramePrimaryButton
                onClick={() => setShowModal(false)}
                text="Close"
              />
            </div>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default TrainingDetailsPage;
