import { CloudFrameTextBox } from "@cloudframe/textbox";
import { ReactElement, useEffect, useState } from "react";

import { useDebounce } from "../../hooks/cf-hooks";

interface LFormProps {
  updateFormData: (data: any) => void;
  setErrors: (errors: any) => void;
}

const ProjectDeploymentDetailsForm: React.FC<LFormProps> = ({
  updateFormData,
  setErrors,
}) => {
  const [formValues, setFormValues] = useState<any>({});

  useEffect(() => {
    const formDataStr = sessionStorage.getItem("projectData");
    const formData = formDataStr ? JSON.parse(formDataStr) : {};
    setFormValues(formData);
  }, []);

  // useDebounce(
  //   () => {
  //     console.log("formValues", formValues);
  //     updateFormData(formValues);
  //   },
  //   500,
  //   formValues
  // );

  useDebounce(
    () => {
      console.log("formValues", formValues);
      const savedDataStr = sessionStorage.getItem("projectData");
      const savedData = savedDataStr ? JSON.parse(savedDataStr) : {};
      const updatedData = { ...savedData, ...formValues };

      updateFormData(updatedData);
      //   setErrors({});
      // if(formValues.teamMembers) {
      //   updateFormData(formValues);
      // }
    },
    500,
    formValues
  );
  const renderLabel = (label: string): ReactElement => (
    <div style={{ marginBottom: "0.2rem" }}>{label}</div>
  );

  return (
    <>
      {/* Target Application Details */}
      {/*<div className={classes.root} style={{ borderRadius: "20px", overflow: "hidden" }}>*/}
      {/* <div className={classes.formBody} style={{ borderRadius: "25px", padding: "1rem", marginLeft: "35px" }}>*/}
      <div style={{ marginLeft: "5%", width: "50%" }}>
        <h3>Target Application Details</h3>
        <CloudFrameTextBox
          label="Technology Stack of Converted Java Application"
          value={formValues.javaTechStack}
          onChange={(_, value) => {
            setFormValues({ ...formValues, javaTechStack: value });
          }}
          // errorMessage={error.javaTechStack}
        />
        <CloudFrameTextBox
          label="Key Functionalities of Java Application"
          value={formValues.javaKeyFeatures}
          onChange={(_, value) =>
            setFormValues({ ...formValues, javaKeyFeatures: value })
          }
          multiline
          rows={3}
          // errorMessage={error.javaKeyFeatures}
        />
        <CloudFrameTextBox
          label="Performance Metrics or Benchmarks"
          value={formValues.javaPerformanceMetrics}
          onChange={(_, value) =>
            setFormValues({ ...formValues, javaPerformanceMetrics: value })
          }
          multiline
          rows={3}
          // errorMessage={error.javaPerformanceMetrics}
        />
        <CloudFrameTextBox
          label="Java Integration Details with Other Systems or Applications"
          value={formValues.javaIntegrationDetails}
          onChange={(_, value) =>
            setFormValues({ ...formValues, javaIntegrationDetails: value })
          }
          multiline
          rows={3}
          // errorMessage={error.javaIntegrationDetails}
        />
        <CloudFrameTextBox
          label="APIs Used for Integration"
          value={formValues.javaIntegrationApi}
          onChange={(_, value) =>
            setFormValues({ ...formValues, javaIntegrationApi: value })
          }
          multiline
          rows={3}
          // errorMessage={error.javaIntegrationApi}
        />

        {/* Deployment Environment */}

        <h3>Deployment Environment</h3>

        <CloudFrameTextBox
          label="Server Infrastructure Details"
          value={formValues.depInfra}
          onChange={(_, value) =>
            setFormValues({ ...formValues, depInfra: value })
          }
          // errorMessage={error.depInfra}
        />
        <CloudFrameTextBox
          label="Operating System / Server Details"
          value={formValues.depOs}
          onChange={(_, value) =>
            setFormValues({ ...formValues, depOs: value })
          }
          // errorMessage={error.depOs}
        />
        <CloudFrameTextBox
          label="Database Used"
          value={formValues.depDatabase}
          onChange={(_, value) =>
            setFormValues({ ...formValues, depDatabase: value })
          }
          // errorMessage={error.depDatabase}
        />
      </div>
    </>
  );
};

export default ProjectDeploymentDetailsForm;
