import { makeStyles } from "@fluentui/react-components"

export const useStyles = makeStyles({
    root: {
        paddingLeft: "4rem",
        paddingTop: "1rem",
        position: "relative",
        "@media(max-width: 700px)": {
          paddingLeft: 0,
        },
        paddingBottom:"2rem"
      },
      tableBody: {
        width: "auto",
        height: "auto",
        top:"0.5rem",
        overflowY: "auto",
        overflowX: "auto",
        display: "block",
        "@media(min-width: 1250px)": {
          overflowX: "auto",
        },
      },
      mt16: {
        marginTop: "1rem",
      },
      DetailsModalContent: {
        height: "25rem",
        width:"25rem",
        paddingTop:"1rem",
        paddingBottom:"1rem",
        paddingRight:"1rem",
        paddingLeft: "2rem",  
      },
    })