import { gql } from "@apollo/client";

export const MUTATION_CREATE_MEMBER = gql`
  mutation Mutation($data: TeamMemberInputType!) {
    createTeamMemberInfo(data: $data) {
      id
      name
      role
      organization
      phone
      email
      cfSbDetails
      customer {
        id
      }
      partner {
        id
      }
      trainings {
        id
      }
    }
  }
`;

export const MUTATION_EDIT_MEMBER = gql`
  mutation Mutation($id: Int!, $data: TeamMemberInputType!) {
    updateTeamMember(id: $id, data: $data) {
      id
      name
      role
      organization
      phone
      email
      cfSbDetails
      customer {
        id
      }
      partner {
        id
      }
      trainings {
        id
      }
    }
  }
`;

export const MUTATION_DELETE_MEMBER = gql`
  mutation deleteTeamMember($deleteMemberId: Int!) {
    deleteTeamMember(id: $deleteMemberId)
  }
`;
