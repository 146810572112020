import { gql } from "@apollo/client";

// Query to get all projects with their details
export const QUERY_GET_PROJECTS = gql`
  query GetProjects {
    getProjects {
      id
      name
      startDate
      endDate
      milestones
      engagementType
      customer {
        id
        customerName
      }
      partner {
        id
        partnerName
      }
      issuedLicenseLink
      renewalReminderDate
      teamMembers {
        id
        name
        role
        phone
        email
        organization
      }
      mfTechStack
      mfKeyFeatures
      mfChallenges
      mfAdditionalInfo
      javaTechStack
      javaKeyFeatures
      javaPerformanceMetrics
      javaIntegrationDetails
      javaIntegrationApi
      javaAdditionalInfo
      depInfra
      depOs
      depDatabase
      docNote
      docFiles
      additionalInfo
      feedbackAndIssues {
        id
        issues
        feedback
      }

      projectEngagementType
      timeTrackings {
        resourceName
        billable
        id
        notes
        organization
        startDate
        hours
      }
      productInfos {
        id
        productName
        version
        ticketsFixed
        repository
        releaseType
      }
      attachments {
        id
        fileName
      }
      trainingDetails {
        id
        course
        startDate
        endDate
        status
        sandboxProvided
      }
    }
  }
`;

// Query to get a single project's details by ID
export const QUERY_GET_PROJECT_DETAILS = gql`
  query getProjectDetails($id: Int!) {
    getProjectDetails(id: $id) {
      id
      name
      startDate
      endDate
      milestones
      engagementType
      customer {
        id
        customerName
      }
      partner {
        id
        partnerName
      }
      issuedLicenseLink
      renewalReminderDate
      teamMembers {
        id
        name
        role
        phone
        email
        organization
      }
      mfTechStack
      mfKeyFeatures
      mfChallenges
      mfAdditionalInfo
      javaTechStack
      javaKeyFeatures
      javaPerformanceMetrics
      javaIntegrationDetails
      javaIntegrationApi
      javaAdditionalInfo
      depInfra
      depOs
      depDatabase
      docNote
      docFiles
      additionalInfo
      projectEngagementType
      timeTrackings {
        id
        notes
        organization
        startDate
        hours
        resourceName
        billable
      }
      productInfos {
        id
        productName
        version
        ticketsFixed
        repository
        releaseType
      }
    }
  }
`;

export const GET_CUSTOMER_OPTIONS = gql`
  query getCustomerDetails {
    getCustomerDetails {
      id
      customerName
      teamMembers {
        id
        name
        role
        organization
        phone
        email
      }
    }
  }
`;

export const GET_CUSTOMER_OPTIONS_TEAM = gql`
  query GetCustomerDetailsByID($getCustomerDetailsByIdId: Float!) {
    getCustomerDetailsByID(id: $getCustomerDetailsByIdId) {
      id
      customerName
      teamMembers {
        id
        name
        role
        organization
        phone
        email
        cfSbDetails
      }
    }
  }
`;

export const GET_PARTNER_OPTIONS = gql`
  query getPartnerDetails {
    getPartnerDetails {
      id
      partnerName
      teamMembers {
        id
        name
        role
        organization
        phone
        email
      }
    }
  }
`;

export const QUERY_GET_MEMBERS = gql`
  query getTeamMember {
    getTeamMember {
      id
      name
      organization
      phone
      email
      role
    }
  }
`;

export const GET_TRAINING_OPTIONS = gql`
  query getTrainingDetails {
    getTrainingDetails {
      id
      course
      startDate
      endDate
      status
      sandboxProvided
    }
  }
`;
