import { CloudFrameStack } from "@cloudframe/stack";
// import LicenseForm from "../components/license-form/license-form";
import Authorization from "../components/auth/authorization";
import { UserPermission } from "../constants/permissions.constants";
import MemberForm from "../components/member-form/member-form";

const CreateMemberPage = () => {
  return (
    <Authorization>
      <section key={UserPermission.P_CREATE_MEMBER}>
        <div style={{ margin: 16 }}>
          <CloudFrameStack>
            <MemberForm />
          </CloudFrameStack>
        </div>
      </section>
    </Authorization>
  );
};

export default CreateMemberPage;
