import React, { useState, useEffect } from "react";
import { CloudFrameDataTable } from "@cloudframe/data-table";
import { CloudFramePrimaryButton } from "@cloudframe/button";
import { DialogType, Modal } from "@fluentui/react";
import { useQuery, useMutation } from "@apollo/client";
import { IColumn, SelectionMode } from "@fluentui/react";
import { QUERY_GET_ALL_EMAIL_CONFIGS } from "../../graphql-query/email-service-config.query";
import { MUTATION_DELETE_EMAIL_CONFIG } from "../../graphql-mutations/email-service-config.mutation";
import { useStyles } from "./emai-service-config-list.style";
import ActionButtons from "../action-buttons/action-buttons";
import { IActionButtonTitle } from "../action-buttons/action-button.intefaces";
import { CloudFrameDialog } from "@cloudframe/dialog";
import { useNavigate } from "react-router-dom";

interface Configuration {
  id: number;
  configurationType: string;
  thresholdPeriod: number | null;
  emailReceivers: string;
  emailBody: string | null;
  emailBodyVariables: string | null;
}

const ConfigurationPage: React.FC = () => {
  const classes = useStyles(); // Using styles from useStyles hook
  const [configurations, setConfigurations] = useState<Configuration[]>([]);
  const [filteredConfigurations, setFilteredConfigurations] = useState<Configuration[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [selectedConfiguration, setSelectedConfiguration] = useState<Configuration | null>(null);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [itemToDelete, setItemToDelete] = useState<Configuration | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 8; // Adjust items per page as needed

  const { loading, error, data, refetch } = useQuery(QUERY_GET_ALL_EMAIL_CONFIGS);
  const [deleteEmailConfig] = useMutation(MUTATION_DELETE_EMAIL_CONFIG);
  const navigate = useNavigate();
  useEffect(() => {
    if (!loading && !error && data) {
      const fetchedConfigurations = data.findAllEmailServiceConfig || [];
      setConfigurations(fetchedConfigurations);
      setFilteredConfigurations(fetchedConfigurations);
    }
  }, [loading, error, data]);

  useEffect(() => {
    if (configurations.length > 0) {
      const filtered = configurations.filter((config) => {
        const name = config.configurationType.toLowerCase();
        const status = config.emailReceivers.toLowerCase();
        return name.includes(searchTerm.toLowerCase()) || status.includes(searchTerm.toLowerCase());
      });
      setFilteredConfigurations(filtered);
    }
  }, [searchTerm, configurations]);

  const handleDeleteEmailConfig = async () => {
    if (itemToDelete) {
      try {
        await deleteEmailConfig({ variables: { id: itemToDelete.id } });
        setShowDeleteDialog(false);
        refetch(); // Refresh the list after deletion
      } catch (err) {
        console.error("Error deleting email configuration:", err);
      }
    }
  };

  const columns: IColumn[] = [
    {
      key: "configurationType",
      name: "Configuration Type",
      fieldName: "configurationType",
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
      isSorted: false,
      isSortedDescending: false,
    },
    {
      key: "thresholdPeriod",
      name: "Threshold Period",
      fieldName: "thresholdPeriod",
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
      onRender: (item: Configuration) => <span>{item.thresholdPeriod ?? "-"}</span>,
    },
    {
      key: "emailReceivers",
      name: "Email Receivers",
      fieldName: "emailReceivers",
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
    },
    {
      key: "action",
      name: "Action",
      minWidth: 100,
      maxWidth: 150,
      isResizable: true,
      onRender: (item: Configuration) => {
        const getActions = (item: Configuration): IActionButtonTitle => {
          return {
            viewTitle: "View Details",
            deleteTitle: "Delete",
            editTitle: "Edit"
          };
        };
      
        const handleEditConfiguration= () =>{
          navigate(`/create-email-service-config`, {
            state: {
              id: item.id,
            },
          });
        };

        return (
          <ActionButtons
            onView={() => handleViewDetails(item)}
            onDelete={() => {
              setItemToDelete(item);
              setShowDeleteDialog(true);
            }}
            onEdit={handleEditConfiguration}
            row={item}
            buttonTitles={getActions(item)}
          />
        );
      },
    },
  ];

  const totalPages = Math.ceil(filteredConfigurations.length / itemsPerPage);

  const getPaginatedData = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return filteredConfigurations.slice(startIndex, endIndex);
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handleViewDetails = (configuration: Configuration) => {
    setSelectedConfiguration(configuration);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedConfiguration(null);
  };

  return (
    <div style={{ backgroundColor: "white", borderRadius: "15px", marginTop: "15px" }} className={classes.root}>
      <h3 className={classes.mt16}>Configuration Details</h3>
      <input
        placeholder="Search"
        onChange={(e) => setSearchTerm(e.currentTarget.value)}
        style={{ width: "20rem", height: "1.5rem" }}
      />
      <CloudFrameDataTable
        items={getPaginatedData()}
        selectionMode={SelectionMode.none}
        columns={columns}
        className={classes.tableBody} // Applying tableBody style
      />
      {totalPages > 1 && (
        <div style={{ display: "flex", justifyContent: "flex-end", padding: "10px" }}>
          <button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
            style={{ margin: "5px", cursor: "pointer" }}
          >
            Prev
          </button>
          {[...Array(totalPages)].map((_, index) => {
            const page = index + 1;
            return (
              <button
                key={page}
                onClick={() => handlePageChange(page)}
                style={{
                  margin: "5px",
                  cursor: "pointer",
                  background: page === currentPage ? "#4C8DE4" : "#E8E8E8",
                  color: page === currentPage ? "#fff" : "#000",
                }}
              >
                {page}
              </button>
            );
          })}
          <button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
            style={{ margin: "5px", cursor: "pointer" }}
          >
            Next
          </button>
        </div>
      )}
      <CloudFrameDialog
        hidden={!showDeleteDialog}
        onDismiss={() => setShowDeleteDialog(false)}
        dialogContentProps={{
          type: DialogType.normal,
          title: 'Delete Confirmation',
          closeButtonAriaLabel: 'Close',
          subText: `Are you sure you want to delete this Configuration?`,
        }}
        primaryButton={{onClick:handleDeleteEmailConfig, text:"Delete"}}
        secondaryButton={{onClick:()=>{setShowDeleteDialog(false)},text:"Cancel"}}
      />
      <Modal isOpen={showModal} onDismiss={handleCloseModal}>
        {selectedConfiguration && (
          <div className={classes.DetailsModalContent}>
            <h2 style={{ textAlign: "center" }}>Configuration Details</h2>
            <div>
              <table style={{ width: "100%", borderCollapse: "collapse" }}>
                <tbody>
                  <tr>
                    <td style={{ border: "1px solid", padding: "5px" }}><b>ID</b></td>
                    <td style={{ border: "1px solid", padding: "5px" }}>{selectedConfiguration.id}</td>
                  </tr>
                  <tr>
                    <td style={{ border: "1px solid", padding: "5px" }}><b>Configuration Type</b></td>
                    <td style={{ border: "1px solid", padding: "5px" }}>{selectedConfiguration.configurationType}</td>
                  </tr>
                  <tr>
                    <td style={{ border: "1px solid", padding: "5px" }}><b>Email Receivers</b></td>
                    <td style={{ border: "1px solid", padding: "5px" }}>{selectedConfiguration.emailReceivers}</td>
                  </tr>
                  <tr>
                    <td style={{ border: "1px solid", padding: "5px" }}><b>Email Body</b></td>
                    <td style={{ border: "1px solid", padding: "5px" }}>{selectedConfiguration.emailBody ?? "-"}</td>
                  </tr>
                  <tr>
                    <td style={{ border: "1px solid", padding: "5px" }}><b>Email Body Variables</b></td>
                    <td style={{ border: "1px solid", padding: "5px" }}>{selectedConfiguration.emailBodyVariables ?? "-"}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <br />
            <div style={{ textAlign: "center" }}>
              <CloudFramePrimaryButton onClick={handleCloseModal} text="Close" />
            </div>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default ConfigurationPage;
