import {
  CloudFrameDefaultButton,
  CloudFramePrimaryButton,
} from "@cloudframe/button";
import { CloudFrameDatepicker } from "@cloudframe/datepicker";
import { CloudFrameDropdown } from "@cloudframe/dropdown";
import { CloudFrameTextBox } from "@cloudframe/textbox";
import React, { useEffect, useRef, useState } from "react";
import { useDebounce } from "../../hooks/cf-hooks";
import { defaultDatePickerStrings } from "@fluentui/react";
import CustomLabel from "../custom-label/custom-label";
import { useContainerStyles } from "../../@styles/container.styles";
import { validateItemsForRequiredFields } from "../../utils/validation.util";
interface LFormProps {
  updateFormData: (data: any) => void;
  setErrors: (errors: any) => void;
}

const TimeFeedbackDocForm: React.FC<LFormProps> = ({
  updateFormData,
  setErrors,
}) => {
  const [formValues, setFormValues] = useState<any>({});
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [error, setError] = useState<any>({
    docNote: "",
    attachments: [],
    timeTrackings: [
      {
        resourceName: "",
        startDate: new Date(),
        billable: "",
        hours: "",
        notes: "",
        organization: "",
      },
    ],
    feedbackAndIssues: [{ feedback: "", issues: "" }],
  });
  const contClasses = useContainerStyles();
  const errorTimeTrackingIdentifier = "timeTrackings";
  const defaultTimeTracking = {
    resourceName: "",
    startDate: new Date(),
    billable: true,
    hours: 0,
    notes: "",
    organization: "",
  };
  const defaultFeedbacklAndIssues = {
    feedback: "",
    issues: "",
  };

  const validateForm = () => {
    const errors: any = {};

    validateItemsForRequiredFields(
      formValues.timeTrackings,
      ["resourceName", "startDate", "billable", "hours", "organization"],
      errors,
      errorTimeTrackingIdentifier
    );

    setError(errors);
    setErrors(errors);
    return !!!Object.keys(errors).length;
  };

  useEffect(() => {
    const formDataStr = sessionStorage.getItem("projectData");
    const formData = formDataStr ? JSON.parse(formDataStr) : {};
    setFormValues((prev: any) => ({
      ...prev,
      ...formData,
      ...formValues,
      docNote: formData.docNote || "",
      attachments: formData.attachments || [],
      timeTrackings: formData.timeTrackings || [{ ...defaultTimeTracking }],
      feedbackAndIssues: formData.feedbackAndIssues || [
        { ...defaultFeedbacklAndIssues },
      ],
    }));
  }, []);

  useDebounce(
    () => {
      const isValid = validateForm();
      if (isValid) {
        updateFormData(formValues);
      }
    },
    500,
    formValues
  );

  const handleAddTimeTracking = () => {
    setFormValues((prev: any) => ({
      ...prev,
      timeTrackings: [
        ...prev.timeTrackings,
        {
          resourceName: "",
          startDate: new Date(),
          billable: true,
          hours: 0,
          notes: "",
          organization: "",
        },
      ],
    }));
  };

  const handleRemovetimeTrackings = (index: number) => {
    setFormValues((prev: any) => {
      const updatedTracking = [...prev.timeTrackings];
      updatedTracking.splice(index, 1);
      return { ...prev, timeTrackings: updatedTracking };
    });
  };

  const handleFileInputChange = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (e.target.files && e.target.files.length > 0) {
      const filesArray = Array.from(e.target.files);

      const filesMap = new Map<string, any>(
        formValues.attachments.map((file: any) => [file.fileName, file])
      );

      for (const file of filesArray) {
        const fileContent = await fileToBase64(file);
        if (!filesMap.has(file.name)) {
          filesMap.set(file.name, {
            fileName: file.name,
            fileSize: file.size,
            fileContent: fileContent.split(",")[1],
            fileType: file.type,
          });
        }
      }
      const uniqueFilesArray = Array.from(filesMap.values());

      setFormValues((prev: any) => ({
        ...prev,
        attachments: uniqueFilesArray,
      }));
    }
  };

  const fileToBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });
  };

  const handleFileButtonClicked = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleRemoveAttachment = (index: number) => {
    setFormValues((prev: any) => {
      const updatedAttachments = [...prev.attachments];
      updatedAttachments.splice(index, 1);
      return { ...prev, attachments: updatedAttachments };
    });
  };

  const handleAddFeedbackIssue = () => {
    setFormValues((prev: any) => {
      const updated = [
        ...prev.feedbackAndIssues,
        { ...defaultFeedbacklAndIssues },
      ];
      return { ...prev, feedbackAndIssues: updated };
    });
  };

  const handleRemoveFeedbackIssue = (index: number) => {
    setFormValues((prev: any) => {
      const updatedFeedback = [...prev.feedbackAndIssues];
      updatedFeedback.splice(index, 1);
      return { ...prev, feedbackAndIssues: updatedFeedback };
    });
  };

  return (
    <div>
      <div style={{ marginLeft: "5%", width: "50%" }}>
        <h3>Documentation and Resources</h3>
        <CloudFrameTextBox
          label="Notes"
          value={formValues.docNote}
          onChange={(_, value) =>
            setFormValues((prev: any) => ({
              ...prev,
              docNote: value,
            }))
          }
          multiline
          rows={1}
        />
        <input
          type="file"
          onChange={handleFileInputChange}
          multiple
          style={{ display: "none" }}
          ref={fileInputRef}
        />
        <CloudFramePrimaryButton
          text="Attach Documents"
          onClick={handleFileButtonClicked}
          style={{ marginTop: "10px", fontSize: "12px", padding: "4px 8px" }}
        />
        <div>
          {formValues.attachments?.length > 0 && (
            <div>
              {formValues.attachments.map((file, index) => (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginTop: "10px",
                  }}
                >
                  <span>{file.fileName}</span>
                  <CloudFrameDefaultButton
                    text="-"
                    onClick={() => handleRemoveAttachment(index)}
                    style={{
                      backgroundColor: "red",
                      color: "white",
                      fontSize: "12px",
                      padding: "4px 8px",
                      cursor: "pointer",
                      fontWeight: "bold",
                    }}
                    title="Remove this attachment"
                  />
                </div>
              ))}
            </div>
          )}
        </div>

        <div>
          <div className={contClasses.flexStartContainer}>
            <h3>Time Tracking Records</h3>
            <CustomLabel
              id="hintTimeTracking"
              ariaLabel="You may add project time tracking records in the following form. You may add multiple records by clicking on the 'Add Time Tracking' button"
            />
          </div>

          {formValues.timeTrackings?.map((track: any, index: number) => (
            <div key={index}>
              <h5>Time Tracking {index + 1}</h5>
              <CloudFrameTextBox
                label="Resource Name"
                value={track.resourceName}
                onChange={(_, value) =>
                  setFormValues((prev: any) => {
                    const updatedTracking = [...prev.timeTrackings];
                    updatedTracking[index].resourceName = value;
                    return { ...prev, timeTrackings: updatedTracking };
                  })
                }
                errorMessage={
                  error[`${errorTimeTrackingIdentifier}_${index}_resourceName`]
                }
                required={
                  !!error[
                    `${errorTimeTrackingIdentifier}_${index}_resourceName`
                  ]
                }
              />
              <CloudFrameDatepicker
                label="Date"
                strings={defaultDatePickerStrings}
                value={new Date(track.startDate)}
                onSelectDate={(startDate) =>
                  setFormValues((prev: any) => {
                    const updatedTracking = [...prev.timeTrackings];
                    updatedTracking[index].startDate = startDate;
                    return { ...prev, timeTrackings: updatedTracking };
                  })
                }
                isRequired={
                  !!error[`${errorTimeTrackingIdentifier}_${index}_startDate`]
                }
              />

              <CloudFrameDropdown
                label="Billable"
                defaultSelectedKey={track.billable ? "Yes" : "No"}
                options={[
                  { key: "Yes", text: "Yes" },
                  { key: "No", text: "No" },
                ]}
                onChange={(_, option) =>
                  setFormValues((prev: any) => {
                    const updatedTracking = [...prev.timeTrackings];
                    updatedTracking[index].billable = option?.key === "Yes";
                    return { ...prev, timeTrackings: updatedTracking };
                  })
                }
                errorMessage={
                  error[`${errorTimeTrackingIdentifier}_${index}_billable`]
                }
                required={
                  !!error[`${errorTimeTrackingIdentifier}_${index}_billable`]
                }
              />
              <CloudFrameTextBox
                label="Hours"
                type="number"
                value={track.hours}
                onChange={(_, value) =>
                  setFormValues((prev: any) => {
                    const updatedTracking = [...prev.timeTrackings];
                    updatedTracking[index].hours = Number(value);
                    return { ...prev, timeTrackings: updatedTracking };
                  })
                }
                errorMessage={
                  error[`${errorTimeTrackingIdentifier}_${index}_hours`]
                }
                required={
                  !!error[`${errorTimeTrackingIdentifier}_${index}_hours`]
                }
              />
              <CloudFrameTextBox
                label="Organization"
                value={track.organization}
                onChange={(_, value) =>
                  setFormValues((prev: any) => {
                    const updatedTracking = [...prev.timeTrackings];
                    updatedTracking[index].organization = value;
                    return { ...prev, timeTrackings: updatedTracking };
                  })
                }
                errorMessage={
                  error[`${errorTimeTrackingIdentifier}_${index}_organization`]
                }
                required={
                  !!error[
                    `${errorTimeTrackingIdentifier}_${index}_organization`
                  ]
                }
              />
              <CloudFrameTextBox
                label="Notes"
                value={track.notes}
                onChange={(_, value) =>
                  setFormValues((prev: any) => {
                    const updatedTracking = [...prev.timeTrackings];
                    updatedTracking[index].notes = value;
                    return { ...prev, timeTrackings: updatedTracking };
                  })
                }
                multiline
                rows={4}
              />
              {formValues.timeTrackings?.length > 1 && (
                <div
                  className={contClasses.flexEndContainer}
                  style={{ marginTop: 10, marginBottom: 0 }}
                >
                  <CloudFramePrimaryButton
                    text="Remove"
                    onClick={() => handleRemovetimeTrackings(index)}
                    style={{
                      backgroundColor: "red",
                    }}
                  />
                </div>
              )}
            </div>
          ))}
        </div>
        <CloudFramePrimaryButton
          text="Add Time Tracking"
          onClick={handleAddTimeTracking}
          style={{ marginTop: "10px", fontSize: "12px", padding: "4px 8px" }}
        />

        <div className={contClasses.flexStartContainer}>
          <h3>Feedback and Issues</h3>
          <CustomLabel
            id="hintFeedbackAndIssues"
            ariaLabel="You may add project feedback and issues in the following form. You may add multiple feedvack and issues entries by clicking on the 'Add CF Product' button"
          />
        </div>

        <div>
          {formValues.feedbackAndIssues?.map((feedback: any, index: number) => (
            <div key={index}>
              <h5>Feedback and Issues {index + 1}</h5>
              <CloudFrameTextBox
                label="Feedback"
                value={feedback.feedback}
                onChange={(_, value) =>
                  setFormValues((prev: any) => {
                    const updatedFeedback = [...prev.feedbackAndIssues];
                    updatedFeedback[index].feedback = value;
                    return { ...prev, feedbackAndIssues: updatedFeedback };
                  })
                }
                multiline
                rows={4}
              />
              <CloudFrameTextBox
                label="Reported Issues"
                value={feedback.issues}
                onChange={(_, value) =>
                  setFormValues((prev: any) => {
                    const updatedFeedback = [...prev.feedbackAndIssues];
                    updatedFeedback[index].issues = value;
                    return { ...prev, feedbackAndIssues: updatedFeedback };
                  })
                }
                multiline
                rows={4}
              />
              {formValues.feedbackAndIssues?.length > 1 && (
                <div
                  className={contClasses.flexEndContainer}
                  style={{ marginTop: 10, marginBottom: 0 }}
                >
                  <CloudFramePrimaryButton
                    text="Remove"
                    onClick={() => handleRemoveFeedbackIssue(index)}
                    style={{
                      backgroundColor: "red",
                    }}
                  />
                </div>
              )}
            </div>
          ))}
        </div>
        <CloudFramePrimaryButton
          text="Add Feedback / Issue"
          onClick={handleAddFeedbackIssue}
          style={{ marginTop: "10px" }}
        />
      </div>
    </div>
  );
};

export default TimeFeedbackDocForm;
