import { useMutation } from "@apollo/client";
import { message } from "antd";
import React, { FormEvent, useEffect, useState } from "react";
import {
  MUTATION_CREATE_PROJECT,
  MUTATION_UPDATE_PROJECT,
} from "../../graphql-mutations/projectManagement.mutation";
import { isBlank } from "../../utils/string.utils";
import StepperComponent from "./../stepper-component/stepper";
import LicenseDetailsForm from "./lincense-mainframe";
import ProjectDetailsForm from "./project-form";
import ProjectTeamForm from "./project-team-info";
import TargetandDeployment from "./target-and-deployment";
import TimeFeedbackDoc from "./time-feedback-doc";

import { useLocation, useNavigate } from "react-router-dom";
import { UserPermission } from "../../constants/permissions.constants";
import { deepClean } from "../../utils/object.util";
import Authorization from "../auth/authorization";
import ProjectTrainingForm from "./project-training-info";

const ProjectFormStepper: React.FC = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [createProject] = useMutation(MUTATION_CREATE_PROJECT);
  const navigate = useNavigate();
  const location = useLocation();
  const [updateProjectDetails] = useMutation(MUTATION_UPDATE_PROJECT);
  const [errors, setErrors] = useState({});
  const [formSubmitting, setFormSubmitting] = useState(false);

  const [formData1, setFormData1] = useState<any>({});
  useEffect(() => {
    const projectDataStr = sessionStorage.getItem("projectData");
    if (location.state?.project) {
      setFormData1(location.state.project);
      sessionStorage.setItem(
        "projectData",
        JSON.stringify(location.state.project)
      );
    } else if (projectDataStr) {
      setFormData1(JSON.parse(projectDataStr));
    }
  }, [location.state]);

  const updateFormData = (data: any) => {
    console.log("data to update", data);
    if (!!Object.keys(data).length && !!data.name) {
      console.log("valid data to update", data);
      sessionStorage.setItem("projectData", JSON.stringify(data));
      setFormData1(data);
    }
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    setFormSubmitting(true);

    const formDataStr = sessionStorage.getItem("projectData");
    const formData = JSON.parse(formDataStr || "{}");
    console.log("Form Data: ", formData);
    if (!validateAllForms(formData)) {
      setFormSubmitting(false);
      message.error("Please fill in all required fields.");
      return;
    }
    try {
      deepClean(formData, "__typename");
      const teamMemberIds = formData.teamMembers.map((member) => member.id);

      // const finalData = { ...formData, teamMembers: teamMemberIds };
      if (formData.id) {
        const { data } = await updateProjectDetails({
          variables: { id: formData.id, data: formData },
        });
        console.log("Response from backend (update):", data);
        message.success("Project updated successfully.");
      } else {
        const { data } = await createProject({
          variables: { data: formData },
        });
        console.log("Response from backend (create):", data);
        message.success("Project created successfully.");
      }
      setFormSubmitting(false);

      navigate("/project-list");
      sessionStorage.removeItem("projectData");
    } catch (error) {
      setFormSubmitting(false);
      console.error("Error submitting form:", error);
    }
  };

  const validateAllForms = (formData?: any) => {
    let formValues;
    const sanityErrors: any = {};
    if (!formData) {
      const formDataStr = sessionStorage.getItem("projectData");
      formValues = JSON.parse(formDataStr || "{}");
    } else {
      formValues = formData;
    }
    if (formValues) {
      console.log("formValues-sanity", formValues);
      if (isBlank(formValues.name)) {
        sanityErrors.name = "Project name is required";
      }
    }
    console.log("errors", errors);
    console.log("sanity-errors", sanityErrors);
    return !!!Object.keys(sanityErrors).length && !!!Object.keys(errors).length;
  };

  const steps = [
    {
      title: "Project Details",
      content: (
        <ProjectDetailsForm
          updateFormData={(data) => updateFormData(data)}
          setErrors={setErrors}
        />
      ),
    },
    // {
    //   title: "Customer Details",
    //   content: (
    //     <CustomerDetailsForm
    //       updateFormData={(data) => updateFormData(data)}
    //     />
    //   ),
    // },
    {
      title: "License Details",
      content: (
        <LicenseDetailsForm
          updateFormData={(data) => updateFormData(data)}
          setErrors={setErrors}
        />
      ),
    },
    {
      title: "Project Team Info",
      content: (
        <ProjectTeamForm
          updateFormData={(data) => updateFormData(data)}
          setErrors={setErrors}
        />
      ),
    },
    {
      title: "Project Training Info",
      content: (
        <ProjectTrainingForm
          updateFormData={(data) => updateFormData(data)}
          setErrors={setErrors}
        />
      ),
    },
    {
      title: "Target And Deployment Details",
      content: (
        <TargetandDeployment
          updateFormData={(data) => updateFormData(data)}
          setErrors={setErrors}
        />
      ),
    },
    {
      title: "Feedback",
      content: (
        <TimeFeedbackDoc
          updateFormData={(data) => updateFormData(data)}
          setErrors={setErrors}
        />
      ),
    },
  ];

  return (
    <Authorization>
      <section key={UserPermission.P_CREATE_NEW_PROJECT}>
        <StepperComponent
          steps={steps}
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          handleSubmit={handleSubmit}
          validateAllForms={validateAllForms}
        />
      </section>
    </Authorization>
  );
};

export default ProjectFormStepper;
