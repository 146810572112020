import { gql } from "@apollo/client";

export const QUERY_GET_MEMBERS = gql`
  query getTeamMember {
    getTeamMember {
      id
      name
    }
  }
`;

export const GET_PARTNER_DETAILS = gql`
  query GetPartnerDetails {
    getPartnerDetails {
      id
      partnerName
    }
  }
`;

export const QUERY_GET_TRAINING_BY_ID = gql`
  query GetTrainingDetailsById($id: Int!) {
    getTrainingDetailsById(id: $id) {
      id
      course
      startDate
      endDate
      status
      sandboxProvided
      additionalInfo
      members {
        id
        name
      }
    }
  }
`;

export const GET_TRAINING_DETAILS = gql`
  query GetTrainingDetails {
    getTrainingDetails {
      id
      course
      startDate
      endDate
      status
      sandboxProvided
      additionalInfo
      members {
        id
        name
      }
    }
  }
`;

export const GET_MONTHLY_TRAINING_REPORT = gql`
  query GetMonthlyTrainingReport($startDate: String!, $endDate: String!) {
    getMonthlyTrainingReport(startDate: $startDate, endDate: $endDate) {
      id
      course
      startDate
      endDate
      status
      sandboxProvided
      additionalInfo
      members {
        id
        name
      }
    }
  }
`;

export const GET_TRAINEE_TRAINING_REPORT = gql`
  query GetTraineeTrainingReport($traineeId: Int!) {
    getTraineeTrainingReport(traineeId: $traineeId) {
      id
      course
      startDate
      endDate
      status
      sandboxProvided
      additionalInfo
      members {
        id
        name
      }
    }
  }
`;

export const GET_PARTNER_TRAINING_REPORT = gql`
  query GetPartnerTrainingReport($pId: Int!) {
    getPartnerTrainingReport(pId: $pId) {
      id
      course
      startDate
      endDate
      status
      sandboxProvided
      additionalInfo
      members {
        id
        name
      }
    }
  }
`;
