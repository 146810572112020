export enum ENGAGEMENT_TYPE {
  Direct_Engagement = "Direct_Engagement",
  Through_Partner = "Through_Partner",
}

export enum PROJECT_ENGAGEMENT_TYPE {
  POC = "POC",
  Pilot = "Pilot",
  Services = "Services",
  Consulting = "Consulting",
  Support = "Support",
}

export enum RELEASE_TYPE {
  Major = "Major",
  HFIX = "HFIX",
  Minor = "Minor",
}

export enum SUPPORT_TYPE {
  Premium = "Premium",
  Regular = "Regular",
}

export enum ORGANIZATION_TYPE {
  Customer = "Customer",
  CF_Partner = "CF_Partner",
  CloudFrame = "CloudFrame",
  Customer_Partner = "Customer_Partner",
}
