import { useMsal } from "@azure/msal-react";
import {
  INavLink,
  INavLinkGroup,
  INavStyles,
  IPersonaSharedProps,
  IStyle,
  Nav,
  Persona,
  PersonaPresence,
  PersonaSize,
  Stack,
  initializeIcons,
} from "@fluentui/react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { UserInfo } from "../../@types/auth.types";
import { createObjectFromKeysArray } from "../../utils/array.utils";
import { getInitials } from "../../utils/string.utils";

initializeIcons();
const navStyles: Partial<INavStyles> = {
  root: {
    width: 350,
    height: "100%",
    boxSizing: "border-box",
    overflowY: "auto",
    paddingTop: "1rem",
    paddingLeft: "2rem",
    paddingRight: "1rem",
  },
};

const personaStyles: IStyle = {
  border: "1px solid #eee",
  padding: "12px",
};

const navLinkGroups: INavLinkGroup[] = [
  {
    links: [
      {
        name: "Home",
        url: "/",
        key: "P_HOME",
        expandAriaLabel: "Expand Home section",
        collapseAriaLabel: "Collapse Home section",
      },
    ],
  },
  {
    name: "Administration",
    collapseByDefault: true,
    links: [
      {
        name: "User List",
        url: "/user-list",
        key: "P_USER_LIST",
      },
      {
        name: "User Role Management",
        url: "/user-role",
        key: "P_USER_ROLE_FORM,P_USER_ROLE_LIST",
      },
      {
        name: "Invite User",
        url: "/invite-user",
        key: "P_INVITE_USER,P_INVITATION_LIST",
      },
      {
        name: "License Audit Trail",
        url: "/license-audit-trail",
        key: "P_LICENSE_AUDIT_TRAIL",
      },
      {
        name: "Application Log",
        url: "/logs",
        key: "P_APP_LOGS",
      },
      {
        name: "Archive & Restore DB",
        url: "/db-backup-restore",
        key: "P_DB_BACKUP,P_DATABASE_RESTORE",
      },
      {
        name: "Configuration",
        url: "#",
        key: " ",
        links: [
          {
            name: "Create Configuration",
            url: "/create-email-service-config",
            key: " ",
          },
          {
            name: "Configuration List",
            url: "/email-service-config",
            key: " ",
          },
        ],
      },
    ],
  },
  {
    name: "License Management",
    collapseByDefault: true,
    links: [
      {
        name: "Licenses List",
        url: "/all-licenses",
        key: "P_LICENSE_LIST",
      },
      {
        name: "Create License",
        url: "/create-license",
        key: "P_CREATE_NEW_LICENSE",
      },
      {
        name: "Activation Key",
        url: "/activation-key",
        key: "P_CREATE_ACTIVATION_KEY",
      },
    ],
  },
  {
    name: "Project Management",
    collapseByDefault: true,
    links: [
      {
        name: "Project",
        url: "#",
        key: "P_PROJECT_LIST,P_CREATE_NEW_PROJECT",
        links: [
          {
            name: "Project List",
            url: "/project-list",
            key: "P_PROJECT_LIST",
          },
          {
            name: "Create Project",
            url: "/project-form",
            key: "P_CREATE_NEW_PROJECT",
          },
        ],
      },
      {
        name: "Team Member",
        url: "#",
        key: "P_MEMBER_LIST,P_CREATE_MEMBER",
        links: [
          {
            name: "Member List",
            url: "/member-list",
            key: "P_MEMBER_LIST",
          },
          {
            name: "Create Member",
            url: "/create-member",
            key: "P_CREATE_MEMBER",
          },
        ],
      },
      {
        name: "Customer",
        url: "#",
        key: "P_CUSTOMER_LIST,P_CREATE_CUSTOMER",
        links: [
          {
            name: "Customer List",
            url: "/customer-list",
            key: "P_CUSTOMER_LIST",
          },
          {
            name: "Create Customer",
            url: "/create-customer",
            key: "P_CREATE_CUSTOMER",
          },
        ],
      },
      {
        name: "Partner",
        collapseByDefault: true,
        url: "#",
        key: "P_PARTNER_LIST,P_CREATE_NEW_PARTNER",
        links: [
          {
            name: "List of Partners",
            url: "/list-partner",
            key: "P_PARTNER_LIST",
          },
          {
            name: "Create a New Partner",
            url: "/create-Partner",
            key: "P_CREATE_NEW_PARTNER",
          },
        ],
      },
      {
        name: "Training",
        url: "#",
        key: "P_CREATE_TRAINING,P_TRAINING_LIST",
        links: [
          {
            name: "List training details",
            url: "/training-details",
            key: "P_TRAINING_LIST",
          },
          {
            name: "Create new training",
            url: "/create-new-training",
            key: "P_CREATE_TRAINING",
          },
        ],
      },
    ],
  },
  // {
  //   links: [
  // {
  //   name: "Dashboard Client",
  //   url: "/dashboard-client",
  //   key: "/dashboard-client",
  //   isExpanded: true,
  // },

  // {
  //   name: "Contact us",
  //   url: "/contact-us",
  //   key: "/contact-us",
  // },
  //   ],
  // },
];

const filterLinks = (links: INavLink[], permissions: any) => {
  return links.filter((link) => {
    const keys = `${link.key}`.split(",");
    for (const key of keys) {
      if (permissions[key]) {
        return true;
      }
    }
    return false;
  });
};

const getNavLinkGroups = (
  user: UserInfo,
  navLinkGroups: INavLinkGroup[]
): INavLinkGroup[] => {
  if (user && user.username) {
    if (user.roles.includes("Admin")) {
      return navLinkGroups;
    } else {
      const permissionKeys = createObjectFromKeysArray(user.permissions);
      return navLinkGroups.filter((group) => {
        group.links = filterLinks(group.links, permissionKeys);
        return group.links.length > 0;
      });
    }
  }
  return [];
};

const NavBasicExample = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const user = useSelector((state: UserInfo) => state);
  return (
    <Nav
      selectedKey={location.pathname}
      ariaLabel="Nav basic example"
      styles={{ ...navStyles, link: { fontSize: "1rem" } }}
      groups={getNavLinkGroups(user, navLinkGroups)}
      onLinkClick={(e, item) => {
        e?.preventDefault();
        if (item?.url !== "#") {
          navigate(item?.url ?? "/");
        } else {
          item.isExpanded = !!!item.isExpanded;
        }
      }}
    />
  );
};

export const SideNav = () => {
  const msl = useMsal();
  const user = useSelector((state: UserInfo) => state);

  const persona1: IPersonaSharedProps = {
    imageInitials: getInitials(msl.accounts?.[0]?.name || "User"),
    text: msl.accounts?.[0]?.name || "User",
    secondaryText: user.roles[0],
    tertiaryText: "",
    optionalText: user.roles[1] || "",
    presence: PersonaPresence.online,
  };

  return (
    <Stack
      tokens={{ childrenGap: 10 }}
      style={{ display: "static", height: "98%" }}
    >
      <div
        style={{
          paddingTop: 5,
          paddingBottom: 5,
          //borderRight: "1px solid #eee",
        }}
      >
        <Persona {...persona1} size={PersonaSize.size72} />
      </div>
      <div
        style={{
          overflowY: "auto",
          overflowX: "hidden",
          border: "1px solid #eee",
          borderRadius: 5,
        }}
      >
        <NavBasicExample />
      </div>
    </Stack>
  );
};
