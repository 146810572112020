import { CloudFrameStack } from "@cloudframe/stack";
// import LicenseForm from "../components/license-form/license-form";
import Authorization from "../components/auth/authorization";
import { UserPermission } from "../constants/permissions.constants";
import CustomerForm from "../components/customer-form/customer-form";

const CreateCustomerPage = () => {
  return (
    <Authorization>
      <section key={UserPermission.P_CREATE_CUSTOMER}>
        <div style={{ margin: 16 }}>
          <CloudFrameStack>
            <CustomerForm />
          </CloudFrameStack>
        </div>
      </section>
    </Authorization>
  );
};

export default CreateCustomerPage;
