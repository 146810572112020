export const validateItemsForRequiredFields = (
  data: any[] = [],
  requiredFields: string[] = [],
  errors: any = {},
  errorIdentifier: string
): any => {
  try {
    console.log("data", data);
    console.log("requiredFields", requiredFields);
    // if (data.length === 1) {
      validateArrayItems(
        data,
        data.length,
        requiredFields,
        errors,
        errorIdentifier
      );
    // } else if (data.length > 1) {
    //   validateArrayItems(
    //     data,
    //     data.length - 1,
    //     requiredFields,
    //     errors,
    //     errorIdentifier
    //   );
    //   validateLastItemIfTouched(data, requiredFields, errors, errorIdentifier);
    // }

    return errors;
  } catch (error) {
    console.log(error);
    return {};
  }
};

function validateLastItemIfTouched(
  data: any[],
  requiredFields: string[],
  errors: any,
  errorIdentifier: string
) {
  const lastItem = data[data.length - 1];
  const hasValueInLastItem = requiredFields.filter((field) => !!lastItem[field]).length > 0;
  if (!hasValueInLastItem) {
    requiredFields.forEach((field) => {
      errors[
        `${errorIdentifier}_${data.length - 1}_${field}`
      ] = `${field} is required`;
    });
  }
}

function validateArrayItems(
  data: any[],
  loopEnd: number,
  requiredFields: string[],
  errors: any,
  errorIdentifier: string
) {
  for (let index = 0; index < loopEnd; index++) {
    const item = data[index];
    validateArrayItemFields(
      requiredFields,
      item,
      errors,
      index,
      errorIdentifier
    );
  }
}

function validateArrayItemFields(
  requiredFields: string[],
  item: any,
  errors: any,
  index: number,
  errorIdentifier: string
) {
  requiredFields.forEach((field) => {
    if (item[field] !== false && item[field] !== 0 && !item[field]) {
      errors[`${errorIdentifier}_${index}_${field}`] = `${field} is required`;
    }
  });
}
