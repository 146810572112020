import { useQuery } from "@apollo/client";
import { CloudFramePrimaryButton } from "@cloudframe/button";
import { CloudFrameDropdown } from "@cloudframe/dropdown";
import { CloudFrameTextBox } from "@cloudframe/textbox";
import React, { ReactElement, useEffect, useState } from "react";
import { Collapse } from "antd";
import {
  GET_CUSTOMER_OPTIONS,
  GET_PARTNER_OPTIONS,
  QUERY_GET_MEMBERS,
} from "../../graphql-query/project.query";
import { useDebounce } from "../../hooks/cf-hooks";
import { validateItemsForRequiredFields } from "../../utils/validation.util";
import { getKeysAsObjects } from "../../utils/object.util";
import { ORGANIZATION_TYPE } from "../../constants/project.enum";
import { IDropdownProps, ITextFieldProps } from "@fluentui/react";
import CustomLabel from "../custom-label/custom-label";
import { useContainerStyles } from "../../@styles/container.styles";

interface LFormProps {
  updateFormData: (data: any) => void;
  setErrors: (errors: any) => void;
}

const ProjectTeamForm: React.FC<LFormProps> = ({
  updateFormData,
  setErrors,
}) => {
  const { data: membersData } = useQuery(QUERY_GET_MEMBERS);
  const { data: customerData } = useQuery(GET_CUSTOMER_OPTIONS);
  const { data: partnerData } = useQuery(GET_PARTNER_OPTIONS);
  const memberListQuery = useQuery(QUERY_GET_MEMBERS);
  const [memberList, setMemberList] = useState<Array<any>>([]);
  const [formValues, setFormValues] = useState<any>({});
  const [error, setError] = useState<any>({});
  const [selectedMembers, setSelectedMembers] = useState<any[]>([]);

  const removeTypename = (key, value) =>
    key === "__typename" ? undefined : value;
  const defaultMember = {
    name: "",
    role: "",
    organization: ORGANIZATION_TYPE.CF_Partner,
    phone: "",
    email: "",
  };
  const teamErrorIdentifier = "teamMembers";
  const contClasses = useContainerStyles();

  const validateForm = () => {
    const errors: any = {};
    validateItemsForRequiredFields(
      selectedMembers,
      ["name", "role"],
      errors,
      teamErrorIdentifier
    );

    if (!selectedMembers || !selectedMembers.length) {
      errors.teamMembers = "Please select project team members";
    }
    console.log("errors", errors);
    setError(errors);
    setErrors(errors);
    return errors;
  };

  useEffect(() => {
    const formDataStr = sessionStorage.getItem("projectData");
    const formData = formDataStr ? JSON.parse(formDataStr) : {};
    const savedteamMembers = formData.teamMembers ?? [];

    if (savedteamMembers.length) {
      setSelectedMembers(savedteamMembers);
    } else {
      setSelectedMembers([]);
    }

    validateForm();
    setFormValues(formData);
  }, []);

  useDebounce(
    () => {
      if (Object.keys(formValues).length) {
        const formData = { ...formValues, teamMembers: selectedMembers };
        const isValid = validateForm();
        if (isValid) {
          const sanitizedData = JSON.parse(
            JSON.stringify(formData, removeTypename)
          );
          updateFormData(sanitizedData);
        }
      }
    },
    500,
    selectedMembers
  );

  const handleAddMember = () => {
    setSelectedMembers([...selectedMembers, { ...defaultMember }]);
  };

  const handleRemoveMember = (index) => {
    const updatedMembers = [...selectedMembers];
    updatedMembers.splice(index, 1);
    setSelectedMembers(updatedMembers);
  };

  const handleChange = (index, field, value) => {
    const updatedMembers = [...selectedMembers];
    updatedMembers[index][field] = value;
    setSelectedMembers(updatedMembers);
  };

  const loadMemberProps = (members: any[]): any[] => {
    return members.map((member) => {
      return {
        ...member,
      };
    });
  };

  const handleMultiSelectChange = (event, option) => {
    const selectedMember = membersData?.getTeamMember?.find(
      (member) => member.id === option.key
    );
    const newteamMembers = [...selectedMembers];
    newteamMembers.unshift(selectedMember);
    setSelectedMembers(newteamMembers);
  };

  const loadMemberList = async () => {
    let data: Array<any> = membersData?.getTeamMember;
    if (data) {
      const formattedData = loadMemberProps(data);
      setMemberList([...formattedData]);
    }
  };

  useEffect(() => {
    loadMemberList().then(() => {
      console.log("List loaded ");
    });
  }, [membersData]);

  const onRenderLabel = (
    props: ITextFieldProps | IDropdownProps | undefined
  ): ReactElement =>
    props ? (
      <CustomLabel id={props.label?.replaceAll(" ", "")} {...props} />
    ) : (
      <div />
    );

  return (
    <div style={{ width: "55%", marginLeft: "35px" }}>
      <h2> Project Team Information </h2>
      <CloudFrameDropdown
        label="Select Team Members"
        ariaLabel="You may add existing members to the project by selecting them from these options. If you do not see the members you are looking for, please add them below filling out the forms. You may add new forms for more members by pressing the 'Add Member' button."
        selectedKeys={selectedMembers.map((member) => member.id)}
        onChange={handleMultiSelectChange}
        onRenderLabel={onRenderLabel}
        options={
          memberList?.map((member: any) => ({
            key: member.id,
            text: member.name,
          })) || []
        }
        multiSelect
        required={!!error?.teamMembers}
        errorMessage={error?.teamMembers}
      />
      <h3> Selected team members </h3>
      <Collapse defaultActiveKey={["0"]}>
        {selectedMembers.map((member, index) => (
          <Collapse.Panel
            header={`Team Member ${index + 1}`}
            key={index.toString()}
            style={{ width: "100%" }}
          >
            <div key={index} style={{ marginBottom: "20px" }}>
              <div style={{ display: "flex", marginBottom: "1rem" }}>
                <span style={{ minWidth: "225px", marginRight: "1rem" }}>
                  <CloudFrameTextBox
                    label="Name"
                    value={member.name}
                    onChange={(e, value) => handleChange(index, "name", value)}
                    required={!!error[`${teamErrorIdentifier}_${index}_name`]}
                    errorMessage={error[`${teamErrorIdentifier}_${index}_name`]}
                  />
                </span>
                <span style={{ minWidth: "225px", marginRight: "2px" }}>
                  <CloudFrameTextBox
                    label="role"
                    value={member.role}
                    onChange={(e, value) => handleChange(index, "role", value)}
                    required={!!error[`${teamErrorIdentifier}_${index}_role`]}
                    errorMessage={error[`${teamErrorIdentifier}_${index}_role`]}
                  />
                </span>
              </div>
              <div style={{ display: "flex", marginBottom: "1rem" }}>
                <span style={{ minWidth: "225px", marginRight: "1rem" }}>
                  <CloudFrameDropdown
                    label="organization"
                    selectedKey={member.organization}
                    onChange={(e, option) =>
                      handleChange(index, "organization", option?.key)
                    }
                    options={getKeysAsObjects(ORGANIZATION_TYPE)}
                  />
                </span>
                <span style={{ minWidth: "225px", marginRight: "2px" }}>
                  <CloudFrameTextBox
                    label="Contact Number"
                    value={member.phone}
                    onChange={(e, value) => handleChange(index, "phone", value)}
                  />
                </span>
              </div>
              <div style={{ display: "flex", marginBottom: "1rem" }}>
                <span style={{ minWidth: "225px", marginRight: "1rem" }}>
                  <CloudFrameTextBox
                    label="Email Id"
                    value={member.email}
                    onChange={(e, value) => handleChange(index, "email", value)}
                  />
                </span>
              </div>

              <div
                className={contClasses.flexEndContainer}
                style={{ marginTop: 10, marginBottom: 0 }}
              >
                <CloudFramePrimaryButton
                  text="Remove"
                  onClick={() => handleRemoveMember(index)}
                  style={{
                    backgroundColor: "red",
                  }}
                />
              </div>
              {index !== selectedMembers.length - 1 && <hr />}
            </div>
          </Collapse.Panel>
        ))}
      </Collapse>
      <CloudFramePrimaryButton
        text="Add Member"
        onClick={handleAddMember}
        style={{
          marginTop: "10px",
        }}
      />
    </div>
  );
};

export default ProjectTeamForm;
