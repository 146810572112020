import { gql } from "@apollo/client";

// Mutation to create a new project
export const MUTATION_CREATE_PROJECT = gql`
  mutation CreateProject($data: CreateProjectDetailsInputType!) {
    persistProjectDetailsInfo(data: $data) {
      name
      milestones
      mfTechStack
      mfKeyFeatures
      mfChallenges
      mfAdditionalInfo
      javaTechStack
      javaPerformanceMetrics
      javaKeyFeatures
      javaIntegrationDetails
      javaIntegrationApi
      javaAdditionalInfo
      issuedLicenseLink
      additionalInfo
      feedbackAndIssues {
        id
        issues
        feedback
      }
      productInfos {
        id
        productName
        version
        ticketsFixed
        repository
        releaseType
      }
      attachments {
        id
        fileName
        fileContent
        file
        fileSize
        fileType
      }
      customer {
        customerName
        id
        additionalInfo
        industry
        keyNotes
        primaryContactEmail
        primaryContactName
        primaryContactPhone
        primaryLocation
        timezone
      }
      depDatabase
      depInfra
      depOs
      docFiles
      docNote
      endDate
      engagementType
      partner {
        id
        partnerName
        primaryContactEmail
        primaryContactName
        primaryContactPhone
        usingCfSandbox
        cfSbDetails
        additionalInfo
      }
      id
      projectEngagementType
      renewalReminderDate
      startDate
      teamMembers {
        cfSbDetails
        email
        id
        name
        organization
        phone
        role
      }
      timeTrackings {
        startDate
        resourceName
        organization
        notes
        id
        hours
        billable
      }
      trainingDetails {
        id
        course
        startDate
        endDate
        status
        sandboxProvided
      }
    }
  }
`;
// Mutation to delete a project
export const MUTATION_DELETE_PROJECT = gql`
  mutation DeleteProjectDetails($id: Int!) {
    deleteProjectDetails(id: $id)
  }
`;

// Mutation to update an existing project
export const MUTATION_UPDATE_PROJECT = gql`
  mutation UpdateProject($data: UpdateProjectDetailsInputType!) {
    updateProjectDetails(data: $data) {
      id
      name
      milestones
      mfTechStack
      mfKeyFeatures
      mfChallenges
      mfAdditionalInfo
      javaTechStack
      javaPerformanceMetrics
      javaKeyFeatures
      javaIntegrationDetails
      javaIntegrationApi
      javaAdditionalInfo
      issuedLicenseLink
      additionalInfo
      productInfos {
        id
        productName
        version
        ticketsFixed
        repository
        releaseType
      }
      attachments {
        id
        fileName
      }
      customer {
        customerName
        id
        additionalInfo
        industry
        keyNotes
        primaryContactEmail
        primaryContactName
        primaryContactPhone
        primaryLocation
        timezone
      }
      depDatabase
      depInfra
      depOs
      docFiles
      docNote
      endDate
      engagementType
      feedbackAndIssues {
        id
        issues
        feedback
      }
      partner {
        id
        partnerName
        primaryContactEmail
        primaryContactName
        primaryContactPhone
        usingCfSandbox
        cfSbDetails
        additionalInfo
      }
      renewalReminderDate
      startDate
      teamMembers {
        cfSbDetails
        email
        id
        name
        organization
        phone
        role
      }
      timeTrackings {
        startDate
        resourceName
        organization
        notes
        id
        hours
        billable
      }
      trainingDetails {
        id
        course
        startDate
        endDate
        status
        sandboxProvided
      }
    }
  }
`;
