import { gql } from "@apollo/client";

export const QUERY_GET_MEMBERS = gql`
  query getTeamMember {
    getTeamMember {
      id
      name
      role
      organization
      phone
      email
      cfSbDetails
      customer {
        id
        customerName
      }
      partner {
        id
        partnerName
      }
      trainings {
        id
        course
      }
    }
  }
`;

export const GET_CUSTOMER_OPTIONS = gql`
  query getCustomerDetails {
    getCustomerDetails {
      id
      customerName
      teamMembers {
        id
        name
        role
        organization
        phone
        email
        cfSbDetails
      }
    }
  }
`;

export const GET_PARTNER_OPTIONS = gql`
  query getPartnerDetails {
    getPartnerDetails {
      id
      partnerName
      teamMembers {
        id
        name
        role
        organization
        phone
        email
        cfSbDetails
      }
    }
  }
`;

export const GET_TRAINING_OPTIONS = gql`
  query getTrainingDetails {
    getTrainingDetails {
      id
      course
    }
  }
`;
